<div class="main-container">
<div class="file-uploader-container">
    <img src="assets/attach_file_icon.svg" alt="" *ngIf="fileUploaderDetails.value == null">
    <img src="assets/file-uploaded-icon.svg" alt="" *ngIf="fileUploaderDetails.value != null">
    <span class="file-upload-label" *ngIf="fileUploaderDetails.value == null">{{fileUploaderDetails?.label}}</span>
    <span class="file-upload-label" *ngIf="fileUploaderDetails.value != null">{{fileUploaderDetails?.value?.name}}</span>
    
    <div class="file-uploaded-caption-container" *ngIf="fileUploaderDetails.value != null">
        <img src="assets/complete-badge.svg" alt="" class="file-uploaded-img">
        <span class="file-uploaded-caption">Uploaded Successfully</span>
    </div>

    <div class="replace-file-container" (click)="openExplorer(explorer)" *ngIf="fileUploaderDetails.value != null">
        <span class="replace-file-text">CLICK TO REPLACE</span>
    </div>

    <div class="attach-file-cta-container" *ngIf="fileUploaderDetails.value == null">
        <custom-button [buttonDetails]="attachFileContainerCtaDetails" (onCustomButtonClick)="openExplorer(explorer)"></custom-button>
    </div>
</div>
</div>

<input type="file" #explorer class="file-explorer" [accept]="fileUploaderDetails.acceptFormat"
(change)="onFileUpload($event)"
/>