import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { RatingDetails } from '../../types/components.type';

@Component({
  selector: 'rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnChanges{
  @Input() ratingDetails : RatingDetails
  @Output() onRatingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  starArr = [];

  ngOnChanges(changes: SimpleChanges): void {
    this.createStarArr();
  }

  createStarArr(){
    this.starArr = [];
    for(let i = 0; i < this.ratingDetails.stars ; i++){
      this.starArr.push(i);
    }
  }

  selectRating(index){
    this.ratingDetails.value = index + 1;
    this.onRatingChange.emit(true);
  }

}
