import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_GET_SLOTS, API_URL_CALCULATE_DISTANCE, API_URL_FETCH_PACKAGES_REGION_WISE, API_URL_FETCH_SLOTS,API_URL_REASONS_CREATE_RIDE } from 'src/app/config/apiRouteConfig';
import {ReasonsListApiResponse} from '../../types/container.type';

@Injectable({
  providedIn: 'root'
})
export class JourneyDetailsApiService {

  constructor(private _http: HttpClient) { }

  fetchReasonAndCode():Observable<ReasonsListApiResponse>{
    return this._http.get<ReasonsListApiResponse>(API_URL_REASONS_CREATE_RIDE);
  }

  fetchPackages(location):Observable<any>{
    return this._http.get<any>(`${API_URL_FETCH_PACKAGES_REGION_WISE}?lat=${location.lat}&lng=${location.lng}`);
  }

  calculateDistance(stopsList):Observable<number>{
    return this._http.post<number>(API_URL_CALCULATE_DISTANCE, stopsList);
  }

  fetchSlots(slotsDto):Observable<any>{
    return this._http.post<any>(API_URL_FETCH_SLOTS, slotsDto);
  }
}
