import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AccordianDetails } from '../../types/components.type';

@Component({
  selector: 'accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.scss']
})
export class AccordianComponent implements OnChanges, AfterViewInit{

  @Input() accordianDetails: AccordianDetails
  @Input() HTMLContent: string | null;

  @Output() isActiveEvent= new EventEmitter<boolean>()
  @ViewChild('headerTemplate') accordianHeaderTemplate! : ElementRef;
  
  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.HTMLContent)
      this.updateHTMLContent();
  }

  ngAfterViewInit(){
    console.log(this.HTMLContent)
    console.log(this.accordianHeaderTemplate)
    this.accordianHeaderTemplate?.nativeElement ? this.accordianHeaderTemplate.nativeElement.innerHTML = this.HTMLContent : null;
  }

  updateHTMLContent(){
    console.log(this.HTMLContent)
    if (this.accordianHeaderTemplate && this.accordianHeaderTemplate.nativeElement) {
      console.log(this.HTMLContent)
      this.accordianHeaderTemplate.nativeElement.innerHTML = this.HTMLContent;
    }
  }


  toggleAccordian(){
    if(!this.accordianDetails.isDisabled){
      this.accordianDetails.isActive = !this.accordianDetails.isActive;
      this.isActiveEvent.emit(this.accordianDetails.isActive);
    }
    else{
      return "";
    }
    
  }
}
