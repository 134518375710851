export const BreadCrumbType = {
    ADD : 'ADD',
    REMOVE: 'REMOVE'
}


export const StepperAlign = {
    HORIZONTAL : 'HORIZONTAL',
    VERTICAL : 'VERTICAL'
}


export const Theme = {
    ERROR : 'ERROR',
    WARNING: 'WARNING',
    INFO: 'INFO',
    SUCCESS: 'SUCCESS'
}


export const CarouselAlignmnet = {
    VERTICAL : 'VERTICAL',
    HORIZONTAL: 'HORIZONTAL'
}


export const CreateRideSteps = {
    CUSTOMER_DETAILS: 'Customer Details',
    JOURNEY_DETAILS: 'Journey Details',
    WEEK_SCHEDULE: 'Week Schedule',
    PAYMENT_DETAILS: 'Payment Details'
}

export const JourneyDetailsStep = {
    LOCATION_DETAILS: 'locationDetails',
    CAR_DETAILS: 'carVariantDetails',
    TIMESTAMP: 'timestampDetails',
}

export const ButtonTypes = {
    FILLED : 'FILLED',
    OUTLINED: 'OUTLINED' 
}