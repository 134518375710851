import { DutyService } from "../../../../services/duty/duty.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  ViewChildren,
  OnChanges,
  ViewChild,
  ViewRef,
  ElementRef,
  Input,
  ViewEncapsulation
} from "@angular/core";
import { PermissionForUsers } from "src/app/config/apiRouteConfig/checkroles";
import { VehicleService } from "src/app/services/vehicle/vehicle.service";
import { DriverService } from "src/app/services/driver/driver.service";
import { RideService } from "src/app/services/ride/ride.service";
import { RiderService } from "../../../../services/rider/rider.service";

import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validator,
  Validators,
} from "@angular/forms";
import { MapsAPILoader } from "@agm/core";
import { data } from "../../rides/lngLatMap";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddMoneyComponent } from "../../rides/createride/addmoney.component";
// import { CreateSharedRideComponent } from "./create-shared-ride/create-shared-ride.component";
import Swal from "sweetalert2";
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { API_URL_REDIRECT_TO_RAZORPAY } from "src/app/config/apiRouteConfig";
import { BusinessDetailsService } from "src/app/services/business-details/business-details.service";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { LocationService } from "src/app/services/location/location.service";
import { PaymentService } from "src/app/services/payment/payment.service";
import { AppService } from "src/app/services/app.service";
import { UsersService } from "src/app/services/users/users.service";
import { environment } from "src/environments/environment";
import { PhoneValidator } from "ng2-validation";
import { featureList } from "src/app/utils/country-feature-list";



@Component({
  selector: "app-create-new-ride",
  templateUrl: "./create-new-ride.component.html",
  styleUrls: ["./create-new-ride.component.css"],
  // encapsulation: ViewEncapsulation.None
})
export class CreateNewRide implements OnInit, OnDestroy{
  countryBasedFeatureList
  countinueAsAdmin = false;
  pausedData;
  triggerSharedRideFlow = false;
  registerFrom: FormGroup;
  flagToCheckDestinationState: boolean = false;
  flagToCheckPickupState: boolean = true;
  submitted: boolean = false;
  isWeekDisabled: boolean = false;
  isShowSharedRide: boolean = false;
  isEmailInvalid0: boolean = false;
  isEmailInvalid1: boolean = false;
  isEmailInvalid2: boolean = false;
  isEmailInvalid3: boolean = false;
  stepCount: number = 15;
  walletBalance = localStorage.getItem("walletBalance");
  isRideReasonMandatory = false;
  reasons = []
  reasonsList = []
  stops = [];
  multiStops = [];
  sharedRideMaxAllowedRiders: number = 4;
  sharedRideMinAllowedRiders: number = 2;
  classicCategoryImg = this.appService.getCountry() == 'IN' ? "../../../../../assets/bluClassic.png" : "../../../../../assets/audiQ3Car.png"
  selectedCountry="IN";
  @Input() public rideData;
  
  //Create Schedule or rental ride
  @ViewChild("from") public from: ElementRef;
  fromLocationAutoComplete : google.maps.places.Autocomplete | undefined;
  @ViewChild("to") public to:ElementRef;
  toLocationAutoComplete : google.maps.places.Autocomplete | undefined;

  //Shared Ride
  //Pick up
  @ViewChild("pickupLocationOne") public pickupLocationOne : ElementRef;
  @ViewChild("pickupLocationTwo") public pickupLocationTwo: ElementRef;
  @ViewChild("pickupLocationThree") public pickupLocationThree : ElementRef;
  @ViewChild("pickupLocationFour") public pickupLocationFour : ElementRef;
  @ViewChild("dropLoc") public dropLoc : ElementRef;


  //Drop off
  @ViewChild("pickupLocationDropoff") public pickupLocationDropoff: ElementRef;
  @ViewChild("dropoffStopOne") public dropoffStopOne: ElementRef;
  @ViewChild("dropoffStopTwo") public dropoffStopTwo: ElementRef;
  @ViewChild("dropoffStopThree") public dropoffStopThree: ElementRef;
  @ViewChild("dropLocDropoff") public dropLocDropoff : ElementRef;

  //tnc
  @ViewChildren ("tncSubContainer") public tncSubContainerRef;
  
  priceWithoutCoupon: any;
  classicMap=null
  premiumMap=null 
  dateToSlotMap={}
  isErrorPhone = false;
  selectedCategory = null;
  priceMapId: any;

  selectedPriceMapId = null;
  disableRiderDetails: boolean=false;
  isShowSharedRidePopup: boolean = false;
  isShowSharedNew: boolean = false;
  isShowShared: boolean = false;
  selectedValue: string = 'pickup';
  isShowDrop: boolean = false;
  isShowSchedule: boolean = false;
  isSharedRideDistanceLimitExceeded: boolean = false;
  sharedRideReason;
  paymentTypeShared;
  selectedDay1;
  selectedDay2;
  selectedDay3;
  selectedDay4;
  selectedDay5;
  selectedDay6;
  selectedDay7;
  riderPhone0;
  riderName0;
  riderEmail0;
  riderPhone1;
  riderName1;
  riderEmail1;
  riderPhone2;
  riderName2;
  riderEmail2;
  riderPhone3;
  riderName3;
  riderEmail3;
  pickupLocation0;
  pickupLocation1;
  pickupLocation2;
  pickupLocation4;
  // pickupLocationDrop;
  dropLocation;
  pickupNotes0;
  pickupNotes1;
  pickupNotes2;
  pickupNotes3;
  disableHidingStops: boolean = true;
  isTypeDisable:boolean = false;
  isSingleRideUpdate: boolean = false;
  isAdminUser:boolean = false;
  passengerNote:boolean = false;
  showLandmark: boolean = false;
  leadTime = 30;
  pickupLocationZoneId = null;
  riderList0 = [

  ];
  riderList1 = [

  ];
  riderList2 = [

  ];
  riderList3 = [

  ];
  selectedType: any;


  myForm;
  apiData: { businessCode: string; platform: string; paymentMode: string; locationDto: { lat: number; lng: number; locationName: any; pickupNote: any; }; rideTypeCategory: string; shareRideScheduleDetailsDto: { startDate: number; scheduleAt: string; days: any[]; endDate: number; }; stopsList: { 0: { locationDetails: { lat: number; lng: number; locationName: any; pickupNote: any; }; ridersList: any[]; }; 1: { locationDetails: { lat: number; lng: number; locationName: any; pickupNote: any; }; ridersList: any[]; }; 2: { locationDetails: { lat: number; lng: number; locationName: any; pickupNote: any; }; ridersList: any[]; }; }; businessReason: any; };
  sharedRideApiData: { businessCode: string; platform: string; paymentMode: string; locationDto: { lat: number; lng: number; locationName: any; pickupNote: any; }; rideTypeCategory: string; shareRideScheduleDetailsDto: { startDate: number; scheduleAt: string; days: any[]; endDate: number; }; stopsList: { 0: { locationDetails: { lat: number; lng: number; locationName: any; pickupNote: any; }; ridersList: any[]; }; 1: { locationDetails: { lat: number; lng: number; locationName: any; pickupNote: any; }; ridersList: any[]; }; 2: { locationDetails: { lat: number; lng: number; locationName: any; pickupNote: any; }; ridersList: any[]; }; }; businessReason: any; };
  sharedSingleRideApiData: { businessCode: string; locationDto: { lat: number; lng: number; locationName: any; pickupNote: any; }; stopsList: { 0: { locationDetails: { lat: number; lng: number; locationName: any; pickupNote: any; }; ridersList: any[]; }; 1: { locationDetails: { lat: number; lng: number; locationName: any; pickupNote: any; }; ridersList: any[]; }; 2: { locationDetails: { lat: number; lng: number; locationName: any; pickupNote: any; }; ridersList: any[]; }; }; rideReqId: any; };
  isLiveModifyStop: boolean = false;
  isModifyStop: boolean = false;
  isNextDisable: boolean = true;
  newWindow:Window;
  paymentIdForTeller=null;
  interval:any =[];
  userService: any;
  selectedPhonePattern:any;
  creditCardLastTxnStatus: string;
  isRideRequestApiCalled = false;

   //phone Input
   countryDetails:any;
   countryCodes = [];
   selectedCountryCode={code:'', flag:'', limit: 0}

   //expenseCode and businessRideReasons
   expenseCodeDetails = null;
   businessRideReasonDetails = null;
   otherBusinessRideReason;
   otherExpenseCode;
   isTextBusinessRideReasonEnabled = true;
   isDropDownBusinessRideReasonEnabled = false;
   isTextExpenseCodeEnabled = false;
   isDropDownExpenseCodeEnabled = false;
   selectedBusinessRideReason; 
   selectedExpenseCode; 
   isExpenseCodeEmpty = false;
   isBusinessRideReasonEmpty  = false;


  constructor(
    private _locationService: LocationService,
    private _rideService: RideService,
    private _riderService: RiderService,
    private formBuilder: FormBuilder,
    private mapApiLoader: MapsAPILoader,
    private ngZone: NgZone,
    private modal: NgbModal,
    private loader: NgxSpinnerService,
    private businessDetails: BusinessDetailsService,
    private ngbActiveModal: NgbActiveModal,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private _paymentService:PaymentService,
    private appService:AppService,
    private users: UsersService,
    private _router: Router
    ) {
      this.countryBasedFeatureList = featureList[appService.getCountry()]['createRide'];
      this.myForm = this.fb.group({
        myRadio: ['pickup', []] // This set default value
      })
      this.getCountryCode();
      this.selectedCountry =  this.appService.getCountry() == 'IN' ? "IN" : "AE";
    }

  slotOpenFlag = false;  
  disableBookRide = true;
  riderDetails = { firstName: "", lastName: "", email: "", phoneNo: "" };
  selectedLandmark: any;
  landmarks: any = [];
  rideType: any;
  paymentType: any;
  couponCode: any;
  minDate: any;
  minDateForNormalRide:any;
  minDateForSharedRide:any
  maxDate: any;
  startTime = new Date();
  packageDetails: any;
  rentalPackage: any;
  pickupLocation: any;
  dropoffLocation: any;
  businessRideReason: any;
  pickupNotesMessage: any;
  rideRequestID: any;
  sharedRideRequestID: any;
  sharedRideEstimatedAmount: any;
  allowWallet: Boolean = false;
  rideTypeList = [];
  paymentModeList = [];
  packageList = [];
  isTncChecked:Boolean = false;
  tnc:any;
  toggleTnc:boolean = false;
  selectedDate;
  selectedSlot;
  showCustomerInfoForm: boolean = true;
  showRideInfoForm: boolean = false;
  showPaymentInfoForm: boolean = false;
  showPaymentInfoFormShared: boolean = false;
  startDateTime = new Date();
  dateList=[];
  slotList=[];
  endDate;
  allowCashForRental = ['10','17','1158'];
  // displayConfirmMessage: boolean;

  // from:any;
  response: any;
  lat = 28.497219416666667;
  lng = 77.08307966666666;
  origin = { lat: 0, lng: 0 };
  origin0 = { lat: 0, lng: 0 };
  origin1 = { lat: 0, lng: 0 };
  origin2 = { lat: 0, lng: 0 };
  origin3 = { lat: 0, lng: 0 };
  drop = { lat: 0, lng: 0 };
  destination = { lat: null, lng: null };
  zoom = 10;
  pkgDetails =[]
  currencySymbol = null;

  submitForm: boolean = false;
  url =
    "https://maps.googleapis.com/maps/api/staticmap?center=" +
    this.lat +
    "," +
    this.lng +
    "&zoom=" +
    this.zoom +
    "&size=500x300&maptype=roadmap";

  key = "&key="+environment.googlePlacesAPIKey;

  checkPermission(permissionString: String) {
    return PermissionForUsers.checkPermission(permissionString);
  }

  addNewStop() {
    this.stops.push({ place: null, lat: null, lng: null, position: 0 });
  }

  removeStop(i: number) {
    this.stops = this.stops.filter((element, index) => index != i);
  }

  addNewMultiStop() {
    this.multiStops.push({ stop: "", lat: null, lng: null, position: 0 });
  }

  removeMultiStop(i: number) {
    this.multiStops = this.multiStops.filter((element, index) => index != i);
  }

  updateValueOfStop(index: number, value: any) {
    this.stops[index] = value;
  }
  items: MenuItem[];
  ngOnInit() {
    this.triggerSharedRideFlow = window.location.pathname.split("/").includes('sharedride') ? true : false;

    if(this.triggerSharedRideFlow){
       this.intialiseSharedRide();
    }

    this.getPickupBaseLocation();
    this._paymentService.lastTxnStatus$.subscribe(value => {
      this.creditCardLastTxnStatus = value;
    });
  
    this.isAdminUser =  ["346191","158699","1626","2526","1545058","62658","698957"].includes(localStorage.getItem("ssoId"))?  true : false;

    
    
    this.isShowShared = localStorage.getItem('isShowShared') == 'true' ? true : false;
    this.isShowSharedNew = localStorage.getItem('isShowSharedNew') == 'true'? true : false;
    this.isShowSharedRide =this.isShowShared
    if(this.isShowSharedRide)
      this.handleSharedRideRiderLimits()

    this.handleDatePickerTime();
    this.decideAllowWallet();
    this.items = [{ label: 'Live Ride', routerLink: '/rides/liverides' },
    { label: 'New Ride Request' }];
    
    // this.paymentType ="CASH";

    let today = new Date();

    {

      this.minDate = today;
      this.minDateForNormalRide = today;
      this.minDateForSharedRide = today;
    }
    {
      let maxDate = new Date(today.getTime());
      //changed the addition of days from 3 to 30 as per ThomasCook requirements
      maxDate.setDate(maxDate.getDate() + 30);
      maxDate.setHours(23, 0, 0, 0);
      this.maxDate = maxDate;
    }

    this.registerFrom = this.formBuilder.group({
      phoneNo: [
        "",
        [
          Validators.required,
          Validators.minLength(this.selectedCountryCode.limit),
          Validators.maxLength(this.selectedCountryCode.limit),
          Validators.pattern
        ],
      ],
      firstName: ["", Validators.required],
      lastName: [""],
      email: ["", [Validators.required, Validators.email]],
    });
    this.isRideReasonMandatory = this.businessDetails.getIsBusinessRideReasonMandatory()
    this.reasons = this.businessDetails.getRideReason();
    if (this.reasons) {
      this.reasons.forEach(element => {
        this.reasonsList.push({ label: element, value: element })
      })
      this.businessRideReason = this.reasonsList[0];
    }
    this.populateRideType();
    this.populatePaymentMode();

  }

  handleSharedRideRiderLimits(){
    this.sharedRideMaxAllowedRiders = this.businessDetails.getSharedRideMaxLimit()
    this.sharedRideMinAllowedRiders = this.businessDetails.getSharedRideMinLimit();
  }

  get h() {
    return this.registerFrom.controls;
  }

  getUserInfo() {
    this.isErrorPhone = false;  
    let phoneNo = this.registerFrom.controls["phoneNo"].value;
    this.registerFrom.controls['firstName'].setValue("");
    this.registerFrom.controls['lastName'].setValue("");
    this.registerFrom.controls['email'].setValue("");
    this.disableRiderDetails=false

    if (phoneNo.toString().length != this.selectedCountryCode.limit) {
      return;
    } 
    else {
      let phoneRegex = /^\d+$/;
       
      if(!phoneRegex.test(phoneNo)){
        this.isErrorPhone = true;
      }
      else{
        this.isErrorPhone = false;
        let countryCode = this.selectedCountryCode.code.split("+")
      this._riderService.getRiderData(phoneNo, countryCode[1]).subscribe((response) => {

        if (response.statusCode >= 300) {
          Swal.fire({
            title: "Error",
            icon: "info",
            text: response.message,
          });} 
        else {
          if (response.response) {
            this.disableRiderDetails=true
            this.registerFrom.controls['firstName'].setValue(
              response.response.firstName
            );
            this.registerFrom.controls['lastName'].setValue(
              response.response.lastName
            );
            this.registerFrom.controls['email'].setValue(response.response.email);
          }}});
      }
      }}

  getUserInfoForSharedRide(phoneNo, type) {
    if (phoneNo == undefined || phoneNo.toString().length != this.selectedCountryCode.limit) {
      return;
    } else {
      this._riderService.getRiderData(phoneNo, this.selectedCountryCode.code.split("+")[1]).subscribe((response) => {

        if (response.statusCode >= 300) {
          Swal.fire({
            title: "Error",
            icon: "info",
            text: response.message,
          });
        } else {
          if (response.response) {

              switch(type){
                case 'one':
                  this.riderName0 = response.response.lastName ? response.response.firstName + ' ' + response.response.lastName : response.response.firstName;
                  this.riderEmail0 = response.response.email;
                  this.isEmailInvalid0 = false;
                  break;
                case 'two':
                  this.riderName1 = response.response.lastName ? response.response.firstName + ' ' + response.response.lastName : response.response.firstName;
                  this.riderEmail1 = response.response.email;
                  this.isEmailInvalid1 = false;
                  break;
                case 'three':
                  this.riderName2 =  response.response.lastName ? response.response.firstName + ' ' + response.response.lastName : response.response.firstName;
                  this.riderEmail2 = response.response.email;
                  this.isEmailInvalid2 = false;
                  break;
                case 'four':
                    this.riderName3 =  response.response.lastName ? response.response.firstName + ' ' + response.response.lastName : response.response.firstName;
                    this.riderEmail3= response.response.email;
                    this.isEmailInvalid3 = false;
                    break;  
              }

          }
        }
      });
    }
  }

  ngAfterViewInit() {

    // console.log(this.fromLocation)

    // this.fromLocation.first.value = this.pickupLocation;
    this.mapApiLoader.load().then(() => {
      this.getPlaceAutocomplete();
      let bound1 = data[1];
      let newArray = [];

      for (let i = 0; i < bound1.length; i++) {
        newArray.push(
          new google.maps.LatLng(bound1[i].latitude, bound1[i].longitude)
        );
      }

      let defaultBounds = new google.maps.LatLngBounds(...newArray);

        let fromLocationAutoComplete = new google.maps.places.Autocomplete( this.from.nativeElement,
          {
            fields: [
              "address_component",
              "adr_address",
              "formatted_address",
              "geometry",
              "place_id",
            ],
            componentRestrictions: { country: this.appService.getCountry() },
            bounds: defaultBounds,
          }
        );

        fromLocationAutoComplete.setBounds(defaultBounds);

        fromLocationAutoComplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            this.flagToCheckPickupState = true;
            let place: google.maps.places.PlaceResult = fromLocationAutoComplete.getPlace();
            this.pickupLocation = place.formatted_address;
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            this.origin = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };
            this.showLandmarks({
              lat: this.origin.lat,
              lng: this.origin.lng,
              locationType: "PICKUP",
            });
            this.zoom = 12;
            if(this.rideType == 'SCHEDULED_RENTALS' && this.origin.lat != null && this.origin.lng != null){
              let data ={};
              this.loader.show()
              this.apiCalled =true;
              this._rideService.getPkgDetailsRegionWise(this.origin).subscribe(response =>{
                this.loader.hide();
                this.apiCalled= false;
                if (response["statusCode"] >= 300) {
                  Swal.fire({
                    title: 'Error',
                    icon: 'info',
                    text: response['message']
                  });
                  this.close();
                }else{
                  data = response['response'];
                  
                  this.packageList = []
                  this.pkgDetails = []
                  let allPkgCode = Object.keys(data);
                  for (const iterator of allPkgCode) {
                    this.pkgDetails.push({packageCode: iterator, baseDistance: data[iterator]['baseDistance'], baseTime: data[iterator]['baseTime'], categories: data[iterator]['categories']});
                  }
                  this.populatePachakgeList();
                } 

              },error=>{
                this.loader.hide();
                this.apiCalled= false;
              })
            }
          });
        });
      

      
        let toLocationAutoComplete = new google.maps.places.Autocomplete(this.to.nativeElement,
          {
            fields: [
              "address_component",
              "adr_address",
              "formatted_address",
              "geometry",
              "place_id",
            ],
            componentRestrictions: { country: this.appService.getCountry() },
            bounds: defaultBounds,
          }
        );
        toLocationAutoComplete.setBounds(defaultBounds);

        toLocationAutoComplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            this.flagToCheckDestinationState = true;
            let place: google.maps.places.PlaceResult = toLocationAutoComplete.getPlace();
            this.dropoffLocation = place.formatted_address;
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            this.destination = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };
            this.zoom = 12;
          });
        });
      


      // pickupLocation0 suggestion starts here
      
      let pickupLocationOneAutoComplete = new google.maps.places.Autocomplete(this.pickupLocationOne.nativeElement,
            {
              fields: [
                "address_component",
                "adr_address",
                "formatted_address",
                "geometry",
                "place_id",
              ],
              componentRestrictions: { country: this.appService.getCountry() },
              bounds: defaultBounds,
            }
          );

          pickupLocationOneAutoComplete.setBounds(defaultBounds);
          pickupLocationOneAutoComplete.addListener("place_changed", () => {

                      this.ngZone.run(() => {
              this.flagToCheckPickupState = true;
              let place: google.maps.places.PlaceResult = pickupLocationOneAutoComplete.getPlace();
              this.pickupLocation0 = place.formatted_address;
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
              this.origin0 = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              this.showLandmarks({
                lat: this.origin0.lat,
                lng: this.origin0.lng,
                locationType: "PICKUP",
              });
              this.zoom = 12;
            });
          });


      
          let autoCompletePickupForDropoff = new google.maps.places.Autocomplete(this.pickupLocationDropoff.nativeElement,
            {
              fields: [
                "address_component",
                "adr_address",
                "formatted_address",
                "geometry",
                "place_id",
              ],
              componentRestrictions: { country: this.appService.getCountry() },
              bounds: defaultBounds,
        }
);
              autoCompletePickupForDropoff.setBounds(defaultBounds);
              autoCompletePickupForDropoff.addListener("place_changed", () => {
            
            this.ngZone.run(() => {
              this.flagToCheckPickupState = true;
              let place: google.maps.places.PlaceResult = autoCompletePickupForDropoff.getPlace();
              this.pickupLocation0 = place.formatted_address;
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
              this.origin0 = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              this.showLandmarks({
                lat: this.origin0.lat,
                lng: this.origin0.lng,
                locationType: "PICKUP",
              });
              this.zoom = 12;
            });
          });
      //pickupLocation0 suggestion ends here
      

      // pickupLocation1 suggestion starts here

              let autocomplete3 = new google.maps.places.Autocomplete(
                this.pickupLocationTwo.nativeElement,
                {
                  fields: [
                    "address_component",
                    "adr_address",
                    "formatted_address",
                    "geometry",
                    "place_id",
                  ],
                  componentRestrictions: { country: this.appService.getCountry() },
                  bounds: defaultBounds,
                }
              );

              autocomplete3.setBounds(defaultBounds);

              autocomplete3.addListener("place_changed", () => {
                this.ngZone.run(() => {
                  this.flagToCheckPickupState = true;
                  let place: google.maps.places.PlaceResult = autocomplete3.getPlace();
                  this.pickupLocation1 = place.formatted_address;
                  if (place.geometry === undefined || place.geometry === null) {
                    return;
                  }
                  this.origin1 = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                  };
                  this.zoom = 12;
                });
              });



              let autocomplete3Dropoff = new google.maps.places.Autocomplete(
                this.dropoffStopOne.nativeElement,
                {
                  fields: [
                    "address_component",
                    "adr_address",
                    "formatted_address",
                    "geometry",
                    "place_id",
                  ],
                  componentRestrictions: { country: this.appService.getCountry() },
                  bounds: defaultBounds,
                }
              );

              autocomplete3Dropoff.setBounds(defaultBounds);

              autocomplete3Dropoff.addListener("place_changed", () => {
                this.ngZone.run(() => {
                  this.flagToCheckPickupState = true;
                  let place: google.maps.places.PlaceResult = autocomplete3Dropoff.getPlace();
                  this.pickupLocation1 = place.formatted_address;
                  if (place.geometry === undefined || place.geometry === null) {
                    return;
                  }
                  this.origin1 = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                  };
                  this.zoom = 12;
                });
              });
          //pickupLocation1 suggestion ends here



          // pickupLocation2 suggestion starts here
          let autocomplete4 = new google.maps.places.Autocomplete(
          this.pickupLocationThree.nativeElement,
          {
            fields: [
              "address_component",
              "adr_address",
              "formatted_address",
              "geometry",
              "place_id",
            ],
            componentRestrictions: { country: this.appService.getCountry() },
            bounds: defaultBounds,
          }
        );

        autocomplete4.setBounds(defaultBounds);
        autocomplete4.addListener("place_changed", () => {
          this.ngZone.run(() => {
            this.flagToCheckPickupState = true;
            if(this.riderList0.length >=1 || this.myForm.value.myRadio == 'dropoff'){

              let place: google.maps.places.PlaceResult = autocomplete4.getPlace();

              this.pickupLocation2 = place.formatted_address;
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }

              this.origin2 = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              this.zoom = 12;
            }

          });
        });

        // for pickup location 4 starts 
        let autocomplete6 = new google.maps.places.Autocomplete(
          this.pickupLocationFour.nativeElement,
          {
            fields: [
              "address_component",
              "adr_address",
              "formatted_address",
              "geometry",
              "place_id",
            ],
            componentRestrictions: { country: this.appService.getCountry() },
            bounds: defaultBounds,
          }
        );

        autocomplete6.setBounds(defaultBounds);
        autocomplete6.addListener("place_changed", () => {
          this.ngZone.run(() => {
            this.flagToCheckPickupState = true;
            if(this.riderList0.length >= 1 || this.myForm.value.myRadio == 'dropoff'){

              let place: google.maps.places.PlaceResult = autocomplete6.getPlace();

              this.pickupLocation4 = place.formatted_address;
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }

              this.origin3 = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              this.zoom = 12;
            }

          });
        });
        // for pickup location 4 ends

        let autocomplete4Dropoff = new google.maps.places.Autocomplete(
          this.dropoffStopTwo.nativeElement,
          {
            fields: [
              "address_component",
              "adr_address",
              "formatted_address",
              "geometry",
              "place_id",
            ],
            componentRestrictions: { country: this.appService.getCountry() },
            bounds: defaultBounds,
          }
        );

        autocomplete4Dropoff.setBounds(defaultBounds);
        autocomplete4Dropoff.addListener("place_changed", () => {
          this.ngZone.run(() => {
            this.flagToCheckPickupState = true;
            if(this.riderList0.length >= 1 || this.myForm.value.myRadio == 'dropoff'){

              let place: google.maps.places.PlaceResult = autocomplete4Dropoff.getPlace();

              this.pickupLocation2 = place.formatted_address;
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }

              this.origin2 = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              this.zoom = 12;
            }

          });
        });
      //pickupLocation2 suggestion ends here

      //dropodd stop 3 starts 
      let autocompleteDropoffStop3 = new google.maps.places.Autocomplete(
        this.dropoffStopThree.nativeElement,
        {
          fields: [
            "address_component",
            "adr_address",
            "formatted_address",
            "geometry",
            "place_id",
          ],
          componentRestrictions: { country: this.appService.getCountry() },
          bounds: defaultBounds,
        }
      );

      autocompleteDropoffStop3.setBounds(defaultBounds);
      autocompleteDropoffStop3.addListener("place_changed", () => {
        this.ngZone.run(() => {
          this.flagToCheckPickupState = true;
          if(this.riderList0.length >= 1 || this.myForm.value.myRadio == 'dropoff'){

            let place: google.maps.places.PlaceResult = autocompleteDropoffStop3.getPlace();

            this.pickupLocation4 = place.formatted_address;
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            this.origin3 = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };
            this.zoom = 12;
          }

        });
      });
      //dropoff stop3 ends 

        
      // this.dropLoc.changes.subscribe((result) => {
      //  if(result.first){
        let autocomplete5 = new google.maps.places.Autocomplete(
          this.dropLoc.nativeElement,
          {
            fields: [
              "address_component",
              "adr_address",
              "formatted_address",
              "geometry",
              "place_id",
            ],
            componentRestrictions: { country: this.appService.getCountry() },
            bounds: defaultBounds,
          }
        );

        autocomplete5.setBounds(defaultBounds);
        
        autocomplete5.addListener("place_changed", () => {
          this.ngZone.run(() => {
            this.flagToCheckPickupState = true;
              let place: google.maps.places.PlaceResult = autocomplete5.getPlace();
              this.dropLocation = place.formatted_address;
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
              this.drop = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              this.zoom = 12;
          });
        });

        let autocompleteDropForDropoff = new google.maps.places.Autocomplete(
          this.dropLocDropoff.nativeElement,
          {
            fields: [
              "address_component",
              "adr_address",
              "formatted_address",
              "geometry",
              "place_id",
            ],
            componentRestrictions: { country: this.appService.getCountry() },
            bounds: defaultBounds,
          }
        );

        autocompleteDropForDropoff.setBounds(defaultBounds);
        
        autocompleteDropForDropoff.addListener("place_changed", () => {
          this.ngZone.run(() => {
            this.flagToCheckPickupState = true;
              let place: google.maps.places.PlaceResult = autocompleteDropForDropoff.getPlace();
              this.dropLocation = place.formatted_address;
              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
              this.drop = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              this.zoom = 12;
          });
        });


      //  }
      // });
      
    });

    setTimeout(() => {
      if(localStorage.getItem('isOpenShared') == 'true'){

        this.openSharedRideModal()
      }
    }, 50);

  }

  getPlaceAutocomplete() {
		const  autocomplete  =  new google.maps.places.Autocomplete(this.pickupLocationTwo.nativeElement,
		{
			componentRestrictions: { country:  'US' },
			types: ['establishment', 'geocode']
		});

		google.maps.event.addListener(autocomplete, 'place_changed', () => {
			const  place  =  autocomplete.getPlace();
			const  myLatlng  =  place.geometry.location;
			const  mapOptions  = {
				zoom:  15,
				center:  myLatlng
			};
		//	const  map  =  new  google.maps.Map(this.gmapElement.nativeElement, mapOptions);
			const  marker  =  new google.maps.Marker({
				position:  myLatlng,
				title:  place.name
			});
		//	marker.setMap(map);
		});
	}


  onResponse(event: any) {
    this.response = event;
  }

  resetLatLong(input) {
    // console.log(input)
    
    this.pickupLocation == ""?this.showLandmark = false : null;
    if (input == "from" || input == "pickupLocation0"  || input == "pickupLocation1" || input == "pickupLocation2" || input == "pickupLocation4") {
      this.origin.lat = null;
      this.origin.lng = null;
      this.flagToCheckPickupState = false;
    } else if (input == "to") {
      this.destination.lat = null;
      this.destination.lng = null;
      this.flagToCheckDestinationState = false;
    }
  }

  showLandmarks(body) {
    this.showLandmark = false;
    
      this.loader.show()
      this._locationService.getLocationMetadata(body).subscribe((res) => {
      this.loader.hide()
        if (res['statusCode'] >= 300) {
        this.pickupLocationZoneId = null;
          Swal.fire({
            title: 'Error',
            icon: 'info',
            text: res['message']
          });
        }else{
          this.pickupLocationZoneId = res['response']['zoneId'];
          if (this.businessDetails.getIsSubPlaceEnabledFlag() && res['response']['subPlacesDto'] != null) {
            
            this.showLandmark = true;
            this.landmarks = res['response']['subPlacesDto'].subPlaces.map((dto) => {
              return {
                name: dto.displayName,
                code: dto.displayName.toUpperCase(),
                lat: dto.latitude,
                lng : dto.longitude
              };
            });
        }else {
            this.showLandmark = false
          }
          this.leadTime = res['response']['leadTimeInMillis'] ? (res['response']['leadTimeInMillis'])/60000 : 30 ;
          this.handleDatePickerTime();
        }
      },err=>{
        this.loader.hide()
        Swal.fire({
          title: 'Error',
          icon: 'info',
          text: 'Technical error'
        });
        this.pickupLocationZoneId = null;
      });
  }

  calculateLeadTime(){
    return this.leadTime;
  }

  resetScreenToDispalyMap(event?) {
    this.submitForm = false;
    this.disableBookRide = true;
  }

checkValue(){
  // console.log(this.myForm)
  
  this.showLandmark = false;
  this.pickupLocation0 = null;
  this.pickupLocation1 = null;
  this.pickupLocation2 = null;
  this.dropLocation = null;
  this.riderList0 = [];
  this.riderList1 = [];
  this.riderList2 = [];
  this.riderList3 = [];
  this.pickupNotes0 = null;
  this.pickupNotes1 = null;
  this.pickupNotes2 = null;
  this.pickupNotes3 = null;
  this.isShowSchedule = false;
  this.isNextDisable = true;
  // this.startDateTime = null;
  this.paymentTypeShared = null;
  this.sharedRideReason = null;
  this.sharedRideEstimatedAmount = null;
  // this.endDate = null;
  this.selectedDay1 = null;
  this.selectedDay2 = null;
  this.selectedDay3 = null;
  this.selectedDay4 = null;
  this.selectedDay5 = null;
  this.selectedDay6 = null;
  this.selectedDay7 = null;
  this.origin = { lat: 0, lng: 0 };
  this.origin0 = { lat: 0, lng: 0 };
  this.origin1 = { lat: 0, lng: 0 };
  this.origin2 = { lat: 0, lng: 0 };
  this.drop = { lat: 0, lng: 0 };
  if(this.selectedType == 'pickup' || this.myForm.value.myRadio == 'pickup'){
    if((this.riderList0.length + this.riderList2.length + this.riderList1.length) >= 2){
      this.isShowDrop = true;
    }else{
      this.isShowDrop = false;
    }
  }else if(this.selectedType == 'dropoff' || this.myForm.value.myRadio == 'dropoff'){
    if((this.riderList0.length + this.riderList2.length + this.riderList1.length) >= 2){
      this.isShowDrop = true;
    }else{
      this.isShowDrop = false;
    }
  }


}

toggleTnC=() =>{
  this.disableBookRide = (this.priceWithoutCoupon != null && this.isTncChecked == true && this.rideType == 'SCHEDULED_RENTALS') ?   false : true;
  if(this.showPaymentInfoForm ==true && this.rideType == 'SCHEDULED_RIDE')
    this.disableBookRide =false;
 }


  onSubmit() {
    this.submitted = true;

    if (this.registerFrom.invalid) {
      return;
    }
  }

  SubmitJourneyDetails() {
    
    let stopsInPayload  = [];

    if(this.showLandmark == true && this.selectedLandmark == null){
    
      Swal.fire({
        title: "Error",
        icon: "info",
        text: "Please select landmark",
      });
      return;
    }

    if (this.origin.lat == null || this.origin.lng == null) {
      
      Swal.fire({
        title: 'Error',
        icon: 'info',
        text: "Please select the location form Suggestion"
      });
      return;
    }


    if ((this.destination.lat == null || this.destination.lng == null) && this.rideType == 'SCHEDULED_RIDE') {
      
      Swal.fire({
        title: 'Error',
        icon: 'info',
        text: "Please select the location form Suggestion"
      });
      return;

    }
    if (this.multiStops.length > 0) {
      
      let valid = true;
      this.multiStops.forEach(stop => {
        if (stop.lat == null || stop.lng == null || stop.lat == 0 || stop.lng == 0) {
          valid = false;
        }
      })
      if (!valid) {
        
        Swal.fire({
          title: 'Error',
          icon: 'info',
          text: "Please select the location from Suggestion"
        });
        return;

      }

    }
    if (this.rideType == 'SCHEDULED_RENTALS') {
      
      let valid = true;
      this.stops.forEach(stop => {
        if (stop.lat == null || stop.lng == null) {
          valid = false;
        }
      })
      if (!valid) {
        
        Swal.fire({
          title: 'Error',
          icon: 'info',
          text: "Please select the location form Suggestion"
        });
        return;
      }
      this.destination.lng = null;
      this.destination.lat = null;
      
      this.stops.forEach(stop => {
        if (stop['stop']) {
          stop['place'] = stop['stop'];
        }

      })
      stopsInPayload = Object.assign([], this.stops);
    }
    this.submitForm = true;
    if (this.rideType == 'SCHEDULED_RIDE') {

      if (this.multiStops.length > 0) {
        this.multiStops.forEach(stop => {
          if (stop['stop']) {
            stop['place'] = stop['stop'];
          }

        })
        stopsInPayload = Object.assign([], this.multiStops);
        stopsInPayload.push({ stop: this.to.nativeElement.value, lat: this.destination.lat, lng: this.destination.lng, position: parseInt(this.multiStops[this.multiStops.length - 1]['position']) + 1, place: this.to.nativeElement.value });
      } else {
        stopsInPayload = [];
      }

    }
    let resBody = {
      dropLat: this.destination.lat,
      dropLocation: this.rideType == 'SCHEDULED_RENTALS' ? null : this.to.nativeElement.value,
      dropLong: this.destination.lng,
      email: this.registerFrom.controls['email'].value,
      firstName: this.registerFrom.controls['firstName'].value,
      lastName: this.registerFrom.controls['lastName'].value,
      map: "",
      packageCode: this.rentalPackage,
      paymentMode: this.paymentType,
      phoneNumber: this.registerFrom.controls['phoneNo'].value,
      pickupLat: this.showLandmark ? this.selectedLandmark.lat : this.origin.lat,
      pickupLocation: this.showLandmark? this.selectedLandmark['name'] : this.from.nativeElement.value,
      pickupLong: this.showLandmark ? this.selectedLandmark.lng : this.origin.lng,
      platform: "WEB",
      rideType: this.rideType,
      startTime: this.getStartTime(),
      stops: stopsInPayload,
      priceMapId: this.rideType == 'SCHEDULED_RENTALS' ? this.selectedPriceMapId : null,
      callingCode: this.selectedCountryCode.code.toString(),
      otherFlags: {}
    };




    if (this.selectedBusinessRideReason || this.isTextBusinessRideReasonEnabled) {
      if(this.isTextBusinessRideReasonEnabled){
        resBody['otherFlags']['businessRideReason'] = this.otherBusinessRideReason  
      }
      else{
        resBody['otherFlags']['businessRideReason'] = this.selectedBusinessRideReason.code
      }
    }

    if (this.selectedExpenseCode || this.isTextExpenseCodeEnabled) {
      if(this.isTextExpenseCodeEnabled){
        resBody['otherFlags']['expenseCode'] = this.otherExpenseCode  
      }
      else{
        resBody['otherFlags']['expenseCode'] = this.selectedExpenseCode.code  
      }
    }


    if (this.pickupNotesMessage != null && this.pickupNotesMessage.trim().length > 1) {
      resBody['pickupNotes'] = {
        "createdTimestamp": moment().valueOf(),
        "isForAll": false,
        "lastUpdatedTimestamp": moment().valueOf(),
        "latitude": this.origin.lat,
        "longitude": this.origin.lng,
        "notes": this.pickupNotesMessage,
      }
    }


    if(this.businessRideReasonDetails && this.businessRideReasonDetails['isEnabled']){
    if(this.businessRideReasonDetails['isMandatory']){
        if(resBody['otherFlags']['businessRideReason']== null || resBody['otherFlags']['businessRideReason'] == undefined){
          this.isBusinessRideReasonEmpty = true;
          return;
        }
        else{
          this.isBusinessRideReasonEmpty = false;
        }
    }
  }

    if(this.expenseCodeDetails && this.expenseCodeDetails['isEnabled']){
    if(this.expenseCodeDetails['isMandatory']){
      if(resBody['otherFlags']['expenseCode']== null || resBody['otherFlags']['expenseCode'] == undefined){
        this.isExpenseCodeEmpty = true;
        return;
      }
      else{
        this.isExpenseCodeEmpty = false;
      }
  }
}

    this.loader.show();
    if (this.apiCalled) {
      this.loader.hide();
      return;
    }


    this.isRideRequestApiCalled = true;
    this._rideService.createLiveRide(resBody).subscribe((response: any) => {
      this.loader.hide();

      if (response.statusCode >= 300) {
        this.apiCalled = false
        if (response.message.includes('do not serve')) {
          response.message = 'We currently do not have pickup facility from your specified pickup location.'
        }
        if (response.message.includes('Classic rentals beyond 6hr/60km and premium rentals are currently available only in Delhi NCR.')) {
          response.message = 'Classic rentals beyond 6hr/60km and premium rentals are currently available only in Delhi NCR.'
        }
        Swal.fire({
          title: 'Error',
          icon: 'info',
          text: response.message
        });
      }
      else {
        this.loader.hide();
        this.apiCalled = false
        this.rideRequestID = response.response.rideRequestId;
        this.toggleTnc == false? this.disableBookRide = false : this.disableBookRide = true;

        this.priceWithoutCoupon = response.response;
        this.showRideInfoForm = false;
        this.showCustomerInfoForm = false;
        this.showPaymentInfoForm = true;
        this.toggleTnC();
      }
    }, error => {
      this.loader.hide();
      this.apiCalled = false
    })

  }

  apiCalled = false;

  bookRide() {
    // this.bookRideV1();
    this.bookRideV2();

  }

  bookRideV2(){
    this.loader.show()
    this._paymentService.addMoneyToWalletFromTeller(this.rideRequestID).subscribe((response:any)=>{
      this.loader.hide()
      if (response.statusCode >= 300) {
  
        Swal.fire({
          title: 'Error',
          icon: 'info',
          text: response.message
        });
      } 
      else if( response['response']['isApplicable']){

        let url= response['response']['redirectionUrl'];
          this.paymentIdForTeller = response['response']['paymentOrderId'];
          //  this.newWindow = window.open(API_URL_REDIRECT_TO_RAZORPAY+paymentid+'?phoneNo='+localStorage.getItem('phoneNo') +'&email='+localStorage.getItem('email')+'','_blank');
           this.newWindow = window.open(url,"Add Money","toolbar=no,scrollbars=no,location=no,statusbar=no,menubar=no,resizable=0,width=300,height=300,left = 490,top = 262");
           this.keepEye(this.newWindow);
      }
        else{
          this.bookRideV1()          
        }
    
    },(err)=>{
      this.loader.hide();
      Swal.fire({
        title: 'Error',
        icon: 'info',
        text: "Technical Error"
      });
    })
  }

  keepEye(window:Window){
  
    let newInterval = setInterval(()=> {
       if(window.closed){
        //  console.log("closed");
         this._paymentService.checkStatus(this.paymentIdForTeller).subscribe((response:any)=>{
           if(response.status>=300){
            Swal.fire({
              title: 'Error',
              icon: 'info',
              text: response.message
            });
            this.clearAllIntervals();
           }
           if(response.response=='COMPLETED'){
             let message= "Amount added successfully !!"
            Swal.fire({
              title: '<span style="color:#5DCF5E">Payment Successful</span>',
              // icon: 'info',
              imageUrl:'../../../../../assets/addMoneySuccess.png',
              text: message
            }).then(()=>{
              this.bookRideV1();
            });
           
            this.clearAllIntervals();
           }else {
            Swal.fire({
              title: 'Info',
              icon: 'info',
              text: "Transaction is in Status: "+ response.response
            });
            this.clearAllIntervals();
           }
         })
         
       }
  },1000);
  this.interval.push(newInterval);
   }

   clearAllIntervals(){
    for (let i = 0; this.interval &&  i < this.interval.length; i++) {
      clearInterval(this.interval[i]);
      }
      if(this.interval)
        this.interval.length = 0;
   }

  bookRideV1(){
    this.loader.show();
    if (this.apiCalled) {
      this.loader.hide();
      return;
    }
    this.apiCalled = true
    this._rideService.performActionOnRide(this.rideRequestID, { "action": "CONFIRM" }).subscribe(async (res: any) => {
      this.loader.hide();
      if (res.statusCode >= 300) {
        this.apiCalled = false;
        let title = "Error"
        if (res.message.includes('BALANCE_INSUFFICIENT')) {
          res.message = 'Insufficient balance, please contact your admin.'
        }else if(res.message.includes('SPENDING_LIMIT_INSUFFICIENT')){
           title="<span style=\"color:#F7685B;padding: 13px 43px;\">Monthly spending limit used up</span>"
           res.message = 'The rider’s monthly spending limit has been reached. Please increase the limit for this rider to book more rides during this month.'
          }
        Swal.fire({
          imageUrl:"../../../../../assets/warningIcon.png",
          title: title,
          text: res.message
        });

      } 
      // if payment type is credit card
        else if(res['response']['paymentMode'] == 'CREDIT_CARD'){
          let creditCardDto = {
            gatewayOrderId : res['response']['paymentDetailsDto']['gatewayOrderId'],
            transactionId: res['response']['paymentDetailsDto']['transactionId'],
            orderId : res['response']['paymentDetailsDto']['orderId']
          }
           this._paymentService.getrazorpayDetails(creditCardDto);
           this._paymentService.getCreditCardStatusForRazorPay(this.updateCreditCardStatusOfLaxtTxn.bind(this));
        }

        // for other payment types
        else{
        this.apiCalled = false;
        this.close();
        Swal.fire({
          title: '',
          imageUrl: "../../../../../assets/confirmMessageIcon.png",
          text: "Ride Booked Successfully"
        });

        if (this.paymentType == "BUSINESS_ACCOUNT") {
          let balance = this.businessDetails.getWalletBalamce();
          if (balance) {
            let amountToDeduct = this.priceWithoutCoupon.pricingDetails ? this.priceWithoutCoupon.pricingDetails.estimatedTotalAmount : 0;
            balance = parseInt(balance) - amountToDeduct;
            this.businessDetails.setWalletBalance(balance);
          }
        }
        this.close();
      }
      
    }, err => {
      this.loader.hide();
      this.apiCalled = false
    })
  }

  // final steps to confirm ride for credit card
  updateCreditCardStatusOfLaxtTxn(status:any){
    this.creditCardLastTxnStatus =status;
    this.loader.show();
   setTimeout(()=>{
    if(this.creditCardLastTxnStatus == 'Success'){
      this._paymentService.getCreditCardRideStatus().subscribe(res=>{
        this.loader.hide();
        if(res['statusCode']>=300){
          Swal.fire({
              icon: 'error',
              title: 'Warning',
              text: res['message']
          })
        }
      
        else{
            this._rideService.performActionOnRide(this.rideRequestID, { "action": "CONFIRM" }).subscribe((res: any) => {
              this.loader.hide();
              if(res['statusCode']>=300){
                Swal.fire({
                    icon: 'error',
                    title: 'Warning',
                    text: res['message']
                })
              }

            else{
              if(res['response']['paymentDetailsDto']){
                Swal.fire({
                  icon: 'error',
                  title: 'Warning',
                  text: 'Something Went Wrong!'
              })
              }

              else{
                Swal.fire({
                  title: '',
                  imageUrl: "../../../../../assets/confirmMessageIcon.png",
                  text: "Ride Booked Successfully"
                });
              }
            }  
            
            })
          }
      })
      this.apiCalled = false;
      this.close();
  }
   },4000)
  }


  checkStops() {
    if (this.stops.length > 0) {
      this.stops.forEach((element) => {
        if (element.place == "") {
          return false;
        }
      });
    }
    return true;
  }

  openModal() {
    //console.log("From: " + this.from.first.nativeElement);
    const modal = this.modal.open(AddMoneyComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg",
    });

    // (<CreaterideComponent>modal.componentInstance).dataToTakeAsInput = param;

    modal.result
      .then((result) => {
        //console.log(result);
      })
      .catch((result) => {
        //console.log(result);
      });
  }
  rideTypeChange (){
    this.isSlotOpen(this.rideType)
    this.showLandmark = false;
    this.disableBookRide = true;
    this.pickupLocation = null;
    this.packageList.length = 0;
   if(this.rideType =="SCHEDULED_RENTALS"){
      this.passengerNote = true;

      this._riderService.getTnC().subscribe((response)=>{
         this.toggleTnc = true;
        this.tncSubContainerRef.first.nativeElement.innerHTML = response['response']['RENTAL_REVAMP_TNC'];
      },(error=>{this.toggleTnc = false})
      )
      
      // this.rentalPackage = this.packageList[0].value
      // this.getCategoryByPackageCode(this.rentalPackage)
      this.paymentModeList.forEach((element,index) => {
        if((element.label =='Cash' && !this.allowCashForRental.includes(localStorage.getItem('businessAccountId'))) && (this.appService.getCountry() == "IN")){
          this.paymentModeList.splice(index,1);
        }
      });
      this.paymentType = this.paymentModeList[0].value
      this.getPickupBaseLocation();
    } 
    
    else {
      this.getPickupBaseLocation();
      this.passengerNote=false;
    this.rentalPackage= null;
    this.paymentModeList = []
    this.populatePaymentMode()
   }
  this.selectedCategory=null;
  }

  populateRideType() {
    // this.rideTypeList.splice(0,this.rideTypeList.length);
    let allowed = this.businessDetails.getAllowedRideType();


    if (allowed) {
      for (let element of allowed) {
        if (element == "SCHEDULED_RIDE") {
          this.rideTypeList.push({ label: 'Schedule Ride', value: "SCHEDULED_RIDE" })
        }
        if (element == "SCHEDULED_RENTALS") {
          this.rideTypeList.push({ label: 'Schedule Rental', value: "SCHEDULED_RENTALS" })
        }
      }
      this.rideType = this.rideTypeList[0].value;
      if(this.rideType ==  'SCHEDULED_RENTALS')
          this.handlePassengerNote()
      
    }
  }

  handlePassengerNote(){
    this.passengerNote = true;

    this._riderService.getTnC().subscribe((response)=>{
       this.toggleTnc = true;
      this.tncSubContainerRef.first.nativeElement.innerHTML = response['response']['RENTAL_REVAMP_TNC'];
    },(error=>{this.toggleTnc = false})
    )
  }

  populatePachakgeList() {
    this.packageList = [];
    this.pkgDetails.forEach(element => {
      let km = (element.baseDistance / 1000);
      let time = (element.baseTime / 3600000)
      let label = km + ' Km / ' + time + ' hr';
      this.packageList.push({ "label": label, "value": element.packageCode })
    });
    this.rentalPackage = this.packageList[0].value
    this.getCategoryByPackageCode(this.packageList[0].value)
  }

  populatePaymentMode() {
    this.paymentModeList = [];
    let allowed = this.businessDetails.getAcceptedPaymentType();
    
    console.log(allowed);
    for (let element of allowed) {
      if(this._router.url.split("/").includes('sharedride')){
        if (element == 'CASH' ) {
          null;
        }
        if (element == 'BUSINESS_ACCOUNT' && this.appService.getCountry() == "IN") {
          this.paymentModeList.push({ label: 'Wallet', value: element })
        }
        if (element == 'BUSINESS_ACCOUNT' && this.appService.getCountry() == "AE") {
          this.paymentModeList.push({ label: 'Postpaid', value: element })
        }
        if (element == 'PREPAID') {
          this.paymentModeList.push({ label: 'Pre Paid', value: element })
        }
        if (element == 'CREDIT_CARD') {
         null;
        }
      }

      else{
        if (element == 'CASH' ) {
          this.paymentModeList.push({ label: 'Cash', value: element })
        }
        if (element == 'BUSINESS_ACCOUNT' && this.appService.getCountry() == "IN") {
          this.paymentModeList.push({ label: 'Wallet', value: element })
        }
        if (element == 'BUSINESS_ACCOUNT' && this.appService.getCountry() == "AE") {
          this.paymentModeList.push({ label: 'Postpaid', value: element })
        }
        if (element == 'PREPAID') {
          this.paymentModeList.push({ label: 'Pre Paid', value: element })
        }
        if (element == 'CREDIT_CARD') {
          this.paymentModeList.push({ label: 'Credit Card', value: element })
        }
      }
    }

    console.log(this.paymentModeList);
    this.paymentType = this.paymentModeList[0].value;
  }

  close() {
    this.ngbActiveModal.close();
    localStorage.removeItem('isOpenShared');
    localStorage.removeItem('isDuplicate');
    localStorage.removeItem('isSingleUpdate');
    localStorage.removeItem('isLiveModifyStop');
    localStorage.removeItem('isModifyStop');

  }


  decideAllowWallet() {
    let allowed = this.businessDetails.getAcceptedPaymentType();

    if (allowed) {
      if (allowed.includes('BUSINESS_ACCOUNT')) {
        this.allowWallet = true
      }
    }
  }

  toggleCustomerInfoForm() {
    this.showCustomerInfoForm = !this.showCustomerInfoForm;
    if (this.showCustomerInfoForm) {
      this.showRideInfoForm = false;
      this.showPaymentInfoForm = false;
    }

  }

  clearCustomerDetails(){
    this.registerFrom.controls['firstName'].setValue("");
    this.registerFrom.controls['lastName'].setValue("");
    this.registerFrom.controls['email'].setValue("");
    this.registerFrom.controls['phoneNo'].setValue("");
    this.selectedPhonePattern = `[0-9][0-9]{${this.selectedCountryCode.limit - 1}}`
    this.registerFrom = this.formBuilder.group({
      phoneNo: [
        "",
        [
          Validators.required,
          Validators.minLength(this.selectedCountryCode.limit),
          Validators.maxLength(this.selectedCountryCode.limit)
        ],
      ],
      firstName: ["", Validators.required],
      lastName: [""],
      email: ["", [Validators.required, Validators.email]],
    });
  }

  togglePaymentInfoFormShared(){
    this.showPaymentInfoFormShared = !this.showPaymentInfoFormShared;
  }

  toggleRideInfoForm() {
    this.isSlotOpen(this.rideType)
    
    if (this.registerFrom.valid || this.countinueAsAdmin) {
      this.showRideInfoForm = !this.showRideInfoForm;
      if (this.showRideInfoForm) {
        this.showCustomerInfoForm = false;
        this.showPaymentInfoForm = false;
        !this.isRideRequestApiCalled ? this.fetchReasons() : null;
      }
    }

  }

  togglePaymentInfoForm() {
    if (this.submitForm) {
      this.showPaymentInfoForm = !this.showPaymentInfoForm;
      if (this.showPaymentInfoForm) {
        this.showCustomerInfoForm = false;
        this.showRideInfoForm = false;
      }
    }


  }

  fetchReasons(){
    this._rideService.fetchReasonsList().subscribe(res=>{
      if(res['statusCode'] >= 300){
        Swal.fire({
          icon:'error',
          title: 'Error!',
          text: 'Technical Error'
        })
      }
      else{
        if(res['response']['expenseCodeDetails']){
          this.expenseCodeDetails = {
            expenseCodeType : res['response']['expenseCodeDetails']['type'],
            isMandatory: res['response']['expenseCodeDetails']['mandatory'],
            isEnabled: res['response']['expenseCodeDetails']['enabled'],
            isOtherRequired: res['response']['expenseCodeDetails']['otherRequired'],
            expenseCodeList : res['response']['expenseCodeDetails']['itemList'].map((item)=>{return ({name: item , code: item})})
          }

          this.isTextExpenseCodeEnabled = this.expenseCodeDetails['expenseCodeType'] == 'TEXT' ? true : false;
          this.isDropDownExpenseCodeEnabled = this.expenseCodeDetails['expenseCodeType'] == 'LIST' ? true : false;
          this.expenseCodeDetails['isOtherRequired'] ? this.expenseCodeDetails['expenseCodeList'].push({name: 'others', code: 'other'}) : null;
        }
        

        this.businessRideReasonDetails = {
          businessRideReasonType:  res['response']['businessRideReasonDetails']['type'],
          isMandatory: res['response']['businessRideReasonDetails']['mandatory'],
          isEnabled: res['response']['businessRideReasonDetails']['enabled'],
          isOtherRequired: res['response']['businessRideReasonDetails']['otherRequired'],
          businessRideReasonList : res['response']['businessRideReasonDetails']['itemList'].map((item)=>{return ({name: item , code: item})})
        }

        this.isTextBusinessRideReasonEnabled = this.businessRideReasonDetails['businessRideReasonType'] == 'TEXT' ? true : false;
        this.isDropDownBusinessRideReasonEnabled = this.businessRideReasonDetails['businessRideReasonType'] == 'LIST' ? true : false;

       

        
        this.businessRideReasonDetails ['isOtherRequired'] ?  this.businessRideReasonDetails['businessRideReasonList'].push({name: 'others', code: 'other'}) : null;

      }
    })
  }

  //   showConfirmMessageDialog() {
  //     this.displayConfirmMessage = true;
  // }

  // hideConfirmMessageDialog() {
  //   this.displayConfirmMessage = false;
  // }


  handleDatePickerTime() {
    this.startTime = new Date();
    let ssoId = localStorage.getItem("ssoId");
    let bufferTime = this.calculateLeadTime();
    
    let min:number;


    if(ssoId != null && this.isAdminUser)
    {
      this.stepCount = 1;
      bufferTime =0;
      this.startTime.setMinutes(this.startTime.getMinutes()+bufferTime);
    }
    else{

      this.startTime.setMinutes(this.startTime.getMinutes()+bufferTime);
      min = this.startTime.getMinutes();

      if (min >= 0 && min < 15) {
        min = 15;
      }
      else if (min >= 15 && min < 30) {
        min = 30;
      }
      else if (min >= 30 && min < 45) {
        min = 45;
      }
      else {
        min = 0;
        this.startTime.setHours(this.startTime.getHours() + 1);
      }

      this.startTime.setMinutes(min);
      this.minDateForNormalRide = this.startTime;
    }
      this.startDateTime = new Date();
      this.startDateTime.setMinutes(this.startDateTime.getMinutes() + 60);

     min = this.startDateTime.getMinutes();
      if (min >= 0 && min < 15) {
        min = 15
      }
      else if (min >= 15 && min < 30) {
        min = 30;
      }
      else if (min >= 30 && min < 45) {
        min = 45;
      }
    else {
      min = 0;
      this.startDateTime.setHours(this.startDateTime.getHours() + 1);
    }
    this.startDateTime.setMinutes(min);
    this.minDateForSharedRide = this.startDateTime
    this.endDate = new Date();
    this.endDate.setDate(this.endDate.getDate() + 1);
    this.endDate.setHours(0,0,0,0);

  }
  getCategoryByPackageCode(code){
    let selectedCodeCategories =[]
    this.classicMap = null;
    this.premiumMap = null;
    for (const iterator of this.pkgDetails) {
      if(iterator['packageCode'] == code){
        selectedCodeCategories = iterator['categories'];
      }
    }
    for (const iterator of selectedCodeCategories) {
      if(iterator['categoryCode'] == 'CLASSIC'){
        this.classicMap = iterator ;
      }
      if(iterator['categoryCode'] == 'PREMIUM'){
        this.premiumMap = iterator ;
      }
      if(this.classicMap == null)

           this.selectedCategory ='PREMIUM'
        else
           this.selectedCategory ='CLASSIC'
      this.categoryChangeEvent(this.selectedCategory);
    }

  }
  categoryChangeEvent(category){
    
    this.selectedCategory = category
   
    if(this.selectedCategory == 'PREMIUM'){
      this.selectedPriceMapId = this.premiumMap['id']
   
    } else {
      this.selectedPriceMapId = this.classicMap['id']
    }
    this.isSlotOpen(this.rideType)
  }

  selectSlot(date){
  this.selectedSlot =null

   if(this.dateToSlotMap[date].length <= 0)
         Swal.fire({
           title: "Opps",
           icon: "info",
           text: "No slots available!",
        });
    this.slotList=this.dateToSlotMap[date]
  }

  intialiseSharedRide(){
    this.disableHidingStops = !localStorage.getItem('isDuplicate') ? true : false ;


    // this.ngbActiveModal.close();
    //   const modal = this.modal.open(CreateSharedRideComponent, {
    //     ariaLabelledBy: "modal-basic-title",

    //     size: "lg"
    //   });

    this.paymentModeList.forEach(mode => {
      if(mode.value == 'CASH')
        mode.disabled = true;

     if(mode.value == 'CREDIT_CARD')
        mode.disabled = true;   
    });

    

    if(localStorage.getItem('isOpenShared') == 'true'){
      if(this.rideData){
        if(localStorage.getItem('isLiveModifyStop') == 'true'){
          this.isLiveModifyStop = true;


         }else{
           this.isLiveModifyStop = false;
         }

         if(localStorage.getItem('isModifyStop') == 'true'){
          this.isModifyStop = true;
         }else{
           this.isModifyStop = false;
         }

        let category = this.rideData.category ? this.rideData.category : this.rideData.otherFlags.shareRideType;
        let stopsList = this.rideData.stopsList ? this.rideData.stopsList : this.rideData.rideDetails.stopsList;
        if(category == "ONE_DROP_MULTIPLE_PICKUPS" || category == "SHARED_PICKUPS"){
          let pickDropLocationDto = this.rideData.pickDropLocationDto ? this.rideData.pickDropLocationDto : this.rideData.rideDetails.dropLocation;
          this.selectedType = 'pickup';
          this.myForm.controls['myRadio'].setValue('pickup');
          this.dropLocation = pickDropLocationDto.locationName;
          this.drop.lat = pickDropLocationDto.lat;
          this.drop.lng = pickDropLocationDto.lng;
          if(stopsList[0]){
            if(stopsList[0].ridersList.length > 0){
              this.pickupLocation0 = stopsList[0].locationDetails.locationName;
              // this.pickupLocationDrop = stopsList[0].locationDetails.locationName;
              this.origin0.lat = stopsList[0].locationDetails.lat;
              this.origin0.lng = stopsList[0].locationDetails.lng;
              this.pickupNotes0 = stopsList[0].locationDetails.pickupNote;
              this.riderList0 = stopsList[0].ridersList;
            }
          }

          if(stopsList[1]){
            if(stopsList[1].ridersList.length > 0){
              this.pickupLocation1 = stopsList[1].locationDetails.locationName;
              this.origin1.lat = stopsList[1].locationDetails.lat;
              this.origin1.lng = stopsList[1].locationDetails.lng;
              this.pickupNotes1 = stopsList[1].locationDetails.pickupNote;
              this.riderList1 = stopsList[1].ridersList;
            }

          }

          if(stopsList[2]){
            if(stopsList[2].ridersList.length > 0){
              this.pickupLocation2 = stopsList[2].locationDetails.locationName;
              this.origin2.lat = stopsList[2].locationDetails.lat;
              this.origin2.lng = stopsList[2].locationDetails.lng;
              this.pickupNotes2 = stopsList[2].locationDetails.pickupNote;
              this.riderList2 = stopsList[2].ridersList;
            }

          }
          if(stopsList[3]){
            if(stopsList[3].ridersList.length > 0){
              this.pickupLocation4 = stopsList[3].locationDetails.locationName;
              this.origin3.lat = stopsList[3].locationDetails.lat;
              this.origin3.lng = stopsList[3].locationDetails.lng;
              this.pickupNotes3 = stopsList[3].locationDetails.pickupNote;
              this.riderList3 = stopsList[3].ridersList;
            }

          }

        }else if(category == "ONE_PICKUP_MULTIPLE_DROPS" || category == "SHARED_DROPS"){

          let pickDropLocationDto = this.rideData.pickDropLocationDto ? this.rideData.pickDropLocationDto : this.rideData.rideDetails.pickupLocation;
          this.selectedType = 'dropoff';
          this.myForm.controls['myRadio'].setValue('dropoff');
          this.pickupLocation0 = pickDropLocationDto.locationName;
          // this.pickupLocationDrop = stopsList[0].locationDetails.locationName;
          this.origin0.lat = pickDropLocationDto.lat;
          this.origin0.lng = pickDropLocationDto.lng;
          this.pickupNotes0 = pickDropLocationDto.pickupNote;



          if(this.isLiveModifyStop || this.isModifyStop){
            if(stopsList[0]){
              this.pickupLocation1 = stopsList[0].locationDetails.locationName;
              this.origin1.lat = stopsList[0].locationDetails.lat;
              this.origin1.lng = stopsList[0].locationDetails.lng;
              this.pickupNotes1 = stopsList[0].locationDetails.pickupNote;
              this.riderList0 = stopsList[0].ridersList;
            }

            if(stopsList[1]){
              this.pickupLocation2 = stopsList[1].locationDetails.locationName;
              this.origin2.lat = stopsList[1].locationDetails.lat;
              this.origin2.lng = stopsList[1].locationDetails.lng;
              this.pickupNotes2 = stopsList[1].locationDetails.pickupNote;
              this.riderList1 = stopsList[1].ridersList;
            }
            if(stopsList[3]){
              this.pickupLocation4 = stopsList[3].locationDetails.locationName;
              this.origin3.lat = stopsList[3].locationDetails.lat;
              this.origin3.lng = stopsList[3].locationDetails.lng;
              this.origin3 = stopsList[3].ridersList;
            }

            if(stopsList[2]){
              this.dropLocation = stopsList[2].locationDetails.locationName;
              this.drop.lat = stopsList[2].locationDetails.lat;
              this.drop.lng = stopsList[2].locationDetails.lng;
              this.riderList2 = stopsList[2].ridersList;
            }
          }else{
            if(stopsList[1]){
              this.pickupLocation1 = stopsList[1].locationDetails.locationName;
              this.origin1.lat = stopsList[1].locationDetails.lat;
              this.origin1.lng = stopsList[1].locationDetails.lng;
              this.pickupNotes1 = stopsList[1].locationDetails.pickupNote;
              this.riderList0 = stopsList[1].ridersList;
            }

            if(stopsList[2]){
              this.pickupLocation2 = stopsList[2].locationDetails.locationName;
              this.origin2.lat = stopsList[2].locationDetails.lat;
              this.origin2.lng = stopsList[2].locationDetails.lng;
              this.pickupNotes2 = stopsList[2].locationDetails.pickupNote;
              this.riderList1 = stopsList[2].ridersList;
            }
            if(stopsList[4]){
              
                this.dropLocation = stopsList[4].locationDetails.locationName;
                this.drop.lat = stopsList[4].locationDetails.lat;
                this.drop.lng = stopsList[4].locationDetails.lng;
                this.riderList2 = stopsList[4].ridersList;
              if(stopsList[3]){
                this.pickupLocation4 = stopsList[3].locationDetails.locationName;
                this.origin3.lat = stopsList[3].locationDetails.lat;
                this.origin3.lng = stopsList[3].locationDetails.lng;
                this.pickupNotes3 = stopsList[3].locationDetails.pickupNote;
                this.riderList3 = stopsList[3].ridersList;
              }
            }else{
              if(stopsList[3]){
                this.dropLocation = stopsList[3].locationDetails.locationName;
                this.drop.lat = stopsList[3].locationDetails.lat;
                this.drop.lng = stopsList[3].locationDetails.lng;
                this.riderList2 = stopsList[3].ridersList;
              }
            }
            
          }

        }

        if(this.selectedType == 'pickup'){
          if((this.riderList0.length + this.riderList2.length + this.riderList1.length + this.riderList3.length) > 1){
            this.isShowDrop = true;
          }else{
            this.isShowDrop = false;
          }
        }else if(this.selectedType == 'dropoff'){
          if((this.riderList0.length + this.riderList2.length + this.riderList1.length + this.riderList3.length) > 1){
            this.isShowDrop = true;
          }else{
            this.isShowDrop = false;
          }
        }


        if(localStorage.getItem('isSingleUpdate') == 'true'){
          this.isSingleRideUpdate = true;
        }else{
          this.isSingleRideUpdate = false;
        }

         if(!this.isSingleRideUpdate){
          this.paymentTypeShared = this.rideData.paymentMode;
          this.sharedRideReason = 'dummy';
          this.sharedRideEstimatedAmount = this.rideData.estimatedAmount;
          if(localStorage.getItem('isDuplicate') == 'false'){
            this.startDateTime = new Date(this.rideData.rideScheduleDetailsDto.startDate);
            this.endDate = new Date(this.rideData.rideScheduleDetailsDto.endDate);
            this.isShowSchedule = true;
            this.isTypeDisable = true;
          }else{
            this.isShowSchedule = true;
            this.startDateTime = new Date();
            this.endDate = new Date();
            this.handleDatePickerTime()
            this.formChanged();
            this.isTypeDisable = true;
          }



          if(this.startDateTime && this.endDate){
            if(this.startDateTime.getDate() == this.endDate.getDate()){
              this.isWeekDisabled = true;
            }else{
             this.isWeekDisabled = false;
            }
          }

         }

         if(!this.isSingleRideUpdate){

          if(this.rideData.rideScheduleDetailsDto.days){
            this.rideData.rideScheduleDetailsDto.days.forEach(day => {
              switch(day){
               case 'MONDAY':
                 this.selectedDay1 = true;
                 break
               case 'TUESDAY':
                 this.selectedDay2 = true;
                 break
               case 'WEDNESDAY':
                 this.selectedDay3 = true;
                 break
               case 'THURSDAY':
                 this.selectedDay4 = true;
                 break
               case 'FRIDAY':
                 this.selectedDay5 = true;
                 break
               case 'SATURDAY':
                 this.selectedDay6 = true;
                 break
               case 'SUNDAY':
                 this.selectedDay7 = true;
                 break
              }
            });
          }
         }
      }
    }
    this.togglePaymentInfoFormShared();
  }

  openSharedRideModal(){
      this.close();
      this._router.navigate(["/sharedride"])
  }

  addRider(type){


    let name,phone,email;

    if(type == 0){
      name = this.riderName0;
      phone = this.riderPhone0;
      email = this.riderEmail0;
    }else if(type == 1){
      name = this.riderName1;
      phone = this.riderPhone1;
      email = this.riderEmail1;
    }
    else if(type == 3){
      name = this.riderName3;
      phone = this.riderPhone3;
      email = this.riderEmail3;
    }
    else {
      name = this.riderName2;
      phone = this.riderPhone2;
      email = this.riderEmail2;
    }

    let rider = {
      firstName : name,
      phone : phone,
      email : email
    }
    let  isValidEmail;
    if(email){
       isValidEmail = email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    if(rider.firstName && rider.phone && rider.phone.length == 10 && isValidEmail){
      if(type == 0){
        this.riderList0.push(rider);
      }else if(type == 1){
        this.riderList1.push(rider);
      }else if(type == 3){
        this.riderList3.push(rider);
      }
      else{
        this.riderList2.push(rider);
      }

      if(this.selectedType == 'pickup' || this.myForm.value.myRadio == 'pickup'){
        if((this.riderList0.length + this.riderList2.length + this.riderList1.length +this.riderList3.length) > 1){
          this.isShowDrop = true;
        }else{
          this.isShowDrop = false;
        }
      }else if(this.selectedType == 'dropoff' || this.myForm.value.myRadio == 'dropoff'){
        if((this.riderList0.length + this.riderList2.length + this.riderList1.length +this.riderList3.length) > 1 && (this.riderList0.length +  this.riderList1.length +this.riderList3.length ) < this.sharedRideMaxAllowedRiders){
          this.isShowDrop = true;
        }else{
          if(!this.riderEmail3){
            this.isShowDrop = false;
          }
          if(this.riderList3.length > 0){
            this.isShowDrop = true;
          }

        }
      }
      this.clearFields(type);
      this.formChanged();
    }



  }

  removeRider(selectedRider,type){
    let index;
    switch (type){
      case 0:
        index = this.riderList0.findIndex(rider=>rider.phone == selectedRider.phone);
        this.riderList0.splice(index, 1);
        break;
      case 1:
        index = this.riderList1.findIndex(rider=>rider.phone == selectedRider.phone);
        this.riderList1.splice(index, 1);
        break;
      case 2:
        index = this.riderList2.findIndex(rider=>rider.phone == selectedRider.phone);
        this.riderList2.splice(index, 1);
        break;
      case 3:
          index = this.riderList3.findIndex(rider=>rider.phone == selectedRider.phone);
          this.riderList3.splice(index, 1);
          break;  
    }
    if((this.riderList0.length + this.riderList2.length + this.riderList1.length +this.riderList3.length) > 1){
      this.isShowDrop = true;
    }else{
      this.isShowDrop = false;
    }

    this.formChanged();

  }

  clearFields(type){
    if(type == 0){
      this.riderEmail0 = null;
      this.riderName0 = null;
      this.riderPhone0 = null;
    }else if(type == 1){
      this.riderEmail1 = null;
      this.riderName1 = null;
      this.riderPhone1 = null;
    }
    else if(type == 3){
      this.riderEmail3 = null;
      this.riderName3 = null;
      this.riderPhone3 = null;
    }
    else{
      this.riderEmail2 = null;
      this.riderName2 = null;
      this.riderPhone2 = null;
    }

  }

  createSharedRide(){
    if(this.apiCalled){
      return;
    }

    if(this.showLandmark == true && this.selectedLandmark == null && this.myForm.value.myRadio == 'dropoff'){
      this.loader.hide();
      Swal.fire({
        title: "Error",
        icon: "info",
        text: "Please select landmark",
      });
      return;
    }

    this.apiCalled =true;

    let businessCode = localStorage.getItem('businessAccountId');

    let sharedRideData = {
      businessCode : businessCode,
      platform: "WEB",
      paymentMode: "BUSINESS_ACCOUNT",
      locationDto: {
        lat: this.myForm.value.myRadio == "pickup" ? this.drop.lat : (this.showLandmark ? this.selectedLandmark.lat : this.origin0.lat),
        lng: this.myForm.value.myRadio == "pickup" ? this.drop.lng : (this.showLandmark ? this.selectedLandmark.lng : this.origin0.lng),
        locationName: this.myForm.value.myRadio == "pickup" ? this.dropLocation : (this.showLandmark ? this.selectedLandmark.name : this.pickupLocation0),
        pickupNote: this.myForm.value.myRadio == 'pickup' ? '' : this.pickupNotes0

      },

      
      rideTypeCategory: this.myForm.value.myRadio == 'pickup' ? "ONE_DROP_MULTIPLE_PICKUPS" : "ONE_PICKUP_MULTIPLE_DROPS",
      shareRideScheduleDetailsDto: {
          startDate: this.startDateTime ? new Date(this.startDateTime).getTime() : null,
          scheduleAt:  this.startDateTime ? this.startDateTime.getHours() + ":" + this.startDateTime.getMinutes() : null,
          days: this.getDays(),
          endDate: this.endDate ?  new Date(this.endDate).getTime(): null,
      },

      stopsList: {
        0:{
          locationDetails: {
            lat: this.myForm.value.myRadio == 'pickup' ? this.origin0.lat : this.origin1.lat,
            lng: this.myForm.value.myRadio == 'pickup' ? this.origin0.lng : this.origin1.lng,
            locationName: this.myForm.value.myRadio == 'pickup' ? this.pickupLocation0 : this.pickupLocation1,
            pickupNote: this.myForm.value.myRadio == 'pickup' ? this.pickupNotes0 : this.pickupNotes1
          },
          ridersList:this.riderList0
        },
        1:{
          locationDetails: {
            lat: this.myForm.value.myRadio == 'pickup' ? this.origin1.lat : this.origin2.lat,
            lng: this.myForm.value.myRadio == 'pickup' ? this.origin1.lng : this.origin2.lng,
            locationName: this.myForm.value.myRadio == 'pickup' ? this.pickupLocation1 : this.pickupLocation2,
            pickupNote: this.myForm.value.myRadio == 'pickup' ? this.pickupNotes1 : this.pickupNotes2
          },
          ridersList:this.riderList1
        },
        2:{
          locationDetails: {
            lat: this.myForm.value.myRadio == 'pickup' ? this.origin2.lat : this.origin3.lat,
            lng: this.myForm.value.myRadio == 'pickup' ? this.origin2.lng : this.origin3.lng,
            locationName: this.myForm.value.myRadio == 'pickup' ? this.pickupLocation2 : this.pickupLocation4,
            pickupNote: this.myForm.value.myRadio == 'pickup' ? this.pickupNotes2 : this.pickupNotes3
          },
          ridersList: this.myForm.value.myRadio == 'pickup' ? this.riderList2 : this.riderList3
        },
        3:{
          locationDetails: {
            lat: this.myForm.value.myRadio == 'pickup' ? this.origin3.lat : this.drop.lat ,
            lng: this.myForm.value.myRadio == 'pickup' ? this.origin3.lng :this.drop.lng,
            locationName: this.myForm.value.myRadio == 'pickup' ?  this.pickupLocation4:this.dropLocation,
            pickupNote: this.myForm.value.myRadio == 'pickup' ? this.pickupNotes3 :'' 
          },
          ridersList: this.myForm.value.myRadio == 'pickup' ? this.riderList3: this.riderList2
        },
              },
      businessReason: this.sharedRideReason

    }
   

    if(!sharedRideData.stopsList[1].locationDetails.lat && !sharedRideData.stopsList[1].locationDetails.lng){
      delete sharedRideData.stopsList[1]
    }

    if(!sharedRideData.stopsList[2].locationDetails.lat && !sharedRideData.stopsList[2].locationDetails.lng){
      delete sharedRideData.stopsList[2]
    }
    if(!sharedRideData.stopsList[3].locationDetails.lat && !sharedRideData.stopsList[3].locationDetails.lng){
      delete sharedRideData.stopsList[3]
    }

    this.loader.show();


    if(localStorage.getItem('isDuplicate') == 'false'){
      // Edit case
      this.sharedRideApiData = sharedRideData;
      this._rideService.getBulkSharedRideUpdateEstimate(this.rideData.sharedRideId, sharedRideData)
      .subscribe((res:any) => {
        this.apiCalled= false;
        this.loader.hide();
        if (res.statusCode >= 300) {
          this.apiCalled = false;
           let title = "Error"
            res.message = res.message;
          Swal.fire({
            imageUrl:"../../../../../assets/warningIcon.png",
            title: title,
            text: res.message
          });
        }
        if(res['response'] && res['response'].distanceLimitExceeded){
          this.isSharedRideDistanceLimitExceeded = true;
          window.alert('Distance exceeds more than 120KM');
        }else{
          this.isSharedRideDistanceLimitExceeded = false;
          this.sharedRideRequestID = res['response'].sharedRideId;
          this.sharedRideEstimatedAmount = res['response'].estimatedTotalAmount;
          this.showPaymentInfoFormShared = true;

        }
      })

    }else if(this.isSingleRideUpdate){

      let singleRideData = {
        businessCode: businessCode,
        locationDto: sharedRideData.locationDto,
        stopsList: sharedRideData.stopsList,
        rideReqId: this.rideData.rideRequestId
      }
      this.sharedSingleRideApiData = singleRideData;
      this._rideService.getSingleSharedRideUpdateEstimate(this.rideData.rideRequestId, singleRideData)
      .subscribe((res:any) => {
        this.apiCalled=false;
        this.loader.hide();
        if (res.statusCode >= 300) {
          this.apiCalled = false;
          let title = "Error"
            res.message = res.message;
          Swal.fire({
            imageUrl:"../../../../../assets/warningIcon.png",
            title: title,
            text: res.message
          });
        }
        if(res['response'] && res['response'].distanceLimitExceeded){
          this.isSharedRideDistanceLimitExceeded = true;
          window.alert('Distance exceeds more than 120KM');
        }else{
          this.isSharedRideDistanceLimitExceeded = false;
          this.sharedRideRequestID = res['response'].sharedRideId;
          this.sharedRideEstimatedAmount = res['response'].estimatedTotalAmount;
          this.showPaymentInfoFormShared = true;

        }
      })

    }else{
      // Duplicate or New ride case
      this._rideService.createSharedLiveRide(sharedRideData)
      .subscribe((res:any) => {
        // {"sharedRideId":2,"estimatedTotalAmount":229.0,"distanceLimitExceeded":false},  //res
        this.apiCalled =false;
        this.loader.hide();
        if (res.statusCode >= 300) {
          this.apiCalled = false;
          let title = "Error"
            res.message = res.message;
          Swal.fire({
            imageUrl:"../../../../../assets/warningIcon.png",
            title: title,
            text: res.message
          });
        }

        if(res['response'] && res['response'].distanceLimitExceeded){
          this.isSharedRideDistanceLimitExceeded = true;
          window.alert('Distance exceeds more than 120KM');
        }else{
          this.isSharedRideDistanceLimitExceeded = false;
          this.sharedRideRequestID = res['response'].sharedRideId;
          this.sharedRideEstimatedAmount = res['response'].estimatedTotalAmount;
          this.showPaymentInfoFormShared = true;
        }
      })
    }
  }

  checkSharedRideForm(){
    if(this.dropLoc && this.startDateTime && this.endDate && this.selectedDay1 && this.paymentTypeShared && this.sharedRideReason){
      return false;
    }
    return true;
  }

  getDays(){
    let selectedDays = [];
    if(this.selectedDay1){
      selectedDays.push('MONDAY')
    }
    if(this.selectedDay2){
      selectedDays.push('TUESDAY')
    }
    if(this.selectedDay3){
      selectedDays.push('WEDNESDAY')
    }
    if(this.selectedDay4){
      selectedDays.push('THURSDAY')
    }
    if(this.selectedDay5){
      selectedDays.push('FRIDAY')
    }
    if(this.selectedDay6){
      selectedDays.push('SATURDAY')
    }
    if(this.selectedDay7){
      selectedDays.push('SUNDAY')
    }
    return selectedDays;
  }

  bookSharedRide(){
    if(this.apiCalled){
      return;
    }
    this.apiCalled = true;
    this.loader.show();
    if(this.rideData && localStorage.getItem('isDuplicate') == 'true'){
      // duplicate create new ride case
      this._rideService.confirmSharedRideRequest(this.sharedRideRequestID)
      .subscribe((res:any) => {
        this.apiCalled = false;
        this.loader.hide();
        if (res.statusCode >= 300) {
          let title = "Error"
          if (res.message.includes('BALANCE_INSUFFICIENT')) {
            res.message = 'Insufficient balance, please contact your admin.'
          }else if(res.message.includes('SPENDING_LIMIT_INSUFFICIENT')){
             title="<span style=\"color:#F7685B;padding: 13px 43px;\">Monthly spending limit used up</span>"
             res.message = 'The rider’s monthly spending limit has been reached. Please increase the limit for this rider to book more rides during this month.'
            }
          Swal.fire({
            imageUrl:"../../../../../assets/warningIcon.png",
            title: title,
            text: res.message
          });

        } else {
          this.close();
          Swal.fire({
            title: '',
            imageUrl: "../../../../../assets/confirmMessageIcon.png",
            text: "Ride Booked Successfully"
          });

          if (this.paymentType == "BUSINESS_ACCOUNT") {
            let balance = this.businessDetails.getWalletBalamce();
            if (balance) {
              let amountToDeduct = this.sharedRideEstimatedAmount ? this.sharedRideEstimatedAmount : 0;
              balance = parseInt(balance) - amountToDeduct;
              this.businessDetails.setWalletBalance(balance);
            }
          }

          this.close();

        }

      }, err => {
        this.loader.hide();
        this.apiCalled= false;
      })
    }else if(this.rideData && localStorage.getItem('isDuplicate') == 'false'){
      // Edit ride case

      this._rideService.confirmBulkSharedRideUpdateRide(this.rideData.sharedRideId)
      .subscribe((res:any) => {
        this.apiCalled =false;
        this.loader.hide();
        if (res.statusCode >= 300) {
          let title = "Error"
          if (res.message.includes('BALANCE_INSUFFICIENT')) {
            res.message = 'Insufficient balance, please contact your admin.'
          }else if(res.message.includes('SPENDING_LIMIT_INSUFFICIENT')){
             title="<span style=\"color:#F7685B;padding: 13px 43px;\">Monthly spending limit used up</span>"
             res.message = 'The rider’s monthly spending limit has been reached. Please increase the limit for this rider to book more rides during this month.'
            }
          Swal.fire({
            imageUrl:"../../../../../assets/warningIcon.png",
            title: title,
            text: res.message
          });

        } else {
          this.close();
          Swal.fire({
            title: '',
            imageUrl: "../../../../../assets/confirmMessageIcon.png",
            text: "Ride Booked Successfully"
          });

          if (this.paymentType == "BUSINESS_ACCOUNT") {
            let balance = this.businessDetails.getWalletBalamce();
            if (balance) {
              let amountToDeduct = this.sharedRideEstimatedAmount ? this.sharedRideEstimatedAmount : 0;
              balance = parseInt(balance) - amountToDeduct;
              this.businessDetails.setWalletBalance(balance);
            }
          }

          this.close();
          this._router.navigate(['rides/schedulerides']);

        }

      }, err => {
        this.loader.hide();
        this.apiCalled= false;
      })

    }else if(this.isSingleRideUpdate){
      this._rideService.confirmSingleSharedRideUpdateRide(this.rideData.rideRequestId)
      .subscribe((res:any) => {
        this.apiCalled =false;
        this.loader.hide();
        if (res.statusCode >= 300) {
          let title = "Error"
          if (res.message.includes('BALANCE_INSUFFICIENT')) {
            res.message = 'Insufficient balance, please contact your admin.'
          }else if(res.message.includes('SPENDING_LIMIT_INSUFFICIENT')){
             title="<span style=\"color:#F7685B;padding: 13px 43px;\">Monthly spending limit used up</span>"
             res.message = 'The rider’s monthly spending limit has been reached. Please increase the limit for this rider to book more rides during this month.'
            }
          Swal.fire({
            imageUrl:"../../../../../assets/warningIcon.png",
            title: title,
            text: res.message
          });

        } else {
          this.close();
          Swal.fire({
            title: '',
            imageUrl: "../../../../../assets/confirmMessageIcon.png",
            text: "Ride Booked Successfully"
          });

          if (this.paymentType == "BUSINESS_ACCOUNT") {
            let balance = this.businessDetails.getWalletBalamce();
            if (balance) {
              let amountToDeduct = this.sharedRideEstimatedAmount ? this.sharedRideEstimatedAmount : 0;
              balance = parseInt(balance) - amountToDeduct;
              this.businessDetails.setWalletBalance(balance);
            }
          }

          this.close();
          this._router.navigate(['rides/schedulerides']);
        }

      }, err => {
        this.loader.hide();
        this.apiCalled= false;
      })
    }else{
      // create new ride case
            this._rideService.confirmSharedRideRequest(this.sharedRideRequestID)
      .subscribe((res:any) => {
        this.apiCalled = false;
        this.loader.hide();
        if (res.statusCode >= 300) {
          let title = "Error"
          if (res.message.includes('BALANCE_INSUFFICIENT')) {
            res.message = 'Insufficient balance, please contact your admin.'
          }else if(res.message.includes('SPENDING_LIMIT_INSUFFICIENT')){
             title="<span style=\"color:#F7685B;padding: 13px 43px;\">Monthly spending limit used up</span>"
             res.message = 'The rider’s monthly spending limit has been reached. Please increase the limit for this rider to book more rides during this month.'
            }
          Swal.fire({
            imageUrl:"../../../../../assets/warningIcon.png",
            title: title,
            text: res.message
          });

        } else {
          this.close();
          Swal.fire({
            title: '',
            imageUrl: "../../../../../assets/confirmMessageIcon.png",
            text: "Ride Booked Successfully"
          });

          if (this.paymentType == "BUSINESS_ACCOUNT") {
            let balance = this.businessDetails.getWalletBalamce();
            if (balance) {
              let amountToDeduct = this.sharedRideEstimatedAmount ? this.sharedRideEstimatedAmount : 0;
              balance = parseInt(balance) - amountToDeduct;
              this.businessDetails.setWalletBalance(balance);
            }
          }

          this.close();
          this.apiCalled= false;
          this._router.navigate(['rides/schedulerides']);
        }

      }, err => {
        this.loader.hide();
      })
    }

  }

  ngOnDestroy() {
    localStorage.removeItem('isOpenShared');
    localStorage.removeItem('isDuplicate');
    localStorage.removeItem('isSingleUpdate');
    localStorage.removeItem('isLiveModifyStop');
    localStorage.removeItem('isModifyStop');
    this.isTypeDisable = false;
    this.clearAllIntervals();

 }


 formChanged(){
    

   this.sharedRideEstimatedAmount = null;
   this.showPaymentInfoFormShared =false;
   if(this.startDateTime && this.endDate && this.startDateTime.getDate() == this.endDate.getDate()){
     this.isWeekDisabled = true;
   }else{
    this.isWeekDisabled = false;
   }
   if(this.isModifyStop){
    this.isNextDisable =  false;
  }

  switch(this.myForm.value.myRadio){
    case 'pickup':
      if((this.riderList0.length + this.riderList2.length + this.riderList1.length +this.riderList3.length) > 1 && this.paymentTypeShared && this.dropLocation){
        this.isNextDisable =  false;
      }else{
        if(!this.isLiveModifyStop){
          this.isNextDisable = true;
        }

      };
      if((this.riderList0.length + this.riderList2.length + this.riderList1.length +this.riderList3.length) > 1 && this.dropLocation){
        this.isShowSchedule = true;
      }else{
        this.isShowSchedule = false;
      }
      break;

    case 'dropoff':
      if((this.riderList0.length + this.riderList2.length + this.riderList1.length +this.riderList3.length) > 1 && this.paymentTypeShared){
        this.isNextDisable =  false;
      }else{

        if(!this.isLiveModifyStop){
          this.isNextDisable = true;
        }
      }
      if((this.riderList0.length + this.riderList2.length + this.riderList1.length +this.riderList3.length) > 1){
        this.isShowSchedule = true;
      }else{
        this.isShowSchedule = false;
      }
      break;
  }



 }
 closeSharedRide(){
  this.isShowSharedRidePopup = false;
 }

  validateEmail(email,type) {
          // tslint:disable-next-line:max-line-length
          const isValidEmail = email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

         if(!isValidEmail){
           switch(type){
             case 0:
               this.isEmailInvalid0 = true;
               break;
            case 1:
              this.isEmailInvalid1 = true;
              break;
            case 2:
              this.isEmailInvalid2 = true;
              break;
            case 3:
                this.isEmailInvalid3 = true;
                break;  
           }
         }else{
          switch(type){
            case 0:
              this.isEmailInvalid0 = false;
              break;
           case 1:
             this.isEmailInvalid1 = false;
             break;
           case 2:
             this.isEmailInvalid2 = false;
             break;
            case 3:
              this.isEmailInvalid3 = false;
              break; 
          }
         }

      }

      blockStop(stop){



        if(this.rideData && this.rideData.rideDetails){
          switch(stop){
            case 0:
             return  this.rideData.rideDetails.stopsList[0] && this.rideData.rideDetails.stopsList[0].locationDetails.state == 'REACHED' ? true : false;
            case 1:
             return  this.rideData.rideDetails.stopsList[1] && this.rideData.rideDetails.stopsList[1].locationDetails.state == 'REACHED' ? true : false;
            case 2:
             return  this.rideData.rideDetails.stopsList[2] && this.rideData.rideDetails.stopsList[2].locationDetails.state == 'REACHED' ? true : false;
          }
        }

      }
      autoFillWeekdays(){
        let selectedDate = new Date(this.endDate);
        selectedDate.setHours(0,0,0,0);
        this.endDate = selectedDate;
      }

      getCountryCode(){
        this.users.getCountryCodes().subscribe(res=>{
          this.countryDetails = res['response']['countryCodePairList'];
           for(let i=0; i<this.countryDetails.length; i++){
            this.countryCodes[i]=this.countryDetails[i]['countryNameInitial'] == '' ? {label: 'Mostly Searched', items:[]} : {label: this.countryDetails[i]['countryNameInitial'], items:[]}
            for(let j=0; j<this.countryDetails[i]['countries'].length; j++){
              this.countryCodes[i]['items'][j]={label:this.countryDetails[i]['countries'][j]['name'], value: {code: this.countryDetails[i]['countries'][j]['callingCode'], flag:this.countryDetails[i]['countries'][j]['countryFlag'], limit:this.countryDetails[i]['countries'][j]['phoneNumberLimit'] }};
            }
           }         
       this.selectedCountryCode = this.appService.getCountry() == 'AE' ? {code: "+971", flag: "🇦🇪", limit: 9} : {code: "+91", flag: "🇮🇳", limit: 10};
       this.selectedPhonePattern = `[0-9][0-9]{${this.selectedCountryCode.limit - 1}}`
       this.registerFrom = this.formBuilder.group({
        phoneNo: [
          "",
          [
            Validators.required,
            Validators.minLength(this.selectedCountryCode.limit),
            Validators.maxLength(this.selectedCountryCode.limit)
          ],
        ],
        firstName: ["", Validators.required],
        lastName: [""],
        email: ["", [Validators.required, Validators.email]],
      });
        })
      }

      customFilterForReason(event: any){
        const filteredOptions = this.reasonsList.filter((option) => {
          return option.label.toLowerCase().includes(event.query.toLowerCase());
        });
        return filteredOptions;
      }


      isSlotOpen(rideType){
        this.slotOpenFlag = rideType == 'SCHEDULED_RIDE' ? this.countryBasedFeatureList['slots'][rideType] : this.countryBasedFeatureList['slots'][rideType][this.selectedCategory]

        if(this.slotOpenFlag){
          
          this._rideService.getSlots( rideType == 'SCHEDULED_RIDE' ? 0 : this.selectedPriceMapId, this.getZoneId(),rideType == 'SCHEDULED_RIDE').subscribe(res=>{
            this.dateList=[]
            if(res['status']=='SUCCESS'){
              res['response']['dateSlots'].forEach( date => {
                 this.dateList.push({ label: this.datePipe.transform(date.date, "dd MMM yyyy"), value : date.date})
                 this.dateToSlotMap[date.date]=[]
                 date['timeGroupSlotsResponseList'].forEach(timeGroup =>{
                   for(let slot in timeGroup['slots']){
                        if(this.appService.getCountry() =='AE'){
                          if(this.businessDetails.getBaseLocationDetails().businessSpecs == 'HOTEL' || this.businessDetails.getBaseLocationDetails().businessSpecs != null){
                            if( timeGroup['slots'][slot]['slotStatus'] == 'AVAILABLE' && (Math.abs(Date.now() - timeGroup['slots'][slot]['slotTime']) > 900000 ) )
                              this.dateToSlotMap[date.date].push({label:slot,value: timeGroup['slots'][slot]['slotTime']})
                          }else{
                            if( timeGroup['slots'][slot]['slotStatus'] == 'AVAILABLE' && (Math.abs(Date.now() - timeGroup['slots'][slot]['slotTime']) > this.leadTime*600000 ) )
                              this.dateToSlotMap[date.date].push({label:slot,value: timeGroup['slots'][slot]['slotTime']})
                            }
                           }
                           else{
                          if( timeGroup['slots'][slot]['slotStatus'] == 'AVAILABLE'  && (Math.abs(Date.now() - timeGroup['slots'][slot]['slotTime']) > this.leadTime*600000))
                            this.dateToSlotMap[date.date].push({label:slot,value: timeGroup['slots'][slot]['slotTime']})
                           }
                        }
                      
                 })
              })
              this.slotList=this.dateToSlotMap[this.dateList[0].value]
            }
          })
        }
      }


      getZoneId(){
        return this.appService.getCountry() == 'IN' ? this.pickupLocationZoneId : localStorage.getItem('zoneIds')
      }

      getStartTime(){
        return this.slotOpenFlag ? (this.isAdminUser ? new Date(this.startTime).getTime(): this.selectedSlot  ) : new Date(this.startTime).getTime()
      }
      checkBusinessRideReason(){
        if(this.selectedBusinessRideReason.code == 'other'){
          this.isTextBusinessRideReasonEnabled = true;
        }
        else{
          this.isTextBusinessRideReasonEnabled = false;
        }
      }

      checkExpenseCode(){
        if(this.selectedExpenseCode.code == 'other'){
          this.isTextExpenseCodeEnabled = true;
        }
        else{
          this.isTextExpenseCodeEnabled = false;
        }
      }

      startAsAdmin(){
        if(this.countinueAsAdmin){
          this.registerFrom.controls['phoneNo'].setValue(localStorage.getItem('phoneNo'));
          this.registerFrom.controls['phoneNo'].disable();
          this.registerFrom.controls['firstName'].setValue(localStorage.getItem('name'));
          this.registerFrom.controls['firstName'].disable();
          this.registerFrom.controls['lastName'].disable();
          this.registerFrom.controls['email'].setValue(localStorage.getItem('email'));
          this.registerFrom.controls['email'].disable();
        }
        
        else{
          this.registerFrom.controls['phoneNo'].setValue("");
          this.registerFrom.controls['phoneNo'].enable();
          this.registerFrom.controls['firstName'].setValue("");
          this.registerFrom.controls['firstName'].enable();
          this.registerFrom.controls['lastName'].enable();
          this.registerFrom.controls['email'].setValue("");
          this.registerFrom.controls['email'].enable();
      }
    }

      getPickupBaseLocation(){
        let baseLocationDto = this.businessDetails.getBaseLocationDetails()['baseLocation'] != null ? JSON.parse(this.businessDetails.getBaseLocationDetails()['baseLocation'])[0] : null;
        if(baseLocationDto != null){
          this.origin.lat = baseLocationDto.lat,
          this.origin.lng = baseLocationDto.lng,
          this.pickupLocation = baseLocationDto.place
        }
        else{
          return;
        }
      }

}
