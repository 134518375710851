<div class="container_expand" *ngIf='appService.isLoad'>
  <app-header (margin)="marginChangeHandler($event)" *ngIf='appService.isValid'></app-header>
  <img src="assets/help-icon.svg" alt="" class="help-icon-container" (click)="openHelpDialog()" *ngIf='appService.isValid'>
  <div class="right-content" [ngClass]="{marginLess: moreMargin,marginMore: !moreMargin, zeroMargin: !appService.isValid}" >
    <div class="delay-wrapper" *ngIf="showDelayAlert && !hideAlert">
      <div class="delay-content">
        {{delayMsg}}
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>

<p-sidebar [(visible)]="isHelpDialogVisible" position="right">
  <img src="assets/close_icon.svg" alt="" (click)="closeHelpDialog()">
  <feedback-container [feedbackCategory]="'SYMPHONY_FEEDBACK'" [rideDetails]="rideDetailsForHelp" *ngIf="isHelpDialogVisible"></feedback-container>
  </p-sidebar>