<div class="main-container">
    <div class="header-container" (click)="toggleAccordian()">
        <div class="head-title-container">
        <img src="assets/complete-badge.svg" alt="" *ngIf="accordianDetails?.isComplete">
        <span class="acc-title" [ngClass]="{'title-bold': accordianDetails?.isActive, 'title-regular': !accordianDetails?.isActive}">{{accordianDetails?.label}}</span> 

        <div #headerTemplate class="custom-html-container" *ngIf="accordianDetails.isHTMLEnabled"></div>
        </div>

        <span class="acc-arrow">
            <img src="assets/arrow.svg" alt="">
        </span>
    </div>
    
    <div class="body-container" *ngIf="accordianDetails?.isActive">
        <div class="acc-title">
            <ng-content select="[accBody]"></ng-content>
        </div>
    </div>
</div>