import { Injectable } from '@angular/core';
import { CountryFeatureListService } from 'src/app/app-v2/localization/country-feature-list/country-feature-list.service';
import { RideType } from 'src/app/app-v2/types/container.type';

@Injectable({
  providedIn: 'root'
})
export class SelectRideTypeContainerService {

  
  selectedRideTypeDetails: RideType;
  selectedRideTypeFeatures;

  constructor(private _countryFeature: CountryFeatureListService) { }

  setSelectedRideTypeDetails(rideTypeDetails){
    this.selectedRideTypeDetails = Object.assign({},rideTypeDetails);   
    this.setSelectedRideFeatures(this._countryFeature.getCountryFeatureList()['rideTypeFeatures'][this.selectedRideTypeDetails.code]);
  }

  getSelectedRideTypeDetails(){
    return this.selectedRideTypeDetails;
  }


  setSelectedRideFeatures(features){
    this.selectedRideTypeFeatures = Object.assign(features);
  }

  getSelectedRideFeatures(){
    return this.selectedRideTypeFeatures;
  }

  setIsAirportRide(isAirport){
    this.selectedRideTypeDetails.isAirportRide = isAirport;
  }

  getIsAirportRide(){
    return this.selectedRideTypeDetails.isAirportRide;
  }

  setIsExpressRide(isExpress){
    this.selectedRideTypeDetails.isExpressRide = isExpress;
  }

  getIsExpressRide(){
    return this.selectedRideTypeDetails.isExpressRide;
  }

  setIsSlotRequired(slotRequired){
    this.selectedRideTypeDetails.slotsRequired = slotRequired;
  }

  getIsSlotRequired(){
    return this.selectedRideTypeDetails.slotsRequired;
  }

  setIsPackageRequired(packageRequired){
    return this.selectedRideTypeDetails.packageRequired = packageRequired;
  }

  getIsPackageRequired(){
    return this.selectedRideTypeDetails.packageRequired;
  }

  getIsDropRequired(){
    return this.selectedRideTypeDetails.isDropRequired;
  }

  getIsTncRequired(){
    return this.selectedRideTypeDetails.tncRequired;
  }

  setIsMultistopRide(isMultiStop){
    this.selectedRideTypeDetails.isMultiStopRide = isMultiStop;
  }

  getIsMultiStopRide(){
    return this.selectedRideTypeDetails.isMultiStopRide;
  }
}
