import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { JourneyDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/journey-details-container.service';
import { SelectRideTypeContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/select-ride-type-container.service';
import { CreateRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-ride-subscription.service';
import { LocationInputDetails } from 'src/app/app-v2/types/container.type';


@Component({
  selector: 'add-location-container',
  templateUrl: './add-location-container.component.html',
  styleUrls: ['./add-location-container.component.scss']
})
export class AddLocationContainerComponent implements OnInit,OnDestroy {
  addLocationValidationSubscription: Subscription;

  locationInputs: LocationInputDetails = {
    PICK: {
      placeholder: 'Enter Location..',
      type: 'text',
      inputValue: {
        lat: 0,
        lng: 0,
        place: null
      },
      label: 'Pickup Location',
      name: 'PICKUP',
      zoneId: 0,
      validation: {
        isError: false,
        isSuccess: false,
        message: ''
      },
      isDisabled: false,
      options: { componentRestrictions: { country: this._common.getSelectedCountry() } },
      isAirportEnabled: true,
      isAirportLocation: false,
      isExpressRideEnabled: true,
      isExpressRide: false,
      landmarks: [],
      isLandmarkEnabled: true,
      isLandmarkSelected: [],
      showLandmark: false,
      required: true,
      enableTooltip: false,
      tooltipContent: ''
    },

    DROP:
    {
      placeholder: 'Enter Location..',
      type: 'text',
      inputValue: {
        lat: 0,
        lng: 0,
        place: null
      },
      label: 'Drop Location',
      name: 'DROP',
      validation: {
        isError: false,
        isSuccess: false,
        message: ''
      },
      isDisabled: false,
      options: { componentRestrictions: { country: this._common.getSelectedCountry() } },
      isRequired: true,
      isAirportEnabled: true,
      isAirportLocation: false,
      isExpressRideEnabled: false,
      isExpressRide: false,
      landmarks: [],
      zoneId: 0,
      isLandmarkEnabled: true,
      isLandmarkSelected: [],
      showLandmark: false,
      required: true,
      enableTooltip: false,
      tooltipContent: ''
    },

    STOPS: []
  }

  maxAddStopLimit = 2;
  isAddStop = true;


  constructor(private _rideType: SelectRideTypeContainerService,
    private _common: CommonService,
    private _subscription: CreateRideSubscriptionService,
    private _journey: JourneyDetailsContainerService,)
    { }
  

  ngOnInit() {
    this.locationInputs.DROP.isRequired = this._rideType.getSelectedRideTypeDetails().isDropRequired;
    this.locationInputs.DROP.validation = !this._rideType.getSelectedRideTypeDetails().isDropRequired ? null : {
      isError: false,
      isSuccess: false,
      message: ''
    }

    this.checkRideTypeFeatures(this._rideType.getSelectedRideFeatures());

    this.fetchPrevData();
    this.startAddLocationValidationSubscription();
  }


  checkRideTypeFeatures(features){
   this.locationInputs.PICK.isAirportEnabled = features['isPremiumAirportEnabled'];
   this.locationInputs.PICK.isExpressRideEnabled = features['isExpressRideEnabled'];

   this.locationInputs.DROP.isAirportEnabled = features['isPremiumAirportEnabled'];
   this.locationInputs.DROP.isExpressRideEnabled = features['isExpressRideEnabled'];
  }


  addStop(lastIndex) {
    if(this._rideType.getSelectedRideTypeDetails().code.split("_").includes('RENTALS')){
      this.locationInputs.STOPS.push({
        placeholder: 'Enter Location..',
        type: 'text',
        inputValue: {
          lat: 0,
          lng: 0,
          place: null,
          position: lastIndex + 1
        },
        label: `Stop ${lastIndex + 1}`,
        name: 'STOP',
        zoneId: 0,
        validation: {
          isSuccess: false,
          isError: false,
          message: ''
        },
        isDisabled: false,
        options: { componentRestrictions: { country: this._common.getSelectedCountry() } },
        isAirportEnabled: this._rideType.getSelectedRideFeatures['isPremiumAirportEnabled'],
        isAirportLocation: false,
        isExpressRideEnabled: this._rideType.getSelectedRideFeatures['isExpressRideEnabled'],
        isExpressRide: false,
        landmarks: [],
        isLandmarkEnabled: true,
        isLandmarkSelected: [],
        showLandmark: false,
        required: false,
        enableTooltip: false,
        tooltipContent: ''
      })

      this._rideType.setIsMultistopRide(false);
    }

    else{
        this.locationInputs.STOPS.push({
          placeholder: 'Enter Location..',
          type: 'text',
          inputValue: {
            lat: 0,
            lng: 0,
            place: null,
            position: lastIndex + 1
          },
          label: `Stop ${lastIndex + 1}`,
          name: 'STOP',
          zoneId: 0,
          validation: {
            isSuccess: false,
            isError: false,
            message: ''
          },
          isDisabled: false,
          options: { componentRestrictions: { country: this._common.getSelectedCountry() } },
          isAirportEnabled: true,
          isAirportLocation: false,
          landmarks: [],
          isLandmarkEnabled: true,
          isLandmarkSelected: [],
          showLandmark: false,
          required: false,
          enableTooltip: false,
          tooltipContent: ''
        })

        this.isAddStop = this.locationInputs.STOPS.length  == this.maxAddStopLimit ? false : true;
        this._rideType.setIsMultistopRide(this.locationInputs.STOPS.length > 0 ? true : false);
    }

    
  }

  async deleteStop(index) {
    if (this.locationInputs.STOPS.length > 0) {
      this.locationInputs.STOPS.splice(index, 1);
      this._journey.setStops(this.locationInputs.STOPS);
      this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.STOPS, type: 'STOPS' });
      this.renderStops();
    }

    if(this._rideType.getSelectedRideTypeDetails().code != 'SCHEDULED_RENTALS'){
      this.isAddStop = this.locationInputs.STOPS.length < this.maxAddStopLimit ? true : false
    }

    let stopsVal = await this.locationInputs.STOPS.map(stop => stop.inputValue);
    this._journey.setStops(stopsVal)
  }


  renderStops() {
    this.locationInputs.STOPS.forEach((stop, index) => {
      stop.label = `Stop ${index + 1}`;
    })
  }



  async getLocationDataFromInput(event, type) {
    
    switch (type) {
      case 'PICKUP':
        this._journey.setPickupLocation(this.locationInputs.PICK.inputValue);
        this.setExtraData('PICK');
        this.checkIfAirportLocation();
        this.checkIfExpressRide();
        this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.PICK.inputValue, type: type});
        return

      case 'DROP':
        this._journey.setDropLocation(this.locationInputs.DROP.inputValue);
        this.setExtraData('DROP');
        this.checkIfAirportLocation();
        this.checkIfExpressRide();
        this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.DROP.inputValue, type: type });
        
        return

      case 'STOPS':
        let stopsVal = await this.locationInputs.STOPS.map(stop => stop.inputValue);
        this.setExtraData('STOP');
        this._journey.setStops(stopsVal);
        this.checkIfAirportLocation();
        this.checkIfExpressRide();
        this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.STOPS, type: type });
        return
    }

  }


  setExtraData(type){
    

    switch(type){
      case 'PICK':
        let extraDataPick = {
          isAirportLocation: this.locationInputs[type].isAirportEnabled ? this.locationInputs[type].isAirportLocation : false,
          landmarks: this.locationInputs[type].landmarks,
          zoneId: this.locationInputs[type].zoneId,
          isExpressRide: this.locationInputs[type].isExpressRideEnabled ? this.locationInputs[type].isExpressRide : false,
          selectedLandmarkIndex: this.fetchSelectedLandmarkIndex(this.locationInputs[type].landmarks, type)
        }
        this._journey.setPickupLocationExtraFields(extraDataPick);
        return;

      case 'DROP':
        let extraDataDrop = {
          isAirportLocation: this.locationInputs[type].isAirportEnabled ? this.locationInputs[type].isAirportLocation : false,
          landmarks: this.locationInputs[type].landmarks,
          zoneId: this.locationInputs[type].zoneId,
          isExpressRide: this.locationInputs[type].isExpressRideEnabled ? this.locationInputs[type].isExpressRide : false,
          selectedLandmarkIndex: this.fetchSelectedLandmarkIndex(this.locationInputs[type].landmarks, type)
        }
        this._journey.setDropLocationExtraFields(extraDataDrop);
        return;

      case 'STOP':
        let stopsExtraData = this.locationInputs.STOPS.map(stop=>{
          return {
            isAirportLocation : stop.isAirportEnabled ? stop.isAirportLocation : false,
            landmarks : stop.landmarks,
            isExpressRide: stop.isExpressRideEnabled ? stop.isExpressRide : false,
            selectedLandmarkIndex : this.fetchSelectedLandmarkIndex(stop.landmarks, 'STOP'),
            zoneId: stop.zoneId
          }
        });
        this._journey.setStopsExtraFields(stopsExtraData);
        return;
    }
    
  }

  checkIfAirportLocation() {
    let isAirportLocationDto = {
      PICKUP: this.locationInputs.PICK.isAirportLocation,
      DROP: this.locationInputs.DROP.isAirportLocation,
      STOPS: this.locationInputs.STOPS.map(stop => stop.isAirportLocation)
    }

    this._subscription.emitIsAirportRideObserver(isAirportLocationDto);
  }


  checkIfExpressRide(){
    let isExpressRide = this.locationInputs.PICK.isExpressRide;
    this._subscription.emitIsExpressRideObserver(isExpressRide);
  }


  fetchSelectedLandmarkIndex(landmarks, type) {
    let selectedLandmarkStatus = landmarks.map(landmark => this.locationInputs[type].inputValue.place === landmark.place ? true : false);
    let index = selectedLandmarkStatus.includes(true) ? selectedLandmarkStatus.indexOf(true) : null;
    return index;
  }


  fetchPrevData() {

    this.locationInputs.PICK.inputValue = this._journey.getPickupLocation();
    this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.PICK.inputValue, type: 'PICKUP' });

    this.locationInputs.DROP.inputValue = this._journey.getDropLocation();
    this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.DROP.inputValue, type: 'DROP' });

    this._journey.getStops().forEach((stop, index) => {
      this.addStop(index);
      this.locationInputs.STOPS[index].inputValue = stop;
    })
    this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.STOPS, type: 'STOPS' });
    this.fetchExtraFields();
  }

  fetchExtraFields() {
    let pickupExtraData = this._journey.getPickupLocationExtraFields();
    let dropExtraData = this._journey.getDropLocationExtraFields();
    let stopsExtraData = this._journey.getStopsLocationExtraFields();



    this.locationInputs.PICK.isAirportLocation = pickupExtraData.isAirportLocation;
    this.locationInputs.PICK.isExpressRide = pickupExtraData.isExpressRide;
    this.locationInputs.PICK.landmarks = pickupExtraData.landmarks;
    this.locationInputs.PICK.landmarks.forEach(landmark => this.locationInputs.PICK.isLandmarkSelected.push(false));
    this.locationInputs.PICK.showLandmark = pickupExtraData.landmarks.length > 0 ? true : false;
    pickupExtraData.selectedLandmarkIndex != null ? this.locationInputs.PICK.isLandmarkSelected[pickupExtraData.selectedLandmarkIndex] = true : null;


    this.locationInputs.DROP.isAirportLocation = dropExtraData.isAirportLocation;
    this.locationInputs.DROP.isExpressRide = dropExtraData.isExpressRide;
    this.locationInputs.DROP.landmarks = dropExtraData.landmarks;
    this.locationInputs.DROP.landmarks.forEach(landmark => this.locationInputs.DROP.isLandmarkSelected.push(false));
    this.locationInputs.DROP.showLandmark = dropExtraData.landmarks.length > 0 ? true : false;
    pickupExtraData.selectedLandmarkIndex != null ? this.locationInputs.DROP.isLandmarkSelected[dropExtraData.selectedLandmarkIndex] = true : null;


    stopsExtraData.forEach((stop,index)=>{
      this.locationInputs.STOPS[index].isAirportLocation = stop.isAirportLocation;
      this.locationInputs.STOPS[index].isExpressRide = stop.isExpressRide;
      this.locationInputs.STOPS[index].landmarks = stop.landmarks;
      this.locationInputs.STOPS[index].landmarks.forEach(landmark=>this.locationInputs.STOPS[index].isLandmarkSelected.push(false));
      this.locationInputs.STOPS[index].showLandmark = stop.landmarks.length > 0 ? true : false;
      stop.selectedLandmarkIndex != null ? this.locationInputs.STOPS[index].isLandmarkSelected[stop.selectedLandmarkIndex] = true : null;
    });

    this.checkIfAirportLocation();
    this.checkIfExpressRide();
  }

  startAddLocationValidationSubscription() {
    this.addLocationValidationSubscription = this._subscription.fetchAddLocationValidationObserver().subscribe(async res => {
      let promises = await Object.keys(this.locationInputs).map(async inpType=>{

        if(inpType == 'STOPS'){
          if(this.locationInputs.STOPS.length > 0){
            this.locationInputs.STOPS.forEach(async (stop, index)=>{
              let res = await this._journey.checkValidation(inpType, index);
                  stop.validation = res;
            });

            let validation = this.locationInputs.STOPS.map(stop=>stop.validation.isError);
            return validation.includes(true) ? true : false; 
          }
          else{
            return false;
          }
          }
        else{
            if(this.locationInputs[inpType]['validation'] != null){
              let res = await this._journey.checkValidation(inpType);
              this.locationInputs[inpType]['validation'] = res;
              return this.locationInputs[inpType]['validation']['isError'];
            }
            else{
              return false;
            }}
        });
      
      await Promise.all(promises);

      let validatorStatus = Object.keys(this.locationInputs).map(inpType=>{
        if(inpType == 'STOPS'){
          if(this.locationInputs.STOPS.length > 0){
            let validation = this.locationInputs.STOPS.map(stop=>stop.validation.isError);
            return validation.includes(true) ? true : false; 
          }
          else{
            return false;
          }
        }
        else{
          if(!this.locationInputs[inpType]['validation']){
            return false;   
          }
          else{
            return this.locationInputs[inpType]['validation']['isError'];
          }}
      });

      this._journey.setAddLocationValidation(!validatorStatus.includes(true) ? true : false);
      this._subscription.emitAddLocationValidationStatusObserver(!validatorStatus.includes(true) ? true : false);
    });
  }



ngOnDestroy(): void {
  this.addLocationValidationSubscription.unsubscribe();
}
}

