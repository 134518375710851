import { Component, Input, OnInit } from '@angular/core';
import { CustomButton, FileUploader, RatingDetails, TextAreaDetails } from '../../types/components.type';
import { FeedbackDetailsApiType } from '../../types/container.type';
import { FeedbackApiServiceService } from '../../api-services/feedback/feedback-api-service.service';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonTypes, Theme } from '../../types/enums';

@Component({
  selector: 'feedback-container',
  templateUrl: './feedback-container.component.html',
  styleUrls: ['./feedback-container.component.scss']
})
export class FeedbackContainerComponent implements OnInit {

  @Input() feedbackCategory:string;
  @Input() rideDetails: {rideId: number | null, rideRequestId: number | null};

  isFileUploaded:boolean = false;

  feedbackDto : FeedbackDetailsApiType;

  ratingDetails :RatingDetails = {
    readOnly: false,
    stars: 5,
    showCancel: false,
    value: 0
  }

  feedbackTextAreaDetails:TextAreaDetails = {
    placeholder: 'Write a Review',
    inputValue: '',
    label: 'Write a Review',
    name: '',
    rows:'6',
    validation: null,
    isDisabled: false,
    enableTooltip: false,
    required: false,
    tooltipContent: ''
  }

  submitCta:CustomButton = {
    label: 'Submit Feedback',
    left: false,
    right: false,
    leftUrl: '',
    rightUrl: '',
    disabled: true,
    type: ButtonTypes.FILLED
  }


  attachedFileDetails: FileUploader ={
    label: 'Attach PNG, PDF, JPEG (if any)',
    value: null,
    acceptFormat: '.png, .jpg, .jpeg, .pdf'
  }


  constructor(private _feedback: FeedbackApiServiceService, 
              private _commonSubscription: CommonSubscriptionService,
              private _loader: NgxSpinnerService){}
  ngOnInit(): void {
    this.clearData();
  }


  checkRating(event){
    if(this.ratingDetails.value == 0 || this.ratingDetails.value == null){
      this.submitCta.disabled = true;
    }
     else{
      this.submitCta.disabled = false;
     }
  }

  submitFeedback(){
    this.feedbackDto = {
      rideRequestId: this.rideDetails.rideRequestId,
      rideId: this.rideDetails.rideId,
      rating: this.ratingDetails.value,
      requestType : this.feedbackCategory,
      comments: this.feedbackTextAreaDetails.inputValue
    }

    let formData = new FormData();
    formData.append('file', this.attachedFileDetails.value);
    formData.append('supportTicketJson', JSON.stringify(this.feedbackDto));
    
    this._loader.show();
    this._feedback.saveFeedback(formData).subscribe(res=>{
      this._loader.hide();
      if(res['statusCode'] == 200){
        this.isFileUploaded = true;
      }

      else{
        this._commonSubscription.emitAlert({
          theme: Theme.ERROR,
          title: 'Technical Error',
          message: res['message'],
          ctaLabel: 'Ok'
        });

        this.isFileUploaded = false;
      }
    })
    
  }

  clearData(){
    this.ratingDetails.value = 0;
    this.feedbackTextAreaDetails.inputValue = '';
    this.submitCta.disabled = true;
    this.attachedFileDetails.value = null;
  }
}
