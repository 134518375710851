<div class="main-container">
    <div class="feedback-container" *ngIf="!isFileUploaded">
        <div class="feedback-caption-container">
            <img src="assets/star-rating.svg" alt="">
            <h3 class="caption-title"> Review and Rating </h3>
            <span class="caption-text">Share your feedback about your rides or your overall dashboard experience.</span>
        </div>

        <div class="rating-container">
            <rating [ratingDetails] = "ratingDetails" (onRatingChange)="checkRating($event)"></rating>      
        </div>
        
        
        
        <div class="feedback-input-container">
            <custom-text-area [textAreaDetails]="feedbackTextAreaDetails"></custom-text-area>
        </div>
        
        <div class="file-upload-container">
            <file-uploader [fileUploaderDetails]="attachedFileDetails"></file-uploader>
        </div>
        

        <div class="submit-cta-container">
            <custom-button [buttonDetails]="submitCta" (onCustomButtonClick)="submitFeedback()"></custom-button>
        </div>
    </div>


    <div class="file-uploaded-container" *ngIf="isFileUploaded">
        <img src="assets/complete-badge.svg" alt="" class="uploaded-icon">
        <span class="uploaded-title">Review Added !</span>
        <span class="uploaded-text">Thanks for sharing your feedback with us</span>
    </div>
</div>

<pop-up></pop-up>