<div class="main-container" >

    <div class="phone-container">
        <custom-input [inputType]="'PHONE'" [inputBoxDetails]="customerDetails.phoneNumber" (onSendInputData)="getInfoFromInputThroughApi($event)"></custom-input>

        <div class="admin-container" *ngIf="isEligibleForAdminUse">
            <div class="admin-checkbox-container" (click)="continueAsAdmin()">
                <custom-checkbox [checkboxDetails]="continueAsAdminCheckBox"></custom-checkbox>
            </div>
            <span class="admin-caption">Continue as Admin</span>
        </div>
    </div>
    
    <div class="rest-details-container">
        <div class="name-container">
            <div class="first-name">
                <custom-input [inputType]="'BASIC'" [inputBoxDetails]="customerDetails.firstName"></custom-input>
            </div>

            <div class="last-name">
                <custom-input [inputType]="'BASIC'" [inputBoxDetails]="customerDetails.lastName"></custom-input>
            </div>
        </div>

        <div class="email-container">
            <custom-input [inputType]="'BASIC'" [inputBoxDetails]="customerDetails.email"></custom-input>
        </div>
    </div>

    

    <div class="submit-container">
        <custom-button [buttonDetails]="nextCta" (onCustomButtonClick)="submitCustomerDetails()"></custom-button>
    </div>
    
</div>