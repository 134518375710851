import { AppService } from './../services/app.service';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, pipe } from 'rxjs';

import { COOKIE_URL,COOKIE_SUFFIX } from '../config/apiRouteConfig';
import { BusinessDetailsService } from '../services/business-details/business-details.service';
import { SelectRideTypeContainerService } from '../app-v2/getter-setter/container/create-ride/select-ride-type-container.service';
import { CountryFeatureListService } from '../app-v2/localization/country-feature-list/country-feature-list.service';
import { PaymentDetailsContainerService } from '../app-v2/getter-setter/container/create-ride/payment-details-container.service';
import { CommonService } from '../app-v2/getter-setter/common.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateRouteGuard implements CanActivate {
  constructor(
    private cookieService: CookieService, 
    private router: Router, 
    private appService: AppService, 
    private businessDetails:BusinessDetailsService,
    private _selectRideType: SelectRideTypeContainerService,
    private _countryFeature: CountryFeatureListService,
    private _paymentDetails: PaymentDetailsContainerService,
    private _common: CommonService) { }

  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const context = this;
    let rolesURL = {  "MENU_LIVE_RIDES" : "/rides/liverides",
                      "MENU_LIVE_MAP":  "/livemap",
                      "MENU_SCHEDULE_RIDES" :  "/rides/schedulerides",
                      "MENU_PAST_RIDES": "/rides/pastrides",
                      "MENU_ROSTER_UPLOAD":"/rides/rosterupload",
                      // "MENU_DRIVERS_ON_DUTY": "/driver-on-duty",
                      "MENU_VEHICLE": "/vehicle",
                      "MENU_DRIVER": "/driver",
                      "MENU_RIDER" : "/riders" ,
                      "MENU_SETTING": "/setting",
                      // "MENU_REPORTS": "/reports",
                      "MENU_PAYMENTS":"/payments",
                      "MENU_CUSTOMER_SUPPORT": "/customer-support",
                      "MENU_JOURNEY": "/journey-details",
                      "MENU_OVERSPEEDING": "/overspeeding",
                      "ADMIN_SETTING":"/admin-setting" }
    let userMenuRoles={}

    return new Promise(function canActivate(resolve, reject) {
      context.appService.checkToken().subscribe(async (responseServer: any) => {
        if (responseServer.statusCode >= 300) {
          context.appService.isValid = false;
          //console.log("Can activate")
          // context.cookieService.delete('accessToken'+COOKIE_SUFFIX, '/', COOKIE_URL);
          localStorage.removeItem('accessToken')
          context.router.navigateByUrl('/login');
          return reject(false);
        }
        else {
          let ssoId = responseServer.response.userDetails.ssoId
          let lastRole
          localStorage.setItem('ssoId', responseServer.response.userDetails.ssoId)
          context._common.setIsAdminUser(ssoId);
          if(responseServer.response){
            localStorage.setItem('createRideV2', responseServer.response.createRideV2Enabled)
          }
          if(responseServer.response.balanceDetails){
            localStorage.setItem('walletBalance',responseServer.response.balanceDetails.currentBalance)
            context.businessDetails.setWalletBalance(responseServer.response.balanceDetails.currentBalance);
          }     
          if(responseServer.response.accountInfo.businessAccountDataObj && responseServer.response.accountInfo.businessAccountDataObj.featureDetailsDataDto){
            localStorage.setItem('isShowShared',responseServer.response.accountInfo.businessAccountDataObj.featureDetailsDataDto.sharedRideEnabled)
            localStorage.setItem('isShowSharedNew',responseServer.response.accountInfo.businessAccountDataObj.featureDetailsDataDto.sharedRideNewFeatureTag)
          }
          if(responseServer?.response?.accountInfo?.businessAccountDataObj && responseServer?.response?.accountInfo?.businessAccountDataObj?.featureDetailsDataDto?.sharedRideMaxAllowedRiders){
            context.businessDetails.setSharedRideMaxLimit(responseServer.response.accountInfo.businessAccountDataObj.featureDetailsDataDto.sharedRideMaxAllowedRiders);
          }
          if(responseServer?.response?.accountInfo?.businessAccountDataObj && responseServer?.response?.accountInfo?.businessAccountDataObj?.featureDetailsDataDto?.sharedRideMinAllowedRiders){
            context.businessDetails.setSharedRideMinLimit(responseServer.response.accountInfo.businessAccountDataObj.featureDetailsDataDto.sharedRideMinAllowedRiders);
          }
          localStorage.setItem('email',responseServer.response.userDetails.email)
          localStorage.setItem('phoneNo',responseServer.response.userDetails.phone)
          localStorage.setItem('headerImg',responseServer.response.accountInfo.businessPictureUrl)
          localStorage.setItem('businessAccountCode', responseServer.response.accountInfo.code)
          localStorage.setItem('name',responseServer.response.userDetails.firstName)
          context.businessDetails.setAcceptedPaymentType(responseServer.response.accountInfo.allowedPaymentMode)
          context.businessDetails.setAllowedRideType(responseServer.response.accountInfo.allowedRideType)
          context.businessDetails.setPricingModel(responseServer.response.accountInfo.pricingModel)
          context.businessDetails.setBusinessName(responseServer.response.accountInfo.businessDisplayName);
          context.businessDetails.setHeaderImgUrl(responseServer.response.accountInfo.businessPictureUrl);
          if(responseServer.response.accountInfo){
            context.businessDetails.setAccountInfo(responseServer.response.accountInfo);

        let isSharedRideEnabled = responseServer.response.accountInfo.businessAccountDataObj?.featureDetailsDataDto?.sharedRideEnabled;
        context._countryFeature.setCountryFeatureList(context.appService.getCountry());
        context._common.setCallingCodeList();
        context._common.setAllowedRideTypes(responseServer.response.accountInfo.allowedRideType, isSharedRideEnabled);
        context._paymentDetails.setAllowedPaymentModes(responseServer.response.accountInfo.allowedPaymentMode);
        context._common.setSelectedCountry(context.appService.getCountry());
        context._common.setBusinessSpec(responseServer.response.accountInfo.businessSpecs)

          if(responseServer.response.accountInfo.businessAccountDataObj != null){
              context.businessDetails.setIsBusinessRideReasonMandatory(responseServer.response.accountInfo.businessAccountDataObj.isMandatory);
              context.businessDetails.setRideReasons(responseServer.response.accountInfo.businessAccountDataObj.reasons);
              if(responseServer.response.accountInfo.businessAccountDataObj['featureDetailsDataDto'] != null && responseServer.response.accountInfo.businessAccountDataObj.featureDetailsDataDto.hasOwnProperty('isSubPlacesEnabled'))
                context.businessDetails.setIsSubPlaceEnabledFlag(responseServer.response.accountInfo.businessAccountDataObj.featureDetailsDataDto.isSubPlacesEnabled);
                if(responseServer.response.accountInfo.businessAccountDataObj['featureDetailsDataDto'] != null){
                  context.businessDetails.setIsManagerApprovalEnabledFlag(responseServer.response.accountInfo.businessAccountDataObj['featureDetailsDataDto']['isManagerApprovalRequired'])
                  context.businessDetails.setIsRecurringRentalEnabledFlag(responseServer.response.accountInfo.businessAccountDataObj.featureDetailsDataDto.isRecurringRentalsEnabled);
                  responseServer.response.accountInfo.businessAccountDataObj.featureDetailsDataDto.rosterUploadEnabled ? localStorage.setItem('showRosterUpload',responseServer.response.accountInfo.businessAccountDataObj.featureDetailsDataDto.rosterUploadEnabled) : localStorage.setItem('showRosterUpload', 'false'); 
                }
                else{
                  context.businessDetails.setIsManagerApprovalEnabledFlag(false)
                }
          }
        }

          await context.appService.getRolesForUserSync(ssoId).then((res:any)=>{
            localStorage.setItem("userRoles", JSON.stringify(res.response))
            for (const role of res.response) {
              if(JSON.stringify(role).indexOf("MENU") !== -1)
                { userMenuRoles[rolesURL[role]]=role
                  lastRole=rolesURL[role]
                }
            }
            if(next['_routerState'].url == '/admin-setting' && !res.response.includes('ADMIN_SETTING')){
              context.router.navigateByUrl(lastRole);
              return reject(false); 
             }

             if(next['_routerState'].url == '/create' && context._selectRideType.getSelectedRideTypeDetails() == undefined){
              context.router.navigate(['/select']);
             }
            // if(!userMenuRoles[next['_routerState'].url])
            //  { //context.router.navigateByUrl(lastRole);
            //    return reject(false); 
            //  }
           }) 
          
        }

        context.appService.isValid =true;
        return resolve(true);
       
      }, (error) => { });
    });
  }

}
