<div class="banner-container">
    <div class="express-ride-banner" *ngIf="isExpressRide">
        <img src="assets/express-ride-icon.svg" alt="" class="express-ride-icon">
        <span class="express-ride-banner-text">You are booking an Express Ride</span>
    </div>
</div>


<div class="main-container">    
    <div class="left-section">
        
        <!-- Location Container -->
            <card type="container">
                <div class="location-container" container>
                    
                    <div class="title-container">
                        <span class="title">Location</span>
                        <div class="edit-container" (click)="editData('locationDetails')">
                            <img src="assets/edit.svg" alt="">
                            <span class="edit-text">Edit Location</span>
                        </div>
                    </div>

                    <div class="data-container">
                        <div class="pickup-location-container" *ngIf="rideRequestDetails?.rideDetails?.pickupLocation?.locationName">
                            <div class="icon-container">
                                <img src="assets/pick.svg" alt="">
                            </div>
                            <span class="text-info">{{rideRequestDetails?.rideDetails?.pickupLocation?.locationName}}</span>
                        </div>

                        <div class="stop-location-container" *ngFor="let stop of rideRequestDetails.stopsList; index as i">
                            <div *ngIf="stop.type == 'STOP'" class="stop-container">
                                <div class="icon-container">
                                    <span>{{i}}</span>
                                </div>
                                
                                <span class="text-info">{{stop.location}}</span>
                            </div>
                        </div>

                        <div class="drop-location-container" *ngIf="rideRequestDetails?.rideDetails?.dropLocation?.locationName">
                            <div class="icon-container">
                                <img src="assets/drop.svg" alt="">
                            </div>
                            
                            <span class="text-info">{{rideRequestDetails?.rideDetails?.dropLocation?.locationName}}</span>
                        </div>
                    </div>
                </div>
            </card>
    

            <!-- Additional Details Container -->
            <card type="container" *ngIf="isPickupNotesContainerRequired">
                <div class="additional-details-container" container>
                    <div class="title-container">
                        <span class="title">Pick up Notes & Business Ride Reason</span>
                        <div class="edit-container" (click)="editData('locationDetails')">
                            <img src="assets/edit.svg" alt="">
                            <span class="edit-text">Edit Notes</span>
                        </div>
                    </div>

                    <div class="data-container">
                        <div class="pickup-notes-container" *ngIf="rideRequestDetails?.pickupNotes?.notes">
                            <div class="icon-container">
                                <img src="assets/pickupNotes.svg" alt="">
                            </div>
                            <span class="text-info">{{rideRequestDetails?.pickupNotes?.notes}}</span>
                        </div>


                        <div class="business-ride-reason-container" *ngIf="rideRequestDetails?.rideDetails?.otherFlags?.businessRideReason">
                            <div class="icon-container">
                                <img src="assets/brrAndExpense.svg" alt="">
                            </div>
                            <span class="text-info">{{rideRequestDetails?.rideDetails?.otherFlags?.businessRideReason}}</span>
                        </div>


                        <div class="expense-code-container" *ngIf="rideRequestDetails?.rideDetails?.otherFlags?.expenseCode">
                            <div class="icon-container">
                                <img src="assets/expense_code.svg" alt="">
                            </div>
                            <span class="text-info">{{rideRequestDetails?.rideDetails?.otherFlags?.expenseCode}}</span>
                        </div>

                    </div>
                </div>        
            </card>
        

         <!-- Date & Time Container    -->
            <card type="container">
                <div class="timetamp-container" container>
                    <div class="title-container">
                        <span class="title">Date & Time</span>
                        
                        <div class="edit-container" (click)="editData('timestampDetails')">
                            <img src="assets/edit.svg" alt="">
                            <span class="edit-text">Edit Time</span>
                        </div>
                    </div>

                    <div class="data-container">
                        <div class="time-container">
                        <div class="icon-container">
                            <img src="assets/clock.svg" alt="">
                        </div>
                        <span class="text-info">{{rideRequestDetails.rideDetails.scheduleTimestamp | datetimepipe}}</span>
                    </div>
                </div>
                </div>                
            </card>


        <!-- Car Variant Container -->
            <card type="container" *ngIf="isCarVariantContainerRequired">
                <div class="car-variant-container" container >

                    <div class="title-container">
                        <span class="title">Car Variant</span>
                        
                        <div class="edit-container" (click)="editData('carVariantDetails')">
                            <img src="assets/edit.svg" alt="">
                            <span class="edit-text">Edit Package</span>
                        </div>
                    </div>

                    <div  *ngFor="let variant of carVariantDetails; index as i">
                        <div [ngClass]="{'variant-selected': variant.category == selectedCarVariantCategory, 'variant-unselected' : variant.category != selectedCarVariantCategory }" class="variants">
                            <div class="variant-details-container">
                            <div class="variant-details">
                            <img [src]="variant.carUrl" alt="">
                            <div class="variant-category-container">
                                <span class="variant-category">{{variant.category}}</span>
                                <span class="variant-label">{{variant.carLabel}}</span>
                            </div>
                            </div>
                            <custom-checkbox [checkboxDetails]="{disabled : true, checked : true}" *ngIf="variant.category == selectedCarVariantCategory"></custom-checkbox>
                        </div>
                            <div class="extra-info-container" [ngClass]="{'classic-variant': variant['category'] == 'CLASSIC', 'premium-variant': variant['category'] == 'PREMIUM'}" *ngIf="variant['extraInfo'].length > 0">
                                <img src="" alt="">
                                <span>{{variant.extraInfo[i]}}</span>
                            </div>
                        </div>


                           
                    </div>
                </div>        
            </card>
    </div>


    <div class="right-section">
    
    <!-- Payment Container -->
        <card type="container">
            <div class="payment-summary-container" container>
            <div class="title-container">
                <span class="title">Payment Summary</span>
            </div>

              <div class="payment-details-container">
                <div class="detail-container">
                    <span class="details">Distance</span>
                    <span class="details">{{(rideRequestDetails.rideDetails.distance / 1000).toFixed(2)}} Km</span>
                </div>

                <div class="detail-container">
                    <span class="details">Fare</span>
                    <span class="details">{{rideRequestDetails.pricingDetails.estimatedFareAmountExclTax | currencypipe}}</span>
                </div>

                <div class="detail-container">
                    <span class="details">Airport Charges & Tolls</span>
                    <span class="details">{{rideRequestDetails?.pricingDetails?.estimatedAdditionalAmountExclTax | currencypipe}}</span>
                </div>

                <div class="detail-container">
                    <span class="details">Tax</span>
                    <span class="details">{{rideRequestDetails.pricingDetails.estimatedTaxAmount | currencypipe}}</span>
                </div>

              </div>  

              <div class="total-fare-container">
                <span class="total-fare-details">Total Fare</span>
                <span class="total-fare-details">{{rideRequestDetails.pricingDetails.estimatedTotalAmount | currencypipe}}</span>
              </div>        
        </div>
        </card>
        

        <!-- Payment Modes Container -->
        <div class="payment-method-container">
            <div class="title-container">
                <span class="title">Payment Method</span>
            </div>
            

            <div class="payment-container">
            <div class="payment-methods" *ngFor="let paymentMode of selectedPaymentModes; index as i" (click)="selectPaymentMode(i)">
                <card type="container" [isActive]="paymentMode.isActive">
                    <div class="mode-container" container>
                        <img [src]="paymentMode['iconUrl']" alt="">
                        <div class="mode-detail-container">
                            <span class="payment-title">{{paymentMode.title}}</span>
                            <span class="payment-desc">{{paymentMode.desc}}</span>
                        </div>
                        
                    </div>
                </card>
            </div>
        </div>
        </div>


    <!-- TnC Container -->
        <div class="tnc-main-container" *ngIf="isTncRequired">
                <div class="checkbox-container">
                    <custom-checkbox [checkboxDetails]="tncCheckboxDetails" (toggleCheck)="checkActivePaymentMode()"></custom-checkbox>
                </div>
                
                <div class="tnc-container">
                    <span>
                        I have read the following information. Your rental package will automatically start after 10 minutes from your boarding time.
                    </span>
    
                    <span><strong>Note:</strong></span>
    
                    <ol>
                        <li>In exceptional/ rare cases of the cab being late, the same will be applicable post 15 minutes of the driver-partner's arrival.</li>
                        <li>A No-show fee will be charged in case you don't show up for the ride.</li>
                    </ol>
    
                    <span>
                        For further details, <span (click)="moreTncDetails()" class="detail-container"><strong>click here</strong></span>
                    </span>
                </div>
        </div>


    <!-- Book ride Cta Container -->
        <div class="confirm-cta-container">
            <custom-button [buttonDetails]="paymentModeCta" (onCustomButtonClick)="submitPaymentMode()"></custom-button>
        </div>
    </div>
</div> 