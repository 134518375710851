import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_SAVE_TICKET } from 'src/app/config/apiRouteConfig';

@Injectable({
  providedIn: 'root'
})
export class FeedbackApiServiceService {

  constructor(private _http:HttpClient) { }

  saveFeedback(feedback: FormData){
    return this._http.post(API_URL_SAVE_TICKET, feedback);
  }
}
