import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { CreateRideService } from '../../getter-setter/container/create-ride/create-ride-container.service';
import {  JourneyDetails, RideType } from '../../types/container.type';
import { SelectRideTypeContainerService } from '../../getter-setter/container/create-ride/select-ride-type-container.service';
import { Subscription } from 'rxjs';
import { JourneyDetailsContainerService } from '../../getter-setter/container/create-ride/journey-details-container.service';
import { CreateRideSubscriptionService } from '../../subscriptions/create-ride/create-ride-subscription.service';
import { CustomerDetailsContainerService } from '../../getter-setter/container/create-ride/customer-details-container.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CountryFeatureListService } from '../../localization/country-feature-list/country-feature-list.service';
import { JourneyDetailsStep } from '../../types/enums';

@Component({
  selector: 'journey-details-container',
  templateUrl: './journey-details-container.component.html',
  styleUrls: ['./journey-details-container.component.scss']
})
export class JourneyDetailsContainerComponent implements OnInit, OnDestroy{
    triggerLocationSubscription: Subscription;
    triggerCarVariantSubscription: Subscription;
    isAirportRideSubscription: Subscription;
    isExpressRideSubscription: Subscription;
    activeAccSubscription: Subscription;
    
    
    journeyDetails: JourneyDetails;
    selectedRideType: RideType;
    expressRideBanner: string | null = null;
    
  constructor(private _rideType: SelectRideTypeContainerService,
              private _subscription: CreateRideSubscriptionService,
              private _createRide: CreateRideService,
              private _countryFeature: CountryFeatureListService,
              private _journey: JourneyDetailsContainerService,
              private _loader: NgxSpinnerService){
  }
  
  ngOnInit(): void {
    this._createRide.setPaymentMode(this._countryFeature.getCountryFeatureList()['defaultActivePaymentMode'].code);
      this.setAccordianData().then((res)=>{
      this.startAirportRideSubscription();
      this.startExpressRideSubscription();
      this.startLocationCtaSubscription();
      this.startCarVariantCtaSubscription();
      this.selectedRideType = this._rideType.getSelectedRideTypeDetails();
      this.setActiveAccordian(this._createRide.getSelectedJourneyDetailsStep());
      this.startActiveAccSubscription()

      this.fetchPrevData();
    })
    
  }

  startActiveAccSubscription(){
    this.activeAccSubscription = this._subscription.fetchJourneyDetailsActiveAcc().subscribe(res=>{
      this.setActiveAccordian(this._createRide.getSelectedJourneyDetailsStep());
    })
  }

  setAccordianData(){
    let x  = new Promise((resolve, reject)=>{
      this.journeyDetails= {
        locationDetails : this._journey.getLocationAccDetails(),
        carVariantDetails : this._journey.getCarvariantAccDetails(),
        timestampDetails : this._journey.getTimestampAccDetails()
      };
      resolve(true);
    })
    return x;
  }


  startExpressRideSubscription(){
    this.isExpressRideSubscription = this._subscription.fetchIsExpressRideObserver().subscribe((res)=>{
      this._rideType.setIsExpressRide(res);
      if(res){
        this.expressRideBanner = this._journey.getExpressRideHTMLBanner();
      }
      else{
        this.expressRideBanner = null;
      }
      
    })
  }


  startAirportRideSubscription(){
    
    this.isAirportRideSubscription = this._subscription.fetchIsAirportRideObserver().subscribe((res)=>{
        this._rideType.setIsAirportRide(res);
        this.journeyDetails.carVariantDetails.isVisible = this.selectedRideType.packageRequired ? true : res;   
        this._rideType.setIsSlotRequired(res);
    });
  }

  startLocationCtaSubscription(){
    this.triggerLocationSubscription = this._subscription.fetchTriggerLocationCtaObserver().subscribe(res=>{

      if(res){
        this._subscription.emitPackageObserver(res);
        
        if(this.selectedRideType.packageRequired){
          this.setActiveAccordian(JourneyDetailsStep.CAR_DETAILS);
          this.journeyDetails.locationDetails.isComplete = true;
          return ;
        }

        else{
          this.setActiveAccordian(this._rideType.getIsAirportRide() ? JourneyDetailsStep.CAR_DETAILS : JourneyDetailsStep.TIMESTAMP);
          this.journeyDetails.locationDetails.isComplete = true;
          return ;
        }
      }

      else{
        this.journeyDetails.locationDetails.isComplete = false;
        return ;
      }
    });
  }

  startCarVariantCtaSubscription(){
    this.triggerCarVariantSubscription = this._subscription.fetchTriggerCarVariantCtaObserver().subscribe(res=>{
      if(res){
        this._subscription.emitIsSlotRequired(this._rideType.getSelectedRideTypeDetails().slotsRequired ? true : this._rideType.getIsSlotRequired());
        this.setActiveAccordian(JourneyDetailsStep.TIMESTAMP);
        this.journeyDetails.carVariantDetails.isComplete = true;
      }
      else{
        this.journeyDetails.carVariantDetails.isComplete = false;
      }
    });
  }


  toggleActiveAcc(res, activeStep){  
      this.checkPrevStepValidation(activeStep).then(res=>{  

      Object.keys(this.journeyDetails).forEach(key=>this.journeyDetails[key]['isActive']=false);
      this.journeyDetails[activeStep]['isActive'] = res;
      
      if(this.journeyDetails.carVariantDetails.isVisible){
        this._subscription.emitPackageObserver(this.journeyDetails.carVariantDetails.isActive);
      }
    })
    .catch((res=>this.setActiveAccordian(res)));
 
  }


  // to check when traversing to next step by clicking on accordian
  checkPrevStepValidation(currActiveStep){
    let validationPromise = new Promise((resolve, reject)=>{
  
      switch(currActiveStep){
        case 'locationDetails':
          this.checkLocationDetails()
          .then(res=>resolve(res))
          .catch(res=>reject(res));
          return ;

        case 'carVariantDetails':
          this.checkLocationDetails().
          then(res=>resolve(res))
          .catch(res=>reject(res));
          return ;

        case 'timestampDetails':
          this.checkLocationDetails().then((res:boolean)=>{
            if(this.journeyDetails.carVariantDetails.isVisible){
              this.checkCarVariantDetails()
              .then((res:boolean)=>resolve(res))
              .catch(res=>reject(res));
            }

            else{
              this._journey.setCarVariantValidation(false);
              this._journey.setSelectedCarVariantCategory("");
              this._journey.setSubCategory(null);
              return;
            }
          }).catch(res=>{
            reject(res);
          })
          return;
      }
    })
    return validationPromise;
  }


  checkLocationDetails(){
    let locationPromise = new Promise((resolve, reject)=>{
      this._subscription.emitAddLocationValidationObserver();
      this._subscription.emitAdditionalDetailsValidationObserver();

      
      setTimeout(()=>{
        let addLocationValidation = this._journey.getAddLocationValidation();
        let additionalValidation =  this._journey.getAdditionalDetailsValidation();
        return addLocationValidation && additionalValidation ? resolve(true) : reject(JourneyDetailsStep.LOCATION_DETAILS);
      }, 100) 
    });

    return locationPromise;
  }

  checkCarVariantDetails(){
    let carVariantPromise = new Promise((resolve, reject)=>{
      this._subscription.emitCarVariantValidationObserver();

      setTimeout(()=>{
        return this._journey.getCarVariantValidation() ? resolve(true) : reject(JourneyDetailsStep.CAR_DETAILS);
      })
    });

    return carVariantPromise;
  }


  setActiveAccordian(activeAccordian){
    Object.keys(this.journeyDetails).forEach(step=>{
      this.journeyDetails[step].isActive  = false;
    })

    this.journeyDetails[activeAccordian]['isDisabled'] = false;
    this.journeyDetails[activeAccordian]['isActive'] = true; 
  }


  fetchPrevData(){
    this.expressRideBanner = this._rideType.getIsExpressRide() ? this._journey.getExpressRideHTMLBanner() : null;
  }


  ngOnDestroy(){
    this.triggerLocationSubscription.unsubscribe()
    this.triggerCarVariantSubscription.unsubscribe();
    this.isAirportRideSubscription.unsubscribe();
    this.isExpressRideSubscription.unsubscribe();
  }
}


