<div class="mainContainer">
    <img src="../../../assets/backBtn.svg" class="backBtn" width="3%">
  
    <div class="topSection">
      <img src="../../../assets/blu_logo.svg" alt="" class="logo" />
      
      <div class="caption-container">
        <p class="title">{{ title }}</p>
        <p class="contentContainer" [innerHTML]="content"></p>  
      </div>
      
      <div class="validationContainer">
        
        <!-- email validation -->
        <div class="mailContainer" id="emailValidationContainer">
          <div class="mailInputContainer">
            <span style="margin-bottom: 0.5rem;">Email ID</span>
            <div class="mailInputSubContainer" [ngStyle]="{ 'border-color': !emailValidation ? 'red' : '#787885'}">
            <input type="email" [(ngModel)]="enteredMailId" placeholder="Enter your mail ID" class="inputBox" (input)="validateEmail()"/>
            <img src="../../../assets/errorIcon.svg" alt="error" *ngIf="!emailValidation" class="errorIcon">
            </div>
            <span class="errMsg" *ngIf="!emailValidation">{{validationMessage}}</span>
          </div>
          <button pButton type="button" label="Verify"  [ngClass]="{'enableBtn': !disableVerifyBtn, 'disableBtn': disableVerifyBtn}" (click)="loginWithSso()"></button>
        </div>
      </div>

    <div class="bottomSection">
      <img src="../../../assets/car-and-city-image.svg" class="footerImage" alt=""/>
    </div>
  </div>
  