import { Component, EventEmitter, Input, Output } from '@angular/core';
import { checkboxType } from '../../types/components.type';

@Component({
  selector: 'custom-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {

  @Input() checkboxDetails : checkboxType;
  @Output() toggleCheck = new EventEmitter<boolean>();

  toggleSwitch(){
    if(this.checkboxDetails.disabled){
      return;
    }
    else{
      this.checkboxDetails.checked = !this.checkboxDetails.checked;
      this.toggleCheck.emit(this.checkboxDetails.checked);
    }
  }

}
