import { Component, OnInit } from '@angular/core';
import { CreateRideValidationService } from 'src/app/app-v2/validations/createRide/create-ride-validation.service';
import { AESServiceService } from 'src/app/services/AES/aesservice.service';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.css']
})
export class SsoComponent implements OnInit{

  title:string | undefined;
  content:string | undefined;
  emailValidation:boolean = true;
  enteredMailId:string  ='';
  disableVerifyBtn:boolean = true;
  newWindow: Window;
  windowInterval;
  emailDebounce;

  dropdownOptions={}
  keys = [];
  localStorageDto = {
    accessToken: '',
    businessAccountId: null,
  }



  constructor(private _app: AppService, private _aes:AESServiceService, private _validation: CreateRideValidationService){}

  ngOnInit(): void {
    this.title = 'LOGIN USING SSO';
    this.content = 'Please enter your registered mail id'
  }


  validateEmail(){
    clearInterval(this.emailDebounce);
    this.emailDebounce = setTimeout(()=>{
      let validation = this._validation.validateEmail(this.enteredMailId);
      validation.then(res=>{
        if(res.isError){
          this.disableVerifyBtn = true;
        }
        else{
          this.disableVerifyBtn = false;
        }
      })
    },500)
  }


  loginWithSso(){
    if(this.disableVerifyBtn){
      return ;
    }

    else{
      let env = environment.baseUrl;
      this.newWindow = window.open(`${env}/api/v1/auth/sso/okta/login`);
      this.onWindowClose(this.newWindow);
    }

  }

  onWindowClose(ssoWindow ){

    this.windowInterval = setInterval(()=>{
      if(ssoWindow.closed){
        clearInterval(this.windowInterval);
        this._app.checkSsoStatus(this._aes.encrypt(this.enteredMailId)).subscribe(res=>{
          if(res['statusCode'] == '200'){
            this.setAuthCredentials(res['response']);
          }

          else{
            Swal.fire({
              icon: 'error',
              title: 'Technical Error!',
              text: res['message']
            })
          }
        });
      }
    },1000)
}


    setAuthCredentials(authData){
        if(authData['businessAccountMap']){
          this.keys = Object.keys(authData.businessAccountMap);
        }
       else{
         this.keys = [];
       }
        this.dropdownOptions = authData.businessAccountMap;
        this._app.getCountry()== 'AE' ? localStorage.setItem('zoneIds', [3].toString()) : localStorage.setItem('zoneIds', [1,2].toString())
        this.localStorageDto.accessToken = authData.access_token;
        
        // if multiple business accounts are mapped
        if(this.keys.length>1 && !authData.isMfaEnabled){
          Swal.fire({
            icon: 'info',
            title: '',
            text: "Please Select the Business Account you wish to login",
            input: 'select',
            inputOptions: this.dropdownOptions,
            confirmButtonColor: "#2C66E3",
            cancelButtonColor: "#fff",
            confirmButtonText: "Confirm",
            cancelButtonText: "No"
          }).then(result=>{
              this.localStorageDto.businessAccountId = result.value;                
              this._app.setValuesInLocalStorage(this.localStorageDto)
          })}


         // if only single business account mapped 
        else {
               this.localStorageDto.businessAccountId = authData['businessAccountIds'][0];                
               this._app.setValuesInLocalStorage(this.localStorageDto)
               }}
    
}
