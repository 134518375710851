import { AppService } from './../../../services/app.service';
import { Component, OnInit, ElementRef, HostListener, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { NavigationBehaviorOptions, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { PermissionForUsers } from 'src/app/config/apiRouteConfig/checkroles';
import { COOKIE_URL, COOKIE_SUFFIX } from 'src/app/config/apiRouteConfig';
import { BusinessDetailsService } from 'src/app/services/business-details/business-details.service';
import { Subscription } from 'rxjs';
import { AddMoneyComponent } from '../../container/rides/createride/addmoney.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Output() margin: EventEmitter<number> =   new EventEmitter();
  collapse: boolean = true;
  displayCreateTicket: boolean = false;
  walletBalance:any;
  allowWallet:boolean = false;
  allowPostpaid:boolean = false;
  tooltipVisible: boolean = false;
  lowWalletBalance:boolean= false;
  subscription: Subscription;
  walletBalanceSubs: Subscription;
  headerImgURLSubs: Subscription;
  businessAccountCode;
  settingList: any[];
  option = null;
  name=''
  isRecurringRentalsEnabled = false;
  isAccountSetupEnabled = false;

  adminSettings= [{label: "Setting", value:{ action: 'SETTING', icon : 'fa fa-cog'}},
  {label: "Admin Setting",value:{ action: "ADMIN_SETTING", icon:'fa fa-user-cog'}},
  {label: "Logout", value:{action: "LOGOUT",icon: 'fa fa-sign-out'}},]

  // cities=[
  //   {label: 'All', value: 0},
  //   {label: 'Delhi-NCR', value: 1},
  //   {label: 'Bengaluru', value: 2},
  //   ];

cities = []

selectedCity:any = "";
selectedCityMulti:any = [];
pastSelectedCity:any = [];
private routerSubscription: Subscription;


  constructor(public appService: AppService, private _router: Router, private _cookieService: CookieService,private el: ElementRef,private businessDetails: BusinessDetailsService, private modal: NgbModal,private router: Router) {
    if(window.location.pathname == "/info"){
      this.collapse = false;
      this.openNav();
    }

    if(this.businessDetails.getBusinessAccCode().length > 20){
      this.businessAccountCode = businessDetails.getBusinessAccCode().substring(0,19) + '...'
    }
    else{
    this.businessAccountCode = this.businessDetails.getBusinessAccCode();
    }

  }

  ngAfterViewInit(){
    // if(localStorage.getItem('zoneIds') != null){
      this.appService.getCountryZones().subscribe(res=>{
        switch(this.appService.getCountry()){
          case 'IN':
            for (const iterator of res['response']['countryCityList']) {
                if(iterator['name'] == 'India'){
                  this.cities = iterator['cities'].map(element=>{
                    return {label: element.cityName , value: element.zoneId}
                    })
                }
            }
            // this.selectedCityMulti = [{label: 'New Delhi' , value: 1 },{label: 'Bengaluru City' , value: 2 }]

          break;

        case 'AE':
          // this.selectedCityMulti = [{label: 'Dubai' , value: 3 }]
          for (const iterator of res['response']['countryCityList']) {
            if(iterator['name'] == 'UAE'){
              this.cities = iterator['cities'].map(element=>{
                return {label: element.cityName , value: element.zoneId}
                })
            }
        }
          break;
      }

      let zone = localStorage.getItem('zoneIds')
      if(zone.includes(",")){
        this.selectedCityMulti = [];
        zone.split(",").forEach(id=>{
          this.cities.forEach((city,index)=>{
            if(city.value == id){
              this.selectedCityMulti.push(this.cities[index])
            }})})}
      else{
        this.cities.forEach((city,index)=>{
        if(city.value == zone){
          this.selectedCityMulti.push(this.cities[index])
        }
      })
      }
    })
    this.pastSelectedCity = this.selectedCityMulti;
  }

  headerImgURL:any;
  checkPermission(permissionString: String) {
    return PermissionForUsers.checkPermission(permissionString);
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
      if(this.el.nativeElement.contains(event.target)) {
        //clicked inside
      } else {

        if(this.appService.isValid){
          this.collapse = false;
          this.margin.emit(201);

        }

        //clicked outside
      }
  }

  ngOnInit() {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.tooltipVisible = false;
      }
    });

    this.margin.emit(60);
    this.settingList=[{label: "Settings", value:{ action: 'SETTING', icon : 'fa fa-cog'}},
    {label: "Logout", value:{action: "LOGOUT",icon: 'fa fa-sign-out'}}]

    this.option=null;
    this.name = localStorage.getItem('name')
    if(this.checkPermission('ADMIN_SETTING'))
        this.settingList.push( {label: "Admin Setting",value:{ action: "ADMIN_SETTING", icon:'fa fa-user-cog'}})
    this.headerImgURL= localStorage.getItem("headerImg");
    this.subscription = this.businessDetails.currentMessage.subscribe(message => {this.decideAllowWallet();
      this.walletBalance= localStorage.getItem('walletBalance');
      if(!this.walletBalance || this.walletBalance == '' || this.walletBalance ==null){
        this.walletBalance = '0';
      }
    })
    // this.checkIfToShowToolBar();

    this.walletBalanceSubs = this.businessDetails.walletBalanceMessage.subscribe(message=>{
      this.walletBalance = message;
    })

    this.headerImgURLSubs = this.businessDetails.headerImgUrlMsg.subscribe(message=>{
      this.headerImgURL = message;
    })




    this.appService.checkToken().subscribe(res=>{
      let accntSetupDetails = res["response"]["accountInfo"]
      this.lowWalletBalance = res["response"]["lowWalletBalanceToolTipEnabled"]

      if(accntSetupDetails['businessAccountDataObj']){
        if(accntSetupDetails['businessAccountDataObj']['isAccountSetupRequired']){
          this.isAccountSetupEnabled = true;
        }
        else{
          this.isAccountSetupEnabled = false;
        }
      }
      else{
        this.isAccountSetupEnabled = false;
      }
    })

    this.isRecurringRentalsEnabled=this.businessDetails.getIsRecurringRentalsEnabledFlag()
  }

  closeNav() {
    this.collapse = true;
    this.margin.emit(60);

  }

  openNav() {
    if(this.collapse==false){
      this.margin.emit(60);
    }else{
      this.margin.emit(201);
    }
    this.collapse = !this.collapse;

  }

  logout() {
    this.appService.logout().subscribe(
      (response) => {
        //console.log(response);
        //console.log("Header")
        const isHmacEnabled = localStorage.getItem("ishmacenabled");
        localStorage.clear();
        localStorage.setItem("ishmacenabled",isHmacEnabled);

        // this._cookieService.delete('accessToken' + COOKIE_SUFFIX, '/', COOKIE_URL);
        this.appService.isValid = false;
        this._router.navigateByUrl('/login');
      },
      (err) => {
        //console.log(err);
      }
    )
  }

  onRideTypeChange(value) {
    if (value == 'all')
      value = null
    this.appService.setRideType(value);
    this._router.navigate(["rides"])
    this._router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
      setTimeout(() => { this._router.navigate(["/rides"]) }, 10);
    });
  }

  onServiceRegionChange() {
    this.pastSelectedCity = this.selectedCityMulti.length < 1 ? this.pastSelectedCity : this.selectedCityMulti;
    if(this.selectedCityMulti.length <1){
      this.selectedCityMulti = this.pastSelectedCity
      location.reload()
    }

    let selectedZones = [];
    selectedZones = this.selectedCityMulti.map(element=>element.value);
    localStorage.setItem('zoneIds', selectedZones.toString())
    let prevUrl = this._router.url;
    if (this._router.url.indexOf('liverides') >= 0) {
        this._router.navigateByUrl('/rides/schedulerides', { skipLocationChange: true }).then(() => {
        this._router.navigate([prevUrl]);
      });
    }
    else {
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this._router.navigate([prevUrl]);
      });

    }

  }

  openCreateTicketDailog(){
    this.displayCreateTicket = true
  }

  checkIfToShowToolBar(){
    if(this.appService.getCountry() == 'AE')
      this.lowWalletBalance = false;
    let defaultBalance = this.appService.getCountry() == 'IN' ? 2000 : 200;
    if(this.walletBalance > defaultBalance){
      this.lowWalletBalance = false
    }else {
      this.lowWalletBalance = true
    }

  }

  decideAllowWallet(){
    let allowed = this.businessDetails.getAcceptedPaymentType();

    if(allowed){
      if(allowed.includes('BUSINESS_ACCOUNT') && this.appService.getCountry() == 'IN'){
        this.allowWallet= true
      }else if(allowed.includes('BUSINESS_ACCOUNT') && this.appService.getCountry() == 'AE'){
        this.allowPostpaid= true
      }
      else {
        this.allowWallet=false;
        this.allowPostpaid= false;
      }
    }

  }
  redirectTo(){

    if(this.option.action == "SETTING"){
        this._router.navigateByUrl('/setting')
      }

      if(this.option.action == "ADMIN_SETTING"){
        this._router.navigateByUrl('/admin-setting')
    }

    if(this.option.action == "LOGOUT"){
        this.logout()
    }


  }


  hideTooltip() {
    
    this.tooltipVisible = false;
  }
  showToolTip(){
    this.tooltipVisible = true;
  }

  ngOnDestroy(){
    this.tooltipVisible=false;
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  openModal() {
    this.tooltipVisible = false;
    //console.log("From: " + this.from.first.nativeElement);
    const modal = this.modal.open(AddMoneyComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg"
    });
    
    // (<CreaterideComponent>modal.componentInstance).dataToTakeAsInput = param;

    modal.result
      .then(result => {
        //console.log(result);
      })
      .catch(result => {
        //console.log(result);
      });
   
  }
}
