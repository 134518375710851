<div class="main-container">
<div class="star-class-container">
    <div class="star-class" *ngFor="let star of starArr; index as i " (click)="selectRating(i)">
          <svg viewBox="0 0 34 32" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.87084 31.9998L9.55859 20.4872L0.624878 12.7501L12.3915 11.729L17 0.875122L21.6085 11.729L33.3751 12.7501L24.4414 20.4872L27.1292 31.9998L17 25.8877L6.87084 31.9998Z" 
                  [attr.fill]="i < ratingDetails.value ? '#FACC15' : '#ffffff'" 
                  [attr.stroke]="'#CBD5E1'" 
                  [attr.stroke-width]="i < ratingDetails.value ? '0' : '1'" />
          </svg>
            
    </div>
</div>
</div>