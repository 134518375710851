import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { BadgeComponent } from './badge/badge.component';
import { CardComponent } from './card/card.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CustomInputComponent } from './input/input.component';
import { RadioBtnComponent } from './radio-btn/radio-btn.component';
import { AccordianComponent } from './accordian/accordian.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { StepperComponent } from './stepper/stepper.component';
import { FormsModule } from '@angular/forms';
import { InputValidatorComponent } from './input-validator/input-validator.component';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { MapComponent } from './map/map.component'
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { AgmDirectionModule } from 'agm-direction';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { TextAreaComponent } from './text-area/text-area.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EditableContainerComponent } from './editable-container/editable-container.component';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { PopUpComponent } from './pop-up/pop-up.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { RatingComponent } from './rating/rating.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';





@NgModule({
  declarations: [
    ButtonComponent,
    CardComponent,
    AccordianComponent,
    BadgeComponent,
    RadioBtnComponent,
    CheckboxComponent,
    ButtonComponent,
    BreadcrumbComponent,
    StepperComponent,
    CustomInputComponent,
    InputValidatorComponent,
    MapComponent,
    TextAreaComponent,
    DropdownComponent,
    EditableContainerComponent,
    PopUpComponent,
    TooltipComponent,
    NotificationBarComponent,
    CarouselComponent,
    CustomTableComponent,
    RatingComponent,
    FileUploaderComponent,
  ],
  imports: [
    CommonModule,
    CalendarModule,
    GooglePlaceModule,
    FormsModule,
    DialogModule,
    AgmDirectionModule,
    DropdownModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googlePlacesAPIKey
    })
  ],
  exports: [BreadcrumbComponent, StepperComponent, CardComponent, CustomInputComponent, InputValidatorComponent, ButtonComponent
            ,TextAreaComponent,AccordianComponent, MapComponent, DropdownComponent, EditableContainerComponent, PopUpComponent,
            CheckboxComponent, TooltipComponent, NotificationBarComponent,CarouselComponent, CustomTableComponent, RatingComponent, FileUploaderComponent]
})
export class ComponentsModule { }
