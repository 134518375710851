export const dubaiBounds = {
                "0": {
                    "bounds": [
                        {
                            "lng": 55.3725274,
                            "lat": 25.3575148
                        },
                        {
                            "lng": 55.3464251,
                            "lat": 25.3249671
                        },
                        {
                            "lng": 55.2997332,
                            "lat": 25.3628212
                        },
                        {
                            "lng": 55.2887469,
                            "lat": 25.3609598
                        },
                        {
                            "lng": 55.2956134,
                            "lat": 25.3460676
                        },
                        {
                            "lng": 55.2777606,
                            "lat": 25.3305529
                        },
                        {
                            "lng": 55.2818805,
                            "lat": 25.3212431
                        },
                        {
                            "lng": 55.2784472,
                            "lat": 25.3106911
                        },
                        {
                            "lng": 55.2894336,
                            "lat": 25.2926887
                        },
                        {
                            "lng": 55.2729541,
                            "lat": 25.2852386
                        },
                        {
                            "lng": 55.2578479,
                            "lat": 25.2883428
                        },
                        {
                            "lng": 55.2502948,
                            "lat": 25.2746835
                        },
                        {
                            "lng": 55.2530413,
                            "lat": 25.2535706
                        },
                        {
                            "lng": 55.2454882,
                            "lat": 25.2250002
                        },
                        {
                            "lng": 55.2386218,
                            "lat": 25.2100913
                        },
                        {
                            "lng": 55.2276355,
                            "lat": 25.206985
                        },
                        {
                            "lng": 55.2221423,
                            "lat": 25.1864817
                        },
                        {
                            "lng": 55.2036029,
                            "lat": 25.1665964
                        },
                        {
                            "lng": 55.18987,
                            "lat": 25.154788
                        },
                        {
                            "lng": 55.1651507,
                            "lat": 25.1162473
                        },
                        {
                            "lng": 55.1582843,
                            "lat": 25.1205993
                        },
                        {
                            "lng": 55.1603442,
                            "lat": 25.1386273
                        },
                        {
                            "lng": 55.1486712,
                            "lat": 25.1473295
                        },
                        {
                            "lng": 55.1273852,
                            "lat": 25.1436
                        },
                        {
                            "lng": 55.1129657,
                            "lat": 25.1367624
                        },
                        {
                            "lng": 55.1019793,
                            "lat": 25.1181125
                        },
                        {
                            "lng": 55.1115924,
                            "lat": 25.1025687
                        },
                        {
                            "lng": 55.1260119,
                            "lat": 25.0938633
                        },
                        {
                            "lng": 55.1081591,
                            "lat": 25.0895104
                        },
                        {
                            "lng": 55.1232653,
                            "lat": 25.0714751
                        },
                        {
                            "lng": 55.0827533,
                            "lat": 25.0291753
                        },
                        {
                            "lng": 55.0717669,
                            "lat": 25.0297974
                        },
                        {
                            "lng": 55.0436145,
                            "lat": 25.0260644
                        },
                        {
                            "lng": 55.0504809,
                            "lat": 25.0086421
                        },
                        {
                            "lng": 55.0312548,
                            "lat": 24.9893503
                        },
                        {
                            "lng": 55.0257617,
                            "lat": 25.018598
                        },
                        {
                            "lng": 55.0216418,
                            "lat": 25.0353967
                        },
                        {
                            "lng": 54.9948626,
                            "lat": 25.0447284
                        },
                        {
                            "lng": 54.9619037,
                            "lat": 25.0353967
                        },
                        {
                            "lng": 54.9419909,
                            "lat": 25.0080199
                        },
                        {
                            "lng": 54.97289,
                            "lat": 24.9719228
                        },
                        {
                            "lng": 54.8994189,
                            "lat": 24.9152664
                        },
                        {
                            "lng": 55.0085955,
                            "lat": 24.8554688
                        },
                        {
                            "lng": 55.0497943,
                            "lat": 24.8336604
                        },
                        {
                            "lng": 55.0772601,
                            "lat": 24.7925256
                        },
                        {
                            "lng": 55.1671764,
                            "lat": 24.852182
                        },
                        {
                            "lng": 55.203239,
                            "lat": 24.8876026
                        },
                        {
                            "lng": 55.224212,
                            "lat": 24.9242634
                        },
                        {
                            "lng": 55.2310784,
                            "lat": 24.9541492
                        },
                        {
                            "lng": 55.2619775,
                            "lat": 24.9491687
                        },
                        {
                            "lng": 55.2873834,
                            "lat": 24.9560168
                        },
                        {
                            "lng": 55.3024896,
                            "lat": 24.9547717
                        },
                        {
                            "lng": 55.3402551,
                            "lat": 24.9504138
                        },
                        {
                            "lng": 55.3793939,
                            "lat": 24.9522815
                        },
                        {
                            "lng": 55.4013665,
                            "lat": 24.9560168
                        },
                        {
                            "lng": 55.4329522,
                            "lat": 24.9902514
                        },
                        {
                            "lng": 55.460418,
                            "lat": 24.9939855
                        },
                        {
                            "lng": 55.4714044,
                            "lat": 25.0101654
                        },
                        {
                            "lng": 55.3993066,
                            "lat": 25.0866796
                        },
                        {
                            "lng": 55.5009301,
                            "lat": 25.1656319
                        },
                        {
                            "lng": 55.5496819,
                            "lat": 25.2010506
                        },
                        {
                            "lng": 55.5702813,
                            "lat": 25.2377012
                        },
                        {
                            "lng": 55.4576714,
                            "lat": 25.2805497
                        },
                        {
                            "lng": 55.4377587,
                            "lat": 25.2960708
                        },
                        {
                            "lng": 55.3958733,
                            "lat": 25.3295896
                        },
                        {
                            "lng": 55.3876336,
                            "lat": 25.345725
                        },
                        {
                            "lng": 55.3725274,
                            "lat": 25.3575148
                        }
                    ],
                    "id": "ridesPickUp"
                },
                "1": {
                    "bounds": [
                        {
                            "lng": 55.3725274,
                            "lat": 25.3575148
                        },
                        {
                            "lng": 55.3464251,
                            "lat": 25.3249671
                        },
                        {
                            "lng": 55.2997332,
                            "lat": 25.3628212
                        },
                        {
                            "lng": 55.2887469,
                            "lat": 25.3609598
                        },
                        {
                            "lng": 55.2956134,
                            "lat": 25.3460676
                        },
                        {
                            "lng": 55.2777606,
                            "lat": 25.3305529
                        },
                        {
                            "lng": 55.2818805,
                            "lat": 25.3212431
                        },
                        {
                            "lng": 55.2784472,
                            "lat": 25.3106911
                        },
                        {
                            "lng": 55.2894336,
                            "lat": 25.2926887
                        },
                        {
                            "lng": 55.2729541,
                            "lat": 25.2852386
                        },
                        {
                            "lng": 55.2578479,
                            "lat": 25.2883428
                        },
                        {
                            "lng": 55.2502948,
                            "lat": 25.2746835
                        },
                        {
                            "lng": 55.2530413,
                            "lat": 25.2535706
                        },
                        {
                            "lng": 55.2454882,
                            "lat": 25.2250002
                        },
                        {
                            "lng": 55.2386218,
                            "lat": 25.2100913
                        },
                        {
                            "lng": 55.2276355,
                            "lat": 25.206985
                        },
                        {
                            "lng": 55.2221423,
                            "lat": 25.1864817
                        },
                        {
                            "lng": 55.2036029,
                            "lat": 25.1665964
                        },
                        {
                            "lng": 55.18987,
                            "lat": 25.154788
                        },
                        {
                            "lng": 55.1651507,
                            "lat": 25.1162473
                        },
                        {
                            "lng": 55.1582843,
                            "lat": 25.1205993
                        },
                        {
                            "lng": 55.1603442,
                            "lat": 25.1386273
                        },
                        {
                            "lng": 55.1486712,
                            "lat": 25.1473295
                        },
                        {
                            "lng": 55.1273852,
                            "lat": 25.1436
                        },
                        {
                            "lng": 55.1129657,
                            "lat": 25.1367624
                        },
                        {
                            "lng": 55.1019793,
                            "lat": 25.1181125
                        },
                        {
                            "lng": 55.1115924,
                            "lat": 25.1025687
                        },
                        {
                            "lng": 55.1260119,
                            "lat": 25.0938633
                        },
                        {
                            "lng": 55.1081591,
                            "lat": 25.0895104
                        },
                        {
                            "lng": 55.1232653,
                            "lat": 25.0714751
                        },
                        {
                            "lng": 55.0827533,
                            "lat": 25.0291753
                        },
                        {
                            "lng": 55.0717669,
                            "lat": 25.0297974
                        },
                        {
                            "lng": 55.0436145,
                            "lat": 25.0260644
                        },
                        {
                            "lng": 55.0504809,
                            "lat": 25.0086421
                        },
                        {
                            "lng": 55.0312548,
                            "lat": 24.9893503
                        },
                        {
                            "lng": 55.0257617,
                            "lat": 25.018598
                        },
                        {
                            "lng": 55.0216418,
                            "lat": 25.0353967
                        },
                        {
                            "lng": 54.9948626,
                            "lat": 25.0447284
                        },
                        {
                            "lng": 54.9619037,
                            "lat": 25.0353967
                        },
                        {
                            "lng": 54.9419909,
                            "lat": 25.0080199
                        },
                        {
                            "lng": 54.97289,
                            "lat": 24.9719228
                        },
                        {
                            "lng": 54.8994189,
                            "lat": 24.9152664
                        },
                        {
                            "lng": 55.0085955,
                            "lat": 24.8554688
                        },
                        {
                            "lng": 55.0497943,
                            "lat": 24.8336604
                        },
                        {
                            "lng": 55.0772601,
                            "lat": 24.7925256
                        },
                        {
                            "lng": 55.1671764,
                            "lat": 24.852182
                        },
                        {
                            "lng": 55.203239,
                            "lat": 24.8876026
                        },
                        {
                            "lng": 55.224212,
                            "lat": 24.9242634
                        },
                        {
                            "lng": 55.2310784,
                            "lat": 24.9541492
                        },
                        {
                            "lng": 55.2619775,
                            "lat": 24.9491687
                        },
                        {
                            "lng": 55.2873834,
                            "lat": 24.9560168
                        },
                        {
                            "lng": 55.3024896,
                            "lat": 24.9547717
                        },
                        {
                            "lng": 55.3402551,
                            "lat": 24.9504138
                        },
                        {
                            "lng": 55.3793939,
                            "lat": 24.9522815
                        },
                        {
                            "lng": 55.4013665,
                            "lat": 24.9560168
                        },
                        {
                            "lng": 55.4329522,
                            "lat": 24.9902514
                        },
                        {
                            "lng": 55.460418,
                            "lat": 24.9939855
                        },
                        {
                            "lng": 55.4714044,
                            "lat": 25.0101654
                        },
                        {
                            "lng": 55.3993066,
                            "lat": 25.0866796
                        },
                        {
                            "lng": 55.5009301,
                            "lat": 25.1656319
                        },
                        {
                            "lng": 55.5496819,
                            "lat": 25.2010506
                        },
                        {
                            "lng": 55.5702813,
                            "lat": 25.2377012
                        },
                        {
                            "lng": 55.4576714,
                            "lat": 25.2805497
                        },
                        {
                            "lng": 55.4377587,
                            "lat": 25.2960708
                        },
                        {
                            "lng": 55.3958733,
                            "lat": 25.3295896
                        },
                        {
                            "lng": 55.3876336,
                            "lat": 25.345725
                        },
                        {
                            "lng": 55.3725274,
                            "lat": 25.3575148
                        }
                    ],
                    "id": "ridesDrop"
                },
                "2": {
                    "bounds": [
                        {
                            "lng": 54.7776355,
                            "lat": 24.6944557
                        },
                        {
                            "lng": 54.7566928,
                            "lat": 24.7075559
                        },
                        {
                            "lng": 54.6609058,
                            "lat": 24.6779226
                        },
                        {
                            "lng": 54.6602191,
                            "lat": 24.6398568
                        },
                        {
                            "lng": 54.6344699,
                            "lat": 24.6214438
                        },
                        {
                            "lng": 54.6190204,
                            "lat": 24.6223801
                        },
                        {
                            "lng": 54.6135273,
                            "lat": 24.6145772
                        },
                        {
                            "lng": 54.6052875,
                            "lat": 24.61645
                        },
                        {
                            "lng": 54.6083774,
                            "lat": 24.6014673
                        },
                        {
                            "lng": 54.6361866,
                            "lat": 24.6083346
                        },
                        {
                            "lng": 54.6433963,
                            "lat": 24.5967848
                        },
                        {
                            "lng": 54.6282901,
                            "lat": 24.5640028
                        },
                        {
                            "lng": 54.6155872,
                            "lat": 24.5483892
                        },
                        {
                            "lng": 54.620737,
                            "lat": 24.5337107
                        },
                        {
                            "lng": 54.5870914,
                            "lat": 24.5055982
                        },
                        {
                            "lng": 54.5263233,
                            "lat": 24.5209047
                        },
                        {
                            "lng": 54.5390262,
                            "lat": 24.5412064
                        },
                        {
                            "lng": 54.5331897,
                            "lat": 24.5437048
                        },
                        {
                            "lng": 54.5160236,
                            "lat": 24.5424556
                        },
                        {
                            "lng": 54.5156803,
                            "lat": 24.5218418
                        },
                        {
                            "lng": 54.5053806,
                            "lat": 24.5330861
                        },
                        {
                            "lng": 54.4871845,
                            "lat": 24.5521367
                        },
                        {
                            "lng": 54.4889011,
                            "lat": 24.5702476
                        },
                        {
                            "lng": 54.4748249,
                            "lat": 24.5711843
                        },
                        {
                            "lng": 54.4593753,
                            "lat": 24.5540103
                        },
                        {
                            "lng": 54.4748249,
                            "lat": 24.586795
                        },
                        {
                            "lng": 54.4411792,
                            "lat": 24.5508875
                        },
                        {
                            "lng": 54.4216098,
                            "lat": 24.5424556
                        },
                        {
                            "lng": 54.3948307,
                            "lat": 24.536834
                        },
                        {
                            "lng": 54.4082202,
                            "lat": 24.5259023
                        },
                        {
                            "lng": 54.3938007,
                            "lat": 24.514345
                        },
                        {
                            "lng": 54.3817844,
                            "lat": 24.5284011
                        },
                        {
                            "lng": 54.3886508,
                            "lat": 24.5383956
                        },
                        {
                            "lng": 54.361185,
                            "lat": 24.5387079
                        },
                        {
                            "lng": 54.3615284,
                            "lat": 24.5262147
                        },
                        {
                            "lng": 54.3690815,
                            "lat": 24.5002873
                        },
                        {
                            "lng": 54.3550052,
                            "lat": 24.5152821
                        },
                        {
                            "lng": 54.3357791,
                            "lat": 24.4996625
                        },
                        {
                            "lng": 54.3326892,
                            "lat": 24.4827912
                        },
                        {
                            "lng": 54.3501987,
                            "lat": 24.4815414
                        },
                        {
                            "lng": 54.3295993,
                            "lat": 24.4668551
                        },
                        {
                            "lng": 54.3261661,
                            "lat": 24.4718549
                        },
                        {
                            "lng": 54.3217029,
                            "lat": 24.484041
                        },
                        {
                            "lng": 54.30694,
                            "lat": 24.480604
                        },
                        {
                            "lng": 54.3244495,
                            "lat": 24.4640426
                        },
                        {
                            "lng": 54.2914905,
                            "lat": 24.4702924
                        },
                        {
                            "lng": 54.3096866,
                            "lat": 24.4496668
                        },
                        {
                            "lng": 54.340929,
                            "lat": 24.4509169
                        },
                        {
                            "lng": 54.3649616,
                            "lat": 24.4321636
                        },
                        {
                            "lng": 54.4075336,
                            "lat": 24.4171589
                        },
                        {
                            "lng": 54.4672718,
                            "lat": 24.3968372
                        },
                        {
                            "lng": 54.4725943,
                            "lat": 24.3810483
                        },
                        {
                            "lng": 54.4950813,
                            "lat": 24.3815183
                        },
                        {
                            "lng": 54.5252933,
                            "lat": 24.3824538
                        },
                        {
                            "lng": 54.52495,
                            "lat": 24.3242779
                        },
                        {
                            "lng": 54.5510425,
                            "lat": 24.2839144
                        },
                        {
                            "lng": 54.6008243,
                            "lat": 24.3092604
                        },
                        {
                            "lng": 54.6358432,
                            "lat": 24.3380423
                        },
                        {
                            "lng": 54.654726,
                            "lat": 24.3693196
                        },
                        {
                            "lng": 54.7000446,
                            "lat": 24.3374167
                        },
                        {
                            "lng": 54.720644,
                            "lat": 24.36025
                        },
                        {
                            "lng": 54.7446765,
                            "lat": 24.3474263
                        },
                        {
                            "lng": 54.7000446,
                            "lat": 24.3371039
                        },
                        {
                            "lng": 54.7209873,
                            "lat": 24.3233393
                        },
                        {
                            "lng": 54.7275104,
                            "lat": 24.3170822
                        },
                        {
                            "lng": 54.8006382,
                            "lat": 24.3195851
                        },
                        {
                            "lng": 54.7755756,
                            "lat": 24.3824538
                        },
                        {
                            "lng": 54.7659626,
                            "lat": 24.3724469
                        },
                        {
                            "lng": 54.74193,
                            "lat": 24.3890204
                        },
                        {
                            "lng": 54.7639026,
                            "lat": 24.4159085
                        },
                        {
                            "lng": 54.7673358,
                            "lat": 24.4293504
                        },
                        {
                            "lng": 54.7498264,
                            "lat": 24.4615426
                        },
                        {
                            "lng": 54.6766986,
                            "lat": 24.4643551
                        },
                        {
                            "lng": 54.6736087,
                            "lat": 24.4793542
                        },
                        {
                            "lng": 54.6918048,
                            "lat": 24.5193429
                        },
                        {
                            "lng": 54.6966114,
                            "lat": 24.5768044
                        },
                        {
                            "lng": 54.7178974,
                            "lat": 24.622068
                        },
                        {
                            "lng": 54.7628726,
                            "lat": 24.6123923
                        },
                        {
                            "lng": 54.8178043,
                            "lat": 24.6766747
                        },
                        {
                            "lng": 54.7776355,
                            "lat": 24.6944557
                        }
                    ],
                    "id": "ridesDrop"
                },
                "3": {
                    "bounds": [
                        {
                            "lng": 55.3720102,
                            "lat": 25.3596132
                        },
                        {
                            "lng": 55.3956995,
                            "lat": 25.3288956
                        },
                        {
                            "lng": 55.4214487,
                            "lat": 25.3087232
                        },
                        {
                            "lng": 55.3984461,
                            "lat": 25.2863746
                        },
                        {
                            "lng": 55.3953562,
                            "lat": 25.2708522
                        },
                        {
                            "lng": 55.420762,
                            "lat": 25.2335905
                        },
                        {
                            "lng": 55.466424,
                            "lat": 25.2345222
                        },
                        {
                            "lng": 55.5628977,
                            "lat": 25.312758
                        },
                        {
                            "lng": 55.4794702,
                            "lat": 25.3565108
                        },
                        {
                            "lng": 55.4657373,
                            "lat": 25.3351018
                        },
                        {
                            "lng": 55.4348383,
                            "lat": 25.3527877
                        },
                        {
                            "lng": 55.444108,
                            "lat": 25.3620951
                        },
                        {
                            "lng": 55.3991327,
                            "lat": 25.3819484
                        },
                        {
                            "lng": 55.3785334,
                            "lat": 25.3735732
                        },
                        {
                            "lng": 55.3720102,
                            "lat": 25.3596132
                        }
                    ],
                    "id": "ridesDrop"
                },
                "4": {
                    "bounds": [
                        {
                            "lng": 55.3725274,
                            "lat": 25.3575148
                        },
                        {
                            "lng": 55.3464251,
                            "lat": 25.3249671
                        },
                        {
                            "lng": 55.2997332,
                            "lat": 25.3628212
                        },
                        {
                            "lng": 55.2887469,
                            "lat": 25.3609598
                        },
                        {
                            "lng": 55.2956134,
                            "lat": 25.3460676
                        },
                        {
                            "lng": 55.2777606,
                            "lat": 25.3305529
                        },
                        {
                            "lng": 55.2818805,
                            "lat": 25.3212431
                        },
                        {
                            "lng": 55.2784472,
                            "lat": 25.3106911
                        },
                        {
                            "lng": 55.2894336,
                            "lat": 25.2926887
                        },
                        {
                            "lng": 55.2729541,
                            "lat": 25.2852386
                        },
                        {
                            "lng": 55.2578479,
                            "lat": 25.2883428
                        },
                        {
                            "lng": 55.2502948,
                            "lat": 25.2746835
                        },
                        {
                            "lng": 55.2530413,
                            "lat": 25.2535706
                        },
                        {
                            "lng": 55.2454882,
                            "lat": 25.2250002
                        },
                        {
                            "lng": 55.2386218,
                            "lat": 25.2100913
                        },
                        {
                            "lng": 55.2276355,
                            "lat": 25.206985
                        },
                        {
                            "lng": 55.2221423,
                            "lat": 25.1864817
                        },
                        {
                            "lng": 55.2036029,
                            "lat": 25.1665964
                        },
                        {
                            "lng": 55.18987,
                            "lat": 25.154788
                        },
                        {
                            "lng": 55.1651507,
                            "lat": 25.1162473
                        },
                        {
                            "lng": 55.1582843,
                            "lat": 25.1205993
                        },
                        {
                            "lng": 55.1603442,
                            "lat": 25.1386273
                        },
                        {
                            "lng": 55.1486712,
                            "lat": 25.1473295
                        },
                        {
                            "lng": 55.1273852,
                            "lat": 25.1436
                        },
                        {
                            "lng": 55.1129657,
                            "lat": 25.1367624
                        },
                        {
                            "lng": 55.1019793,
                            "lat": 25.1181125
                        },
                        {
                            "lng": 55.1115924,
                            "lat": 25.1025687
                        },
                        {
                            "lng": 55.1260119,
                            "lat": 25.0938633
                        },
                        {
                            "lng": 55.1081591,
                            "lat": 25.0895104
                        },
                        {
                            "lng": 55.1232653,
                            "lat": 25.0714751
                        },
                        {
                            "lng": 55.0827533,
                            "lat": 25.0291753
                        },
                        {
                            "lng": 55.0717669,
                            "lat": 25.0297974
                        },
                        {
                            "lng": 55.0436145,
                            "lat": 25.0260644
                        },
                        {
                            "lng": 55.0504809,
                            "lat": 25.0086421
                        },
                        {
                            "lng": 55.0312548,
                            "lat": 24.9893503
                        },
                        {
                            "lng": 55.0257617,
                            "lat": 25.018598
                        },
                        {
                            "lng": 55.0216418,
                            "lat": 25.0353967
                        },
                        {
                            "lng": 54.9948626,
                            "lat": 25.0447284
                        },
                        {
                            "lng": 54.9619037,
                            "lat": 25.0353967
                        },
                        {
                            "lng": 54.9419909,
                            "lat": 25.0080199
                        },
                        {
                            "lng": 54.97289,
                            "lat": 24.9719228
                        },
                        {
                            "lng": 54.8994189,
                            "lat": 24.9152664
                        },
                        {
                            "lng": 55.0085955,
                            "lat": 24.8554688
                        },
                        {
                            "lng": 55.0497943,
                            "lat": 24.8336604
                        },
                        {
                            "lng": 55.0772601,
                            "lat": 24.7925256
                        },
                        {
                            "lng": 55.1671764,
                            "lat": 24.852182
                        },
                        {
                            "lng": 55.203239,
                            "lat": 24.8876026
                        },
                        {
                            "lng": 55.224212,
                            "lat": 24.9242634
                        },
                        {
                            "lng": 55.2310784,
                            "lat": 24.9541492
                        },
                        {
                            "lng": 55.2619775,
                            "lat": 24.9491687
                        },
                        {
                            "lng": 55.2873834,
                            "lat": 24.9560168
                        },
                        {
                            "lng": 55.3024896,
                            "lat": 24.9547717
                        },
                        {
                            "lng": 55.3402551,
                            "lat": 24.9504138
                        },
                        {
                            "lng": 55.3793939,
                            "lat": 24.9522815
                        },
                        {
                            "lng": 55.4013665,
                            "lat": 24.9560168
                        },
                        {
                            "lng": 55.4329522,
                            "lat": 24.9902514
                        },
                        {
                            "lng": 55.460418,
                            "lat": 24.9939855
                        },
                        {
                            "lng": 55.4714044,
                            "lat": 25.0101654
                        },
                        {
                            "lng": 55.3993066,
                            "lat": 25.0866796
                        },
                        {
                            "lng": 55.5009301,
                            "lat": 25.1656319
                        },
                        {
                            "lng": 55.5496819,
                            "lat": 25.2010506
                        },
                        {
                            "lng": 55.5702813,
                            "lat": 25.2377012
                        },
                        {
                            "lng": 55.4576714,
                            "lat": 25.2805497
                        },
                        {
                            "lng": 55.4377587,
                            "lat": 25.2960708
                        },
                        {
                            "lng": 55.3958733,
                            "lat": 25.3295896
                        },
                        {
                            "lng": 55.3876336,
                            "lat": 25.345725
                        },
                        {
                            "lng": 55.3725274,
                            "lat": 25.3575148
                        }
                    ],
                    "id": "rentalsPickUp"
                },
                "5": {
                    "bounds": [
                        {
                            "lng": 55.3725274,
                            "lat": 25.3575148
                        },
                        {
                            "lng": 55.3464251,
                            "lat": 25.3249671
                        },
                        {
                            "lng": 55.2997332,
                            "lat": 25.3628212
                        },
                        {
                            "lng": 55.2887469,
                            "lat": 25.3609598
                        },
                        {
                            "lng": 55.2956134,
                            "lat": 25.3460676
                        },
                        {
                            "lng": 55.2777606,
                            "lat": 25.3305529
                        },
                        {
                            "lng": 55.2818805,
                            "lat": 25.3212431
                        },
                        {
                            "lng": 55.2784472,
                            "lat": 25.3106911
                        },
                        {
                            "lng": 55.2894336,
                            "lat": 25.2926887
                        },
                        {
                            "lng": 55.2729541,
                            "lat": 25.2852386
                        },
                        {
                            "lng": 55.2578479,
                            "lat": 25.2883428
                        },
                        {
                            "lng": 55.2502948,
                            "lat": 25.2746835
                        },
                        {
                            "lng": 55.2530413,
                            "lat": 25.2535706
                        },
                        {
                            "lng": 55.2454882,
                            "lat": 25.2250002
                        },
                        {
                            "lng": 55.2386218,
                            "lat": 25.2100913
                        },
                        {
                            "lng": 55.2276355,
                            "lat": 25.206985
                        },
                        {
                            "lng": 55.2221423,
                            "lat": 25.1864817
                        },
                        {
                            "lng": 55.2036029,
                            "lat": 25.1665964
                        },
                        {
                            "lng": 55.18987,
                            "lat": 25.154788
                        },
                        {
                            "lng": 55.1651507,
                            "lat": 25.1162473
                        },
                        {
                            "lng": 55.1582843,
                            "lat": 25.1205993
                        },
                        {
                            "lng": 55.1603442,
                            "lat": 25.1386273
                        },
                        {
                            "lng": 55.1486712,
                            "lat": 25.1473295
                        },
                        {
                            "lng": 55.1273852,
                            "lat": 25.1436
                        },
                        {
                            "lng": 55.1129657,
                            "lat": 25.1367624
                        },
                        {
                            "lng": 55.1019793,
                            "lat": 25.1181125
                        },
                        {
                            "lng": 55.1115924,
                            "lat": 25.1025687
                        },
                        {
                            "lng": 55.1260119,
                            "lat": 25.0938633
                        },
                        {
                            "lng": 55.1081591,
                            "lat": 25.0895104
                        },
                        {
                            "lng": 55.1232653,
                            "lat": 25.0714751
                        },
                        {
                            "lng": 55.0827533,
                            "lat": 25.0291753
                        },
                        {
                            "lng": 55.0717669,
                            "lat": 25.0297974
                        },
                        {
                            "lng": 55.0436145,
                            "lat": 25.0260644
                        },
                        {
                            "lng": 55.0504809,
                            "lat": 25.0086421
                        },
                        {
                            "lng": 55.0312548,
                            "lat": 24.9893503
                        },
                        {
                            "lng": 55.0257617,
                            "lat": 25.018598
                        },
                        {
                            "lng": 55.0216418,
                            "lat": 25.0353967
                        },
                        {
                            "lng": 54.9948626,
                            "lat": 25.0447284
                        },
                        {
                            "lng": 54.9619037,
                            "lat": 25.0353967
                        },
                        {
                            "lng": 54.9419909,
                            "lat": 25.0080199
                        },
                        {
                            "lng": 54.97289,
                            "lat": 24.9719228
                        },
                        {
                            "lng": 54.8994189,
                            "lat": 24.9152664
                        },
                        {
                            "lng": 55.0085955,
                            "lat": 24.8554688
                        },
                        {
                            "lng": 55.0497943,
                            "lat": 24.8336604
                        },
                        {
                            "lng": 55.0772601,
                            "lat": 24.7925256
                        },
                        {
                            "lng": 55.1671764,
                            "lat": 24.852182
                        },
                        {
                            "lng": 55.203239,
                            "lat": 24.8876026
                        },
                        {
                            "lng": 55.224212,
                            "lat": 24.9242634
                        },
                        {
                            "lng": 55.2310784,
                            "lat": 24.9541492
                        },
                        {
                            "lng": 55.2619775,
                            "lat": 24.9491687
                        },
                        {
                            "lng": 55.2873834,
                            "lat": 24.9560168
                        },
                        {
                            "lng": 55.3024896,
                            "lat": 24.9547717
                        },
                        {
                            "lng": 55.3402551,
                            "lat": 24.9504138
                        },
                        {
                            "lng": 55.3793939,
                            "lat": 24.9522815
                        },
                        {
                            "lng": 55.4013665,
                            "lat": 24.9560168
                        },
                        {
                            "lng": 55.4329522,
                            "lat": 24.9902514
                        },
                        {
                            "lng": 55.460418,
                            "lat": 24.9939855
                        },
                        {
                            "lng": 55.4714044,
                            "lat": 25.0101654
                        },
                        {
                            "lng": 55.3993066,
                            "lat": 25.0866796
                        },
                        {
                            "lng": 55.5009301,
                            "lat": 25.1656319
                        },
                        {
                            "lng": 55.5496819,
                            "lat": 25.2010506
                        },
                        {
                            "lng": 55.5702813,
                            "lat": 25.2377012
                        },
                        {
                            "lng": 55.4576714,
                            "lat": 25.2805497
                        },
                        {
                            "lng": 55.4377587,
                            "lat": 25.2960708
                        },
                        {
                            "lng": 55.3958733,
                            "lat": 25.3295896
                        },
                        {
                            "lng": 55.3876336,
                            "lat": 25.345725
                        },
                        {
                            "lng": 55.3725274,
                            "lat": 25.3575148
                        }
                    ],
                    "id": "rentalsDrop"
                },
                "6": {
                    "bounds": [
                        {
                            "lng": 54.7776355,
                            "lat": 24.6944557
                        },
                        {
                            "lng": 54.7566928,
                            "lat": 24.7075559
                        },
                        {
                            "lng": 54.6609058,
                            "lat": 24.6779226
                        },
                        {
                            "lng": 54.6602191,
                            "lat": 24.6398568
                        },
                        {
                            "lng": 54.6344699,
                            "lat": 24.6214438
                        },
                        {
                            "lng": 54.6190204,
                            "lat": 24.6223801
                        },
                        {
                            "lng": 54.6135273,
                            "lat": 24.6145772
                        },
                        {
                            "lng": 54.6052875,
                            "lat": 24.61645
                        },
                        {
                            "lng": 54.6083774,
                            "lat": 24.6014673
                        },
                        {
                            "lng": 54.6361866,
                            "lat": 24.6083346
                        },
                        {
                            "lng": 54.6433963,
                            "lat": 24.5967848
                        },
                        {
                            "lng": 54.6282901,
                            "lat": 24.5640028
                        },
                        {
                            "lng": 54.6155872,
                            "lat": 24.5483892
                        },
                        {
                            "lng": 54.620737,
                            "lat": 24.5337107
                        },
                        {
                            "lng": 54.5870914,
                            "lat": 24.5055982
                        },
                        {
                            "lng": 54.5263233,
                            "lat": 24.5209047
                        },
                        {
                            "lng": 54.5390262,
                            "lat": 24.5412064
                        },
                        {
                            "lng": 54.5331897,
                            "lat": 24.5437048
                        },
                        {
                            "lng": 54.5160236,
                            "lat": 24.5424556
                        },
                        {
                            "lng": 54.5156803,
                            "lat": 24.5218418
                        },
                        {
                            "lng": 54.5053806,
                            "lat": 24.5330861
                        },
                        {
                            "lng": 54.4871845,
                            "lat": 24.5521367
                        },
                        {
                            "lng": 54.4889011,
                            "lat": 24.5702476
                        },
                        {
                            "lng": 54.4748249,
                            "lat": 24.5711843
                        },
                        {
                            "lng": 54.4593753,
                            "lat": 24.5540103
                        },
                        {
                            "lng": 54.4748249,
                            "lat": 24.586795
                        },
                        {
                            "lng": 54.4411792,
                            "lat": 24.5508875
                        },
                        {
                            "lng": 54.4216098,
                            "lat": 24.5424556
                        },
                        {
                            "lng": 54.3948307,
                            "lat": 24.536834
                        },
                        {
                            "lng": 54.4082202,
                            "lat": 24.5259023
                        },
                        {
                            "lng": 54.3938007,
                            "lat": 24.514345
                        },
                        {
                            "lng": 54.3817844,
                            "lat": 24.5284011
                        },
                        {
                            "lng": 54.3886508,
                            "lat": 24.5383956
                        },
                        {
                            "lng": 54.361185,
                            "lat": 24.5387079
                        },
                        {
                            "lng": 54.3615284,
                            "lat": 24.5262147
                        },
                        {
                            "lng": 54.3690815,
                            "lat": 24.5002873
                        },
                        {
                            "lng": 54.3550052,
                            "lat": 24.5152821
                        },
                        {
                            "lng": 54.3357791,
                            "lat": 24.4996625
                        },
                        {
                            "lng": 54.3326892,
                            "lat": 24.4827912
                        },
                        {
                            "lng": 54.3501987,
                            "lat": 24.4815414
                        },
                        {
                            "lng": 54.3295993,
                            "lat": 24.4668551
                        },
                        {
                            "lng": 54.3261661,
                            "lat": 24.4718549
                        },
                        {
                            "lng": 54.3217029,
                            "lat": 24.484041
                        },
                        {
                            "lng": 54.30694,
                            "lat": 24.480604
                        },
                        {
                            "lng": 54.3244495,
                            "lat": 24.4640426
                        },
                        {
                            "lng": 54.2914905,
                            "lat": 24.4702924
                        },
                        {
                            "lng": 54.3096866,
                            "lat": 24.4496668
                        },
                        {
                            "lng": 54.340929,
                            "lat": 24.4509169
                        },
                        {
                            "lng": 54.3649616,
                            "lat": 24.4321636
                        },
                        {
                            "lng": 54.4075336,
                            "lat": 24.4171589
                        },
                        {
                            "lng": 54.4672718,
                            "lat": 24.3968372
                        },
                        {
                            "lng": 54.4725943,
                            "lat": 24.3810483
                        },
                        {
                            "lng": 54.4950813,
                            "lat": 24.3815183
                        },
                        {
                            "lng": 54.5252933,
                            "lat": 24.3824538
                        },
                        {
                            "lng": 54.52495,
                            "lat": 24.3242779
                        },
                        {
                            "lng": 54.5510425,
                            "lat": 24.2839144
                        },
                        {
                            "lng": 54.6008243,
                            "lat": 24.3092604
                        },
                        {
                            "lng": 54.6358432,
                            "lat": 24.3380423
                        },
                        {
                            "lng": 54.654726,
                            "lat": 24.3693196
                        },
                        {
                            "lng": 54.7000446,
                            "lat": 24.3374167
                        },
                        {
                            "lng": 54.720644,
                            "lat": 24.36025
                        },
                        {
                            "lng": 54.7446765,
                            "lat": 24.3474263
                        },
                        {
                            "lng": 54.7000446,
                            "lat": 24.3371039
                        },
                        {
                            "lng": 54.7209873,
                            "lat": 24.3233393
                        },
                        {
                            "lng": 54.7275104,
                            "lat": 24.3170822
                        },
                        {
                            "lng": 54.8006382,
                            "lat": 24.3195851
                        },
                        {
                            "lng": 54.7755756,
                            "lat": 24.3824538
                        },
                        {
                            "lng": 54.7659626,
                            "lat": 24.3724469
                        },
                        {
                            "lng": 54.74193,
                            "lat": 24.3890204
                        },
                        {
                            "lng": 54.7639026,
                            "lat": 24.4159085
                        },
                        {
                            "lng": 54.7673358,
                            "lat": 24.4293504
                        },
                        {
                            "lng": 54.7498264,
                            "lat": 24.4615426
                        },
                        {
                            "lng": 54.6766986,
                            "lat": 24.4643551
                        },
                        {
                            "lng": 54.6736087,
                            "lat": 24.4793542
                        },
                        {
                            "lng": 54.6918048,
                            "lat": 24.5193429
                        },
                        {
                            "lng": 54.6966114,
                            "lat": 24.5768044
                        },
                        {
                            "lng": 54.7178974,
                            "lat": 24.622068
                        },
                        {
                            "lng": 54.7628726,
                            "lat": 24.6123923
                        },
                        {
                            "lng": 54.8178043,
                            "lat": 24.6766747
                        },
                        {
                            "lng": 54.7776355,
                            "lat": 24.6944557
                        }
                    ],
                    "id": "rentalsDrop"
                },
                "7": {
                    "bounds": [
                        {
                            "lng": 55.3720102,
                            "lat": 25.3596132
                        },
                        {
                            "lng": 55.3956995,
                            "lat": 25.3288956
                        },
                        {
                            "lng": 55.4214487,
                            "lat": 25.3087232
                        },
                        {
                            "lng": 55.3984461,
                            "lat": 25.2863746
                        },
                        {
                            "lng": 55.3953562,
                            "lat": 25.2708522
                        },
                        {
                            "lng": 55.420762,
                            "lat": 25.2335905
                        },
                        {
                            "lng": 55.466424,
                            "lat": 25.2345222
                        },
                        {
                            "lng": 55.5628977,
                            "lat": 25.312758
                        },
                        {
                            "lng": 55.4794702,
                            "lat": 25.3565108
                        },
                        {
                            "lng": 55.4657373,
                            "lat": 25.3351018
                        },
                        {
                            "lng": 55.4348383,
                            "lat": 25.3527877
                        },
                        {
                            "lng": 55.444108,
                            "lat": 25.3620951
                        },
                        {
                            "lng": 55.3991327,
                            "lat": 25.3819484
                        },
                        {
                            "lng": 55.3785334,
                            "lat": 25.3735732
                        },
                        {
                            "lng": 55.3720102,
                            "lat": 25.3596132
                        }
                    ],
                    "id": "rentalsDrop"
                },
                "8": {
                    "bounds": [
                        {
                            "lng": 55.3725274,
                            "lat": 25.3575148
                        },
                        {
                            "lng": 55.3464251,
                            "lat": 25.3249671
                        },
                        {
                            "lng": 55.2997332,
                            "lat": 25.3628212
                        },
                        {
                            "lng": 55.2887469,
                            "lat": 25.3609598
                        },
                        {
                            "lng": 55.2956134,
                            "lat": 25.3460676
                        },
                        {
                            "lng": 55.2777606,
                            "lat": 25.3305529
                        },
                        {
                            "lng": 55.2818805,
                            "lat": 25.3212431
                        },
                        {
                            "lng": 55.2784472,
                            "lat": 25.3106911
                        },
                        {
                            "lng": 55.2894336,
                            "lat": 25.2926887
                        },
                        {
                            "lng": 55.2729541,
                            "lat": 25.2852386
                        },
                        {
                            "lng": 55.2578479,
                            "lat": 25.2883428
                        },
                        {
                            "lng": 55.2502948,
                            "lat": 25.2746835
                        },
                        {
                            "lng": 55.2530413,
                            "lat": 25.2535706
                        },
                        {
                            "lng": 55.2454882,
                            "lat": 25.2250002
                        },
                        {
                            "lng": 55.2386218,
                            "lat": 25.2100913
                        },
                        {
                            "lng": 55.2276355,
                            "lat": 25.206985
                        },
                        {
                            "lng": 55.2221423,
                            "lat": 25.1864817
                        },
                        {
                            "lng": 55.2036029,
                            "lat": 25.1665964
                        },
                        {
                            "lng": 55.18987,
                            "lat": 25.154788
                        },
                        {
                            "lng": 55.1651507,
                            "lat": 25.1162473
                        },
                        {
                            "lng": 55.1582843,
                            "lat": 25.1205993
                        },
                        {
                            "lng": 55.1603442,
                            "lat": 25.1386273
                        },
                        {
                            "lng": 55.1486712,
                            "lat": 25.1473295
                        },
                        {
                            "lng": 55.1273852,
                            "lat": 25.1436
                        },
                        {
                            "lng": 55.1129657,
                            "lat": 25.1367624
                        },
                        {
                            "lng": 55.1019793,
                            "lat": 25.1181125
                        },
                        {
                            "lng": 55.1115924,
                            "lat": 25.1025687
                        },
                        {
                            "lng": 55.1260119,
                            "lat": 25.0938633
                        },
                        {
                            "lng": 55.1081591,
                            "lat": 25.0895104
                        },
                        {
                            "lng": 55.1232653,
                            "lat": 25.0714751
                        },
                        {
                            "lng": 55.0827533,
                            "lat": 25.0291753
                        },
                        {
                            "lng": 55.0717669,
                            "lat": 25.0297974
                        },
                        {
                            "lng": 55.0436145,
                            "lat": 25.0260644
                        },
                        {
                            "lng": 55.0504809,
                            "lat": 25.0086421
                        },
                        {
                            "lng": 55.0312548,
                            "lat": 24.9893503
                        },
                        {
                            "lng": 55.0257617,
                            "lat": 25.018598
                        },
                        {
                            "lng": 55.0216418,
                            "lat": 25.0353967
                        },
                        {
                            "lng": 54.9948626,
                            "lat": 25.0447284
                        },
                        {
                            "lng": 54.9619037,
                            "lat": 25.0353967
                        },
                        {
                            "lng": 54.9419909,
                            "lat": 25.0080199
                        },
                        {
                            "lng": 54.97289,
                            "lat": 24.9719228
                        },
                        {
                            "lng": 54.8994189,
                            "lat": 24.9152664
                        },
                        {
                            "lng": 55.0085955,
                            "lat": 24.8554688
                        },
                        {
                            "lng": 55.0497943,
                            "lat": 24.8336604
                        },
                        {
                            "lng": 55.0772601,
                            "lat": 24.7925256
                        },
                        {
                            "lng": 55.1671764,
                            "lat": 24.852182
                        },
                        {
                            "lng": 55.203239,
                            "lat": 24.8876026
                        },
                        {
                            "lng": 55.224212,
                            "lat": 24.9242634
                        },
                        {
                            "lng": 55.2310784,
                            "lat": 24.9541492
                        },
                        {
                            "lng": 55.2619775,
                            "lat": 24.9491687
                        },
                        {
                            "lng": 55.2873834,
                            "lat": 24.9560168
                        },
                        {
                            "lng": 55.3024896,
                            "lat": 24.9547717
                        },
                        {
                            "lng": 55.3402551,
                            "lat": 24.9504138
                        },
                        {
                            "lng": 55.3793939,
                            "lat": 24.9522815
                        },
                        {
                            "lng": 55.4013665,
                            "lat": 24.9560168
                        },
                        {
                            "lng": 55.4329522,
                            "lat": 24.9902514
                        },
                        {
                            "lng": 55.460418,
                            "lat": 24.9939855
                        },
                        {
                            "lng": 55.4714044,
                            "lat": 25.0101654
                        },
                        {
                            "lng": 55.3993066,
                            "lat": 25.0866796
                        },
                        {
                            "lng": 55.5009301,
                            "lat": 25.1656319
                        },
                        {
                            "lng": 55.5496819,
                            "lat": 25.2010506
                        },
                        {
                            "lng": 55.5702813,
                            "lat": 25.2377012
                        },
                        {
                            "lng": 55.4576714,
                            "lat": 25.2805497
                        },
                        {
                            "lng": 55.4377587,
                            "lat": 25.2960708
                        },
                        {
                            "lng": 55.3958733,
                            "lat": 25.3295896
                        },
                        {
                            "lng": 55.3876336,
                            "lat": 25.345725
                        },
                        {
                            "lng": 55.3725274,
                            "lat": 25.3575148
                        }
                    ],
                    "id": "airportPickUp"
                },
                "9": {
                    "bounds": [
                        {
                            "lng": 55.3725274,
                            "lat": 25.3575148
                        },
                        {
                            "lng": 55.3464251,
                            "lat": 25.3249671
                        },
                        {
                            "lng": 55.2997332,
                            "lat": 25.3628212
                        },
                        {
                            "lng": 55.2887469,
                            "lat": 25.3609598
                        },
                        {
                            "lng": 55.2956134,
                            "lat": 25.3460676
                        },
                        {
                            "lng": 55.2777606,
                            "lat": 25.3305529
                        },
                        {
                            "lng": 55.2818805,
                            "lat": 25.3212431
                        },
                        {
                            "lng": 55.2784472,
                            "lat": 25.3106911
                        },
                        {
                            "lng": 55.2894336,
                            "lat": 25.2926887
                        },
                        {
                            "lng": 55.2729541,
                            "lat": 25.2852386
                        },
                        {
                            "lng": 55.2578479,
                            "lat": 25.2883428
                        },
                        {
                            "lng": 55.2502948,
                            "lat": 25.2746835
                        },
                        {
                            "lng": 55.2530413,
                            "lat": 25.2535706
                        },
                        {
                            "lng": 55.2454882,
                            "lat": 25.2250002
                        },
                        {
                            "lng": 55.2386218,
                            "lat": 25.2100913
                        },
                        {
                            "lng": 55.2276355,
                            "lat": 25.206985
                        },
                        {
                            "lng": 55.2221423,
                            "lat": 25.1864817
                        },
                        {
                            "lng": 55.2036029,
                            "lat": 25.1665964
                        },
                        {
                            "lng": 55.18987,
                            "lat": 25.154788
                        },
                        {
                            "lng": 55.1651507,
                            "lat": 25.1162473
                        },
                        {
                            "lng": 55.1582843,
                            "lat": 25.1205993
                        },
                        {
                            "lng": 55.1603442,
                            "lat": 25.1386273
                        },
                        {
                            "lng": 55.1486712,
                            "lat": 25.1473295
                        },
                        {
                            "lng": 55.1273852,
                            "lat": 25.1436
                        },
                        {
                            "lng": 55.1129657,
                            "lat": 25.1367624
                        },
                        {
                            "lng": 55.1019793,
                            "lat": 25.1181125
                        },
                        {
                            "lng": 55.1115924,
                            "lat": 25.1025687
                        },
                        {
                            "lng": 55.1260119,
                            "lat": 25.0938633
                        },
                        {
                            "lng": 55.1081591,
                            "lat": 25.0895104
                        },
                        {
                            "lng": 55.1232653,
                            "lat": 25.0714751
                        },
                        {
                            "lng": 55.0827533,
                            "lat": 25.0291753
                        },
                        {
                            "lng": 55.0717669,
                            "lat": 25.0297974
                        },
                        {
                            "lng": 55.0436145,
                            "lat": 25.0260644
                        },
                        {
                            "lng": 55.0504809,
                            "lat": 25.0086421
                        },
                        {
                            "lng": 55.0312548,
                            "lat": 24.9893503
                        },
                        {
                            "lng": 55.0257617,
                            "lat": 25.018598
                        },
                        {
                            "lng": 55.0216418,
                            "lat": 25.0353967
                        },
                        {
                            "lng": 54.9948626,
                            "lat": 25.0447284
                        },
                        {
                            "lng": 54.9619037,
                            "lat": 25.0353967
                        },
                        {
                            "lng": 54.9419909,
                            "lat": 25.0080199
                        },
                        {
                            "lng": 54.97289,
                            "lat": 24.9719228
                        },
                        {
                            "lng": 54.8994189,
                            "lat": 24.9152664
                        },
                        {
                            "lng": 55.0085955,
                            "lat": 24.8554688
                        },
                        {
                            "lng": 55.0497943,
                            "lat": 24.8336604
                        },
                        {
                            "lng": 55.0772601,
                            "lat": 24.7925256
                        },
                        {
                            "lng": 55.1671764,
                            "lat": 24.852182
                        },
                        {
                            "lng": 55.203239,
                            "lat": 24.8876026
                        },
                        {
                            "lng": 55.224212,
                            "lat": 24.9242634
                        },
                        {
                            "lng": 55.2310784,
                            "lat": 24.9541492
                        },
                        {
                            "lng": 55.2619775,
                            "lat": 24.9491687
                        },
                        {
                            "lng": 55.2873834,
                            "lat": 24.9560168
                        },
                        {
                            "lng": 55.3024896,
                            "lat": 24.9547717
                        },
                        {
                            "lng": 55.3402551,
                            "lat": 24.9504138
                        },
                        {
                            "lng": 55.3793939,
                            "lat": 24.9522815
                        },
                        {
                            "lng": 55.4013665,
                            "lat": 24.9560168
                        },
                        {
                            "lng": 55.4329522,
                            "lat": 24.9902514
                        },
                        {
                            "lng": 55.460418,
                            "lat": 24.9939855
                        },
                        {
                            "lng": 55.4714044,
                            "lat": 25.0101654
                        },
                        {
                            "lng": 55.3993066,
                            "lat": 25.0866796
                        },
                        {
                            "lng": 55.5009301,
                            "lat": 25.1656319
                        },
                        {
                            "lng": 55.5496819,
                            "lat": 25.2010506
                        },
                        {
                            "lng": 55.5702813,
                            "lat": 25.2377012
                        },
                        {
                            "lng": 55.4576714,
                            "lat": 25.2805497
                        },
                        {
                            "lng": 55.4377587,
                            "lat": 25.2960708
                        },
                        {
                            "lng": 55.3958733,
                            "lat": 25.3295896
                        },
                        {
                            "lng": 55.3876336,
                            "lat": 25.345725
                        },
                        {
                            "lng": 55.3725274,
                            "lat": 25.3575148
                        }
                    ],
                    "id": "airportDrop"
                },
                "10": {
                    "bounds": [
                        {
                            "lng": 54.7776355,
                            "lat": 24.6944557
                        },
                        {
                            "lng": 54.7566928,
                            "lat": 24.7075559
                        },
                        {
                            "lng": 54.6609058,
                            "lat": 24.6779226
                        },
                        {
                            "lng": 54.6602191,
                            "lat": 24.6398568
                        },
                        {
                            "lng": 54.6344699,
                            "lat": 24.6214438
                        },
                        {
                            "lng": 54.6190204,
                            "lat": 24.6223801
                        },
                        {
                            "lng": 54.6135273,
                            "lat": 24.6145772
                        },
                        {
                            "lng": 54.6052875,
                            "lat": 24.61645
                        },
                        {
                            "lng": 54.6083774,
                            "lat": 24.6014673
                        },
                        {
                            "lng": 54.6361866,
                            "lat": 24.6083346
                        },
                        {
                            "lng": 54.6433963,
                            "lat": 24.5967848
                        },
                        {
                            "lng": 54.6282901,
                            "lat": 24.5640028
                        },
                        {
                            "lng": 54.6155872,
                            "lat": 24.5483892
                        },
                        {
                            "lng": 54.620737,
                            "lat": 24.5337107
                        },
                        {
                            "lng": 54.5870914,
                            "lat": 24.5055982
                        },
                        {
                            "lng": 54.5263233,
                            "lat": 24.5209047
                        },
                        {
                            "lng": 54.5390262,
                            "lat": 24.5412064
                        },
                        {
                            "lng": 54.5331897,
                            "lat": 24.5437048
                        },
                        {
                            "lng": 54.5160236,
                            "lat": 24.5424556
                        },
                        {
                            "lng": 54.5156803,
                            "lat": 24.5218418
                        },
                        {
                            "lng": 54.5053806,
                            "lat": 24.5330861
                        },
                        {
                            "lng": 54.4871845,
                            "lat": 24.5521367
                        },
                        {
                            "lng": 54.4889011,
                            "lat": 24.5702476
                        },
                        {
                            "lng": 54.4748249,
                            "lat": 24.5711843
                        },
                        {
                            "lng": 54.4593753,
                            "lat": 24.5540103
                        },
                        {
                            "lng": 54.4748249,
                            "lat": 24.586795
                        },
                        {
                            "lng": 54.4411792,
                            "lat": 24.5508875
                        },
                        {
                            "lng": 54.4216098,
                            "lat": 24.5424556
                        },
                        {
                            "lng": 54.3948307,
                            "lat": 24.536834
                        },
                        {
                            "lng": 54.4082202,
                            "lat": 24.5259023
                        },
                        {
                            "lng": 54.3938007,
                            "lat": 24.514345
                        },
                        {
                            "lng": 54.3817844,
                            "lat": 24.5284011
                        },
                        {
                            "lng": 54.3886508,
                            "lat": 24.5383956
                        },
                        {
                            "lng": 54.361185,
                            "lat": 24.5387079
                        },
                        {
                            "lng": 54.3615284,
                            "lat": 24.5262147
                        },
                        {
                            "lng": 54.3690815,
                            "lat": 24.5002873
                        },
                        {
                            "lng": 54.3550052,
                            "lat": 24.5152821
                        },
                        {
                            "lng": 54.3357791,
                            "lat": 24.4996625
                        },
                        {
                            "lng": 54.3326892,
                            "lat": 24.4827912
                        },
                        {
                            "lng": 54.3501987,
                            "lat": 24.4815414
                        },
                        {
                            "lng": 54.3295993,
                            "lat": 24.4668551
                        },
                        {
                            "lng": 54.3261661,
                            "lat": 24.4718549
                        },
                        {
                            "lng": 54.3217029,
                            "lat": 24.484041
                        },
                        {
                            "lng": 54.30694,
                            "lat": 24.480604
                        },
                        {
                            "lng": 54.3244495,
                            "lat": 24.4640426
                        },
                        {
                            "lng": 54.2914905,
                            "lat": 24.4702924
                        },
                        {
                            "lng": 54.3096866,
                            "lat": 24.4496668
                        },
                        {
                            "lng": 54.340929,
                            "lat": 24.4509169
                        },
                        {
                            "lng": 54.3649616,
                            "lat": 24.4321636
                        },
                        {
                            "lng": 54.4075336,
                            "lat": 24.4171589
                        },
                        {
                            "lng": 54.4672718,
                            "lat": 24.3968372
                        },
                        {
                            "lng": 54.4725943,
                            "lat": 24.3810483
                        },
                        {
                            "lng": 54.4950813,
                            "lat": 24.3815183
                        },
                        {
                            "lng": 54.5252933,
                            "lat": 24.3824538
                        },
                        {
                            "lng": 54.52495,
                            "lat": 24.3242779
                        },
                        {
                            "lng": 54.5510425,
                            "lat": 24.2839144
                        },
                        {
                            "lng": 54.6008243,
                            "lat": 24.3092604
                        },
                        {
                            "lng": 54.6358432,
                            "lat": 24.3380423
                        },
                        {
                            "lng": 54.654726,
                            "lat": 24.3693196
                        },
                        {
                            "lng": 54.7000446,
                            "lat": 24.3374167
                        },
                        {
                            "lng": 54.720644,
                            "lat": 24.36025
                        },
                        {
                            "lng": 54.7446765,
                            "lat": 24.3474263
                        },
                        {
                            "lng": 54.7000446,
                            "lat": 24.3371039
                        },
                        {
                            "lng": 54.7209873,
                            "lat": 24.3233393
                        },
                        {
                            "lng": 54.7275104,
                            "lat": 24.3170822
                        },
                        {
                            "lng": 54.8006382,
                            "lat": 24.3195851
                        },
                        {
                            "lng": 54.7755756,
                            "lat": 24.3824538
                        },
                        {
                            "lng": 54.7659626,
                            "lat": 24.3724469
                        },
                        {
                            "lng": 54.74193,
                            "lat": 24.3890204
                        },
                        {
                            "lng": 54.7639026,
                            "lat": 24.4159085
                        },
                        {
                            "lng": 54.7673358,
                            "lat": 24.4293504
                        },
                        {
                            "lng": 54.7498264,
                            "lat": 24.4615426
                        },
                        {
                            "lng": 54.6766986,
                            "lat": 24.4643551
                        },
                        {
                            "lng": 54.6736087,
                            "lat": 24.4793542
                        },
                        {
                            "lng": 54.6918048,
                            "lat": 24.5193429
                        },
                        {
                            "lng": 54.6966114,
                            "lat": 24.5768044
                        },
                        {
                            "lng": 54.7178974,
                            "lat": 24.622068
                        },
                        {
                            "lng": 54.7628726,
                            "lat": 24.6123923
                        },
                        {
                            "lng": 54.8178043,
                            "lat": 24.6766747
                        },
                        {
                            "lng": 54.7776355,
                            "lat": 24.6944557
                        }
                    ],
                    "id": "airportDrop"
                },
                "11": {
                    "bounds": [
                        {
                            "lng": 55.3720102,
                            "lat": 25.3596132
                        },
                        {
                            "lng": 55.3956995,
                            "lat": 25.3288956
                        },
                        {
                            "lng": 55.4214487,
                            "lat": 25.3087232
                        },
                        {
                            "lng": 55.3984461,
                            "lat": 25.2863746
                        },
                        {
                            "lng": 55.3953562,
                            "lat": 25.2708522
                        },
                        {
                            "lng": 55.420762,
                            "lat": 25.2335905
                        },
                        {
                            "lng": 55.466424,
                            "lat": 25.2345222
                        },
                        {
                            "lng": 55.5628977,
                            "lat": 25.312758
                        },
                        {
                            "lng": 55.4794702,
                            "lat": 25.3565108
                        },
                        {
                            "lng": 55.4657373,
                            "lat": 25.3351018
                        },
                        {
                            "lng": 55.4348383,
                            "lat": 25.3527877
                        },
                        {
                            "lng": 55.444108,
                            "lat": 25.3620951
                        },
                        {
                            "lng": 55.3991327,
                            "lat": 25.3819484
                        },
                        {
                            "lng": 55.3785334,
                            "lat": 25.3735732
                        },
                        {
                            "lng": 55.3720102,
                            "lat": 25.3596132
                        }
                    ],
                    "id": "airportDrop"
                }
            
  }