import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CustomButton, FileUploader } from '../../types/components.type';
import { ButtonTypes } from '../../types/enums';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {

  @Input() fileUploaderDetails: FileUploader 

  attachFileContainerCtaDetails:CustomButton = {
    label: 'Attach Proof',
    left: true,
    right: false,
    leftUrl: 'assets/add_icon.svg',
    rightUrl: '',
    disabled: false,
    type: ButtonTypes.FILLED
  }


  openExplorer(explorer){
        explorer.click();
  }

  onFileUpload(event){
    this.fileUploaderDetails.value = null;
    this.fileUploaderDetails.value = event.target.files[0];
  }
}


