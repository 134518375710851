import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
} from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';

@Component({
  selector: 'pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
})
export class PopUpComponent implements OnInit, OnDestroy{
  popupSubscription: Subscription;
  
  popupSeverity = {
    error: false,
    warning: false,
    info: false,
    success: false,
  };

  title = '';
  message = '';
  ctaLabel = '';

  constructor(private _subscription: CommonSubscriptionService) {}
  

  ngOnInit(): void {
    this.popupSubscription = this._subscription.fetchAlert().subscribe(
      (res) => {
        this.title = res.title;
        this.message = res.message;
        this.ctaLabel = res.ctaLabel;

        Object.keys(this.popupSeverity).forEach(
          (theme) =>{
            return this.popupSeverity[theme] = false
          } 
        );

        switch (res.theme) {
          case 'ERROR':
            this.popupSeverity.error = true;
            break;
          case 'WARNING':
            this.popupSeverity.warning = true;
            break;
          case 'INFO':
            this.popupSeverity.info = true;
            break;
          case 'SUCCESS':
            this.popupSeverity.success = true;
            break;
        }
      }
    );
  }

  closeDialog(field) {
    this._subscription.emitCloseAlert(this.getSeverity());
    this.popupSeverity[field] = false;
  }

  getSeverity(){
    if(this.popupSeverity.success == true){
      return true;
    }
    else{
      return false;
    }
  }


  ngOnDestroy(): void {
    this.popupSubscription.unsubscribe();
  }
}
